import React from "react";
  import { Link } from 'react-router-dom';
import '../../../assets/css/custom.css';

import moment from 'moment';


const VerificationsList = ({data}) => {

    const assignStatusLabel = (status) => {
        switch(parseInt(status)){
            case 0: return 'Initiated';
            case 1: return 'Approved';
            case 2: return 'Rejected';
            default: return 'Unknown';
        }
    }

    const assignColor = (status) => {
        switch(parseInt(status)){
            case 0:     return {backgroundColor: '#004F93', background: 'linear-gradient(135deg, rgba(0, 79, 147,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)', boxShadow: 'box-shadow: 3px 3px 5px 0px rgba(0, 79, 147,0.6)'};
            case 1:     return {backgroundColor: '#11740F', background: 'linear-gradient(135deg, rgba(17, 116, 15,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)', boxShadow: 'box-shadow:3px 3px 5px 0px rgba(17, 116, 15,0.6)'};
            case 2:     return {backgroundColor: '#FF0000', background: 'linear-gradient(135deg, rgba(255,0,0,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)', boxShadow: 'box-shadow: 3px 3px 5px 0px rgba(255,0,0,0.6)'};
            default:    return {backgroundColor: '#FFffff', background: 'linear-gradient(135deg, rgba(0,0,0,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)', boxShadow: 'box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.6)'}
        }
    }

    return (
        <>
        {data!==0?data.map((item, index) => {
            return <div className='col-xl-3 col-lg-4 col-md-12 col-sm-6'>
                 <div className="card testimonial-card p-2"  style={{minWidth: 206, margin: 10, flexGrow: 1, ...assignColor(item.status)}}>
                    <div className="card-up indigo lighten-1"></div> 
        
                    <div className="col-12 mb-0">
                        <div className="row">
                            <h4 className="card-title" style={{color: '#fff'}} >{'Token ID # '+item.verification_id}</h4>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        <div className="col-12 p-0">
                            <img src={item.images.length>0? item.images[0]:require("../../../assets/img/noimage.png")} className="img-fluid cat-img" alt="user avatar" style={{ height: "100%", minWidth: 190 }} />
                        </div>
                        <div className="pl-0 pr-0 pb-2">
                            <div className="col-12 pb-2 pl-0 pr-0">
                            <div className="small" style={{ float: "right" }}>Reward: {item.reward_point}</div>
                                <div className="small">Status: {assignStatusLabel(item.status)}</div>
                                </div>
                            
                            <div className="des mr-2 ml-2">
                                {String(item.complaint).length > 40?String(item.complaint).substring(0,39)+'...': String(item.complaint)}
                            </div>
                            <div className="small">{moment(item.created_at).format('ll')}</div>
                        </div>
        
                    </div>
                    <div className="card-footer" style={{ textAlign: "right" }}>
                        <Link to={{ pathname: 'unit-show', state: { feedback_id: ''} }}>
                            <button type="button" className="btn btn-info btn-sm">Show</button>
                        </Link>{" "}
                    </div>
                </div>
                </div>
        }):null}
        </>
    )
}

export default VerificationsList;
import React from "react";

import '../../../assets/css/custom.css';

import logoImg from '../../../assets/img/output-onlinepngtools.png';
// import { Link } from 'react-router-dom';

class Feedbacklist extends React.Component {
  

  render() {
    

    return (
      <>
      <div>
        <div>
         <div className="col-md-6"> <img src={logoImg} alt="img" width="200px" height="200px"/></div>
          <div  className="col-md-6" style={{verticalAlign:"center"}}>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '17px', color: '#333333', textDecoration: 'none', textAlign: 'center'}}><b>PARIBESH</b>
              </p>
          </div>
        </div>
      <div className="privacy">
<div id="sig" style={{minWidth: '300px', maxWidth: '800px', minHeight: '40px', margin: 'auto', padding: '30px', marginTop: '0px', background: 'white'}}>
        
        <div id="sig" style={{minWidth: '300px', maxWidth: '800px', minHeight: '82px', lineHeight: '18px', margin: 'auto', padding: '50px', marginTop: '0px', fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', background: 'white'}}>
          <span style={{fontSize: '30px', color: 'black', textDecoration: 'none', fontFamily: '"Avenir LT W01 95 Black", Arial, Helvetica, sans-serif'}}><strong> Privacy Policy</strong></span><br />
          <br />
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}>Pollution Control Board, West Bengal Police built the "PARIBESH" app for the use of Pollution Control Board as well as general public as a free app. This service is provided by WBPCB at no cost and is intended for use as it is.</p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}>This page is used to inform app visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service. </p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}>At  PARIBESH, the privacy of our visitors is of extreme Important to us. This privacy policy document outlines the types of Personal information received and collected by PARIBESH and how it is used. </p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that  we collect is used for providing and improving the Service. we will not use or share your information with anyone except as described in this Privacy Policy. </p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at PARIBESH unless otherwise defined in this Privacy Policy. </p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '18px', color: '#333333', textDecoration: 'none'}}><b>Information Collection and Use</b> </p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}>For a better experience, while using our Service, we may require you to provide us certain personally identifiable information such as users name, email, mobile number, address, location. The information that we request will be retained by us and used as described in this privacy policy.</p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}>Link to privacy policy of third party service providers used by the app</p>
          <ul>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}><u>Google Play Services</u></p>
            </li>
          </ul>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '18px', color: '#333333', textDecoration: 'none'}}><b>Log Data</b> </p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}>We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol ("IP") address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '18px', color: '#333333', textDecoration: 'none'}}><b>Cookies</b> </p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your device internal memory.</p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}>This Service does not use these "cookies" explicitly. However, the app may use third party code and libraries that use "cookies" to collect information and to improve their services. You have the option to either accept or refuse these cookies and to know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
          </p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '18px', color: '#333333', textDecoration: 'none'}}><b>Service Providers</b> </p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}>We may employ third-party companies and individuals due to the following reasons:</p>
          <ul>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>To facilitate our Service</p>
            </li>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>To provide the Service on our behalf</p>
            </li>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>To perform Service-related services or</p>
            </li>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>To assist us to analyse how our Service is used.</p>
            </li>
          </ul>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '18px', color: '#333333', textDecoration: 'none'}}><b>Security</b> </p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it.</p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '18px', color: '#333333', textDecoration: 'none'}}><b>Changes to This Privacy Policy</b> </p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes.  We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '18px', color: '#333333', textDecoration: 'none'}}><b>Contact Us</b> </p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}>If you require any more information or have any questions about our Privacy policy, please feel free to contact us by email at <a href="mailto:developers.wbpcb@gmail.com">developers.wbpcb@gmail.com</a></p>
        </div>
        <div id="sig" style={{minWidth: '300px', maxWidth: '800px', minHeight: '82px', lineHeight: '18px', margin: 'auto', padding: '50px', marginTop: '0px', fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', background: 'white'}}>
          <span style={{fontSize: '30px', color: 'black', textDecoration: 'none', fontFamily: '"Avenir LT W01 95 Black", Arial, Helvetica, sans-serif'}}><strong> Terms and Conditions</strong></span><br />
          <br />
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}><span style={{fontSize: '13px', color: 'black'}}><strong>1.</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;Applicant's privacy is very important to WBPCB. WBPCB designed the Data Policy to make important disclosures about how one can use the website to view various service related data like Environmental News, AQI Information and taking action againt Environmental Complaints.</p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}><span style={{fontSize: '13px', color: 'black'}}><strong>2.</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;I know that and it is purely my own decision for the bonafide and service of the state, and against no consideration - financial or otherwise at all.</p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}><span style={{fontSize: '13px', color: 'black'}}><strong>3.</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;I will sincerely follow the guidelines, ethics of communication.</p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}><span style={{fontSize: '13px', color: 'black'}}><strong>4.</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;Duties and obligations:</p>
          <ul>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>I will not upload viruses or other malicious code.</p>
            </li>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>I will not solicit login information or access an account belonging to someone else.</p>
            </li>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>I will not use my website to do anything unlawful, misleading, malicious, or discriminatory.</p>
            </li>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>I will not do anything that could disable, overburden, or impair the proper working or appearance of WBPCB and PARIBESH website, server and app, such as a denial of service attack or interference with page rendering or other vulnerable functionality..</p>
            </li>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>I shall be fully responsible for any wrongful act done by me and shall be liable for legal action in that regard.</p>
            </li>
          </ul>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}><span style={{fontSize: '13px', color: 'black'}}><strong>5.</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;During registration I am providing my real name and other necessary information as desired by WBPCB authority. Here are some commitments I make to myself relating to registering and maintaining the security of my application as a Volunteer.</p>
          <ul>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>I will not provide any false personal information or create an account for anyone other than myself without permission.</p>
            </li>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>I will keep my contact information accurate and up-to-date.</p>
            </li>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>I will not share my password that might jeopardize the security of my account.</p>
            </li>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>WBPCB solely reserves the right to accept or reject my application if WBPCB deems it to be appropriate.</p>
            </li>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>I am fully aware that WBPCB authority has all rights to suspend my account and to cancel my registration. The decision of WBPCB shall be final and binding upon me.</p>
            </li>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>My information is important on my respect but further investigation totally depend upon WBPCB's law and order.</p>
            </li>
          </ul>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}><span style={{fontSize: '13px', color: 'black'}}><strong>6.</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;I know that WBPCB always respect other people's rights, and expect all should do the same.</p>
          <ul>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>I will not submit any information or take any action for submitting a tip that infringes or violates someone else's rights or otherwise violates the law or any derogatory message as information.</p>
            </li>
            <li>
              <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none', paddingLeft: '10px'}}>WBPCB can remove any content or information I post as an information if they believe that it violates the terms and conditions mentioned in the website.</p>
            </li>
          </ul>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}><span style={{fontSize: '13px', color: 'black'}}><strong>7.</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;I am fully aware that WBPCB always tries to keep website bug-free and safe, and I shall use it at my own risk. WBPCB does not guarantee that the website will always be safe, secure or error free or that the website will always function without disruptions, delays or imperfections.</p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}><span style={{fontSize: '13px', color: 'black'}}><strong>8.</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;I, fully understand that whatever data I am sharing or providing my personal and personally identifiable information, I am doing that with my own consent. I am giving my consent to WBPCB that WBPCB can use the data for analysis and other purposes.</p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}><span style={{fontSize: '13px', color: 'black'}}><strong>9.</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;I fully understand that as a User it does not confer upon me any kind of immunity due to any act or omission having been committed by me in violation of existing Acts, Laws and Rules etc. and I shall be governed by the existing Acts, Laws and Rules. In case of any offence committed by me, the normal course of law will be applicable for me.</p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}><span style={{fontSize: '13px', color: 'black'}}><strong>10.</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;Giving information to this app is not an alternative of lodging an FIR / GD in
            the PS. You should submit the information to the concerned PS for GD / FIR
            if required.
            Apart from any grievances against any government machinery including
            police should only be sent to competent authority.</p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}><span style={{fontSize: '13px', color: 'black'}}><strong>11.</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;WBPCB can send me Email and SMS for which I have no objection.</p>
          <p style={{fontFamily: '"Lucida", Verdana, Arial, Sans-Serif', fontSize: '15px', color: '#333333', textDecoration: 'none'}}><span style={{fontSize: '13px', color: 'black'}}><strong>12.</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;I accept all the above terms and conditions after fully understanding the meaning and implication of the same.</p>
          <br /><br />
        </div>
      </div>
      </div>
      </div>
      </>
    );
  }
}
export default Feedbacklist;

import React, { Component, useEffect } from "react";
import Header from "../../../components/Headers/Header";
import Helper from "../../../helpers/helper";
import * as UnitAPI from "../../../API/unitapi";
import { Redirect, Link } from "react-router-dom";
import Loader from "../../../components/Spinner/spinner";
import GoogleMapReact from "google-map-react";
import moment from "moment";

import {
  Container,
  Button,
  Card,
  Col,
  CardHeader,
  CardBody,
  Row,
  Modal,
} from "reactstrap";
import Slider from "react-animated-slider";
export default class ShowUnit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchInProgress: false,
      remarks: "",
      status: 0,
      modalVisible: false,
      unit: props.location.state.UnitData,
      page_no: props.location.state.page_no,
    };
  }

  getUnitDetails = (id) => {
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      UnitAPI.default.getUnitDetails("/get_unit_details", id).then((result) => {
        if (result.status === "success") {
          console.log("Details loaded successfully");
          console.log(result);
          this.setState({ fetchInProgress: false, unit: result.data });
        } else {
          console.log("failed to load details");
          this.setState({ fetchInProgress: false });
        }
      });
    });
  };

  updateUnitStatus = (id, status) => {
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      UnitAPI.default
        .updateUnitStatus("/verification/approve_unit", status, id)
        .then((result) => {
          if (result.status === "success") {
            console.log("updated successfully");
            this.setState({ fetchInProgress: false });
          } else {
            console.log("failed to update");
            this.setState({ fetchInProgress: false });
          }
        });
    });
    this.getUnitDetails(id);
  };
  updateUnitPaymentStatus = (id, remarks, status) => {
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      UnitAPI.default
        .updateUnitPaymentStatus(
          "/verification/update_unit",
          status,
          id,
          remarks
        )
        .then((result) => {
          if (result.status === "success") {
            console.log("updated successfully");
            this.setState({ fetchInProgress: false });
          } else {
            console.log("failed to update");
            this.setState({ fetchInProgress: false, modalVisible: false });
          }
        });
    });
    this.getUnitDetails(id);
  };

  render() {
    const { unit, page_no } = this.state;

    if (typeof unit === "undefined") {
      return <Redirect to={"/admin/units"} />;
    }
    const items = [];
    if (unit.images != null && unit.images.length > 0) {
      for (var i = 0; i < unit.images.length; i++) {
        items.push(
          <div
            style={{
              background: `url('${unit.images[i].trim()}') no-repeat center`,
              backgroundSize: "cover",
            }}
            key={i}
            alt="preview"
            name={unit.images[i]}
          ></div>
        );
      }
    } else {
      items.push(
        <div
          style={{
            background: `url('${require("../../../assets/img/noimage.png")}') no-repeat center`,
            backgroundSize: "cover",
          }}
          key={0}
          alt="preview"
        ></div>
      );
    }

    if (this.state.fetchInProgress) {
      return (
        <div className="progress2 justify-content-center">
          <img src={Loader} alt="" className="pad" />
        </div>
      );
    } else
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <Col>
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-2">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Unit Details</h3>
                      </Col>
                      <Col xs="4" className="text-right">
                        <Link
                          to={{ path: "units", state: { page_no: page_no } }}
                        >
                          <Button
                            className="btn btn-sm"
                            color="primary"
                            type="button"
                          >
                            <span>Back</span>
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row className="pt-2">
                      <Col md={12} lg={8}>
                        <Slider autoplay={3000}>{items}</Slider>
                      </Col>
                      <Col md={12} lg={4}>
                        <div
                          style={{
                            height: "100%",
                            padding: 10,
                            border: "1px solid",
                            maxHeight: 400,
                          }}
                        >
                          <GoogleMapReact
                            bootrstrapURLKeys={{
                              // key: "AIzaSyBJ-RKwTIsTWX_4eKsBN0bBUBBx8dP1-Ls",
                              key: "AIzaSyCxB4NOyF5Du4Ih88qXosmZHq4HVCAG_yk",
                              // key: "AIzaSyBtYwRK1-rEX4VBywqs6hFRzP0iXvt7wHw",
                            }}
                            defaultCenter={{
                              lat: parseFloat(unit.lat),
                              lng: parseFloat(unit.long),
                            }}
                            defaultZoom={12}
                          >
                            <i
                              className="fas fa-map-pin"
                              lat={parseFloat(unit.lat)}
                              lng={parseFloat(unit.long)}
                            ></i>
                          </GoogleMapReact>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row
                      className="pt-2"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {unit.status === 0 ? (
                        <Col md={12} lg={6}>
                          {" "}
                          <b>Unit verification pending: &nbsp;</b>
                          <Button
                            className="btn btn-success btn-sm ml-2 mr-2"
                            onClick={() => {
                              this.updateUnitStatus(unit.id, 1);
                              // this.getUnitDetails(unit.id);
                            }}
                          >
                            Approve
                          </Button>
                          {/* Newly added by Dipprokash */}
                          <Button
                            className="btn btn-warning  btn-sm mr-2"
                            onClick={() => {
                              this.updateUnitStatus(unit.id, 3);
                              // this.getUnitDetails(unit.id);
                            }}
                          >
                            Irrelevant
                          </Button>
                          <Button
                            className="btn btn-danger btn-sm mr-2"
                            onClick={() => {
                              this.updateUnitStatus(unit.id, 2);
                              // this.getUnitDetails(unit.id);
                            }}
                          >
                            Reject
                          </Button>
                        </Col>
                      ) : unit.status === 1 ? (
                        <Col md={12} lg={6}>
                          <b>
                            Approved: &nbsp;{" "}
                            <i class="fas fa-check-circle text-success"></i>
                          </b>{" "}
                        </Col>
                      ) : unit.status === 2 ? (
                        <Col md={12} lg={6}>
                          <b>
                            Rejected: &nbsp;{" "}
                            <i class="fas fa-times-circle text-danger"></i>
                          </b>
                        </Col>
                      ) : (
                        <Col md={12} lg={6}>
                          <b>
                            Irrelevant: &nbsp;{" "}
                            <i class="fas fa-times-circle text-danger"></i>
                          </b>
                        </Col>
                      )}
                      {unit.status === 1 ? (
                        <Col md={12} lg={6}>
                          <b>Payment Status: &nbsp;</b>
                          <b className="text-success">Paid</b>{" "}
                          <Button
                            className="btn btn-primary btn-sm ml-2 mr-2"
                            onClick={() =>
                              this.setState({ modalVisible: true })
                            }
                          >
                            Update
                          </Button>
                        </Col>
                      ) : (
                        <Col md={12} lg={6}>
                          <b>Payment Status: &nbsp;</b>
                          <b className="text-danger">Not Paid</b>
                          <Button
                            className="btn btn-primary btn-sm ml-2 mr-2"
                            onClick={() =>
                              this.setState({ modalVisible: true })
                            }
                          >
                            Update
                          </Button>
                        </Col>
                      )}
                    </Row>
                    <Row className="pt-2">
                      <Col md={12} lg={6}>
                        <b>Unit Name: &nbsp;</b> {unit.name}
                      </Col>
                      <Col md={12} lg={6}>
                        <b>Payment Remarks: &nbsp;</b>
                        {unit.remarks}
                      </Col>
                    </Row>
                    <Row className="pt-2">
                      <Col md={12} lg={6}>
                        <b>Unit Type: &nbsp;</b> {unit.type}
                      </Col>

                      <Col md={12} lg={6}>
                        <b>Police Station: &nbsp;</b> {unit.ps}
                      </Col>
                    </Row>
                    <Row className="pt-2">
                      <Col md={12} lg={6}>
                        <b>Landmark: &nbsp;</b> {unit.landmark}
                      </Col>
                      <Col md={12} lg={6}>
                        <b>District: &nbsp;</b> {unit.district}
                      </Col>
                    </Row>
                    <Row className="pt-2">
                      <Col md={12} lg={12}>
                        <b>Address: &nbsp;</b> {unit.address}
                      </Col>
                    </Row>
                    <hr />
                    <Row className="pt-2">
                      <Col md={12} lg={6}>
                        <b>Owner Name: &nbsp;</b> {unit.owner_name}
                      </Col>
                      <Col md={12} lg={6}>
                        <b>Business Type: &nbsp;</b> {unit.business_type}
                      </Col>
                    </Row>
                    <Row className="pt-2">
                      <Col md={12} lg={6}>
                        <b>Contact: &nbsp;</b> {unit.contact_no}
                      </Col>
                      <Col md={12} lg={6}>
                        <b>Email: &nbsp;</b> {unit.email}
                      </Col>
                    </Row>
                    <Row className="pt-2">
                      <Col md={12} lg={6}>
                        <b>DIN: &nbsp;</b> {unit.din_no}
                      </Col>
                      <Col md={12} lg={6}>
                        <b>GST: &nbsp;</b> {unit.gst_no}
                      </Col>
                    </Row>
                    <Row className="pt-2">
                      <Col md={12} lg={6}>
                        <b>Created At: &nbsp;</b>{" "}
                        {moment(unit.created_at).format("LLL")}
                      </Col>
                      <Col md={12} lg={6}>
                        <b>Last Updated: &nbsp;</b>{" "}
                        {moment(unit.updated_at).format("LLL")}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal isOpen={this.state.modalVisible}>
              <Container fluid>
                <Row className="p-2">
                  <h2>Update Payment Status</h2>
                </Row>
                <Row className="p-2">
                  <Col md={12} lg={12}>
                    Remarks{" "}
                    <textarea
                      className="form-control"
                      value={this.state.remarks}
                      onChange={(e) =>
                        this.setState({ remarks: e.target.value })
                      }
                    ></textarea>
                  </Col>
                </Row>
                <Row className="p-2">
                  <Col md={7} lg={7}>
                    Payment Status: &nbsp;{" "}
                    <select
                      className="select"
                      onSelect={(e) =>
                        this.setState({
                          status: parseInt(e.currentTarget.value),
                        })
                      }
                    >
                      <option value="0">Paid</option>
                      <option value="1">Not Paid</option>
                    </select>
                  </Col>
                  <Col md={5} lg={5}>
                    <Button
                      className="btn btn-primary btn-sm ml-2 mr-2"
                      style={{ float: "right" }}
                      onClick={() => {
                        this.updateUnitPaymentStatus(
                          unit.id,
                          this.state.remarks,
                          this.state.status
                        );
                        // this.getUnitDetails(unit.id);
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      className="btn btn-primary btn-sm ml-2 mr-2"
                      style={{ float: "right" }}
                      onClick={() => this.setState({ modalVisible: false })}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal>
          </Container>
        </>
      );
  }
}

import React from "react";
import Header from "../../../components/Headers/Header";
import Helper from "../../../helpers/helper";
import * as UnitAPI from "../../../API/unitapi";
import DashboardApi from "../../../API/dashboardapi";
import {
  FormGroup,
  Input,
  Button,
  Card,
  CardBody,
  CardTitle,
  ButtonGroup,
} from "reactstrap";
import {
  Col,
  Container,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledCollapse,
} from "reactstrap";

import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import SelectSearch from "react-select-search";

import "../../../assets/css/custom.css";
import loder1 from "../../../assets/loder1.gif";
import VerificationsList from "./VerificationsList";
import UnitList from "./UnitList";

export default class UnitDashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      paramData: {
        per_page: 12,
        page_no: 1,
        status: 3,
      },
      searchData: {
        district: [],
        name: "",
        type: "",
      },
      stats: {
        total_units: 0,
        pending_units: 0,
        total_complaints: 0,
        pending_complaints: 0,
      },
      fetchInProgress: false,
      activePage: 1,
      totalDataCount: 0,
      activeType: 0,
      activeSearchFilter: 0,
    };
    //activeType possible values 0 for Units and 1 for Complaints
    //activeSearchFilter possible values 0 for none, 1 for search 2 for filter 3 for others; only one can be opened at once
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  loadVerificationsData = () => {
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      UnitAPI.default
        .getVerificationList(
          "/verification/get_all_verifications",
          this.state.paramData
        )
        .then((result) => {
          if (result.status === "success") {
            let totalItem = result.total_data;
            this.setState({ totalDataCount: totalItem });
            this.setState({ data: result.data, fetchInProgress: false });
            console.log(result.data);
          } else {
            this.setState({
              errorMsg: "Error! Please try after some time.",
              fetchInProgress: false,
            });
          }
        });
    });
  };
  loadUnitsData = () => {
    // if(typeof this.props.location?.state !== "undefined" && this.props.location.state.hasOwnProperty('page_no')&& typeof this.props.location.state.page_no === 'number'): this.setState({paramData: {...this.state.paramData,page_no: this.props.location.state.page_no}})
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      UnitAPI.default
        .getUnitList("/verification/get_all_units", this.state.paramData)
        .then((result) => {
          if (result.status === "success") {
            let totalItem = result.total_data;
            this.setState({ totalDataCount: totalItem });
            this.setState({ data: result.data, fetchInProgress: false });
            console.log(this.state.data);
          } else {
            this.setState({
              errorMsg: "Error! Please try after some time.",
              fetchInProgress: false,
            });
          }
        });
    });
  };
  searchUnitsData = () => {
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      UnitAPI.default
        .searchUnitList(
          "/verification/search_units",
          this.state.paramData,
          this.state.searchData
        )
        .then((result) => {
          if (result.status === "success") {
            let totalItem = result.total_data;
            this.setState({ totalDataCount: totalItem });
            this.setState({ data: result.data, fetchInProgress: false });
            console.log(this.state.data);
          } else {
            this.setState({
              errorMsg: "Error! Please try after some time.",
              fetchInProgress: false,
            });
          }
        });
    });
  };

  handleChangeInActiveSearchFilter = (value) => {
    //the value must be number
    this.setState({ activeSearchFilter: value });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    this.setState((prevState) => ({
      paramData: { ...prevState.paramData, page_no: pageNumber },
    }));
    // this.state.activeType === 1
    //   ? this.loadVerificationsData()
    //   : this.loadUnitsData();
    this.loadUnitsData();
  };

  assignStatusLabel = (status) => {
    switch (parseInt(status)) {
      case 0:
        return "Initiated";
      case 1:
        return "Approved";
      case 2:
        return "Rejected";
      default:
        return "Unknown";
    }
  };

  assignColor = (status) => {
    switch (parseInt(status)) {
      case 0:
        return {
          backgroundColor: "#004F93",
          background:
            "linear-gradient(135deg, rgba(0, 79, 147,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)",
          boxShadow: "box-shadow: 3px 3px 5px 0px rgba(0, 79, 147,0.6)",
        };
      case 1:
        return {
          backgroundColor: "#11740F",
          background:
            "linear-gradient(135deg, rgba(17, 116, 15,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)",
          boxShadow: "box-shadow:3px 3px 5px 0px rgba(17, 116, 15,0.6)",
        };
      case 2:
        return {
          backgroundColor: "#FF0000",
          background:
            "linear-gradient(135deg, rgba(255,0,0,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)",
          boxShadow: "box-shadow: 3px 3px 5px 0px rgba(255,0,0,0.6)",
        };
      default:
        return {
          backgroundColor: "#FFffff",
          background:
            "linear-gradient(135deg, rgba(0,0,0,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)",
          boxShadow: "box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.6)",
        };
    }
  };

  loadStatistics = () => {
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      DashboardApi.getTotalCount("/admin/get_verification_stat").then(
        (result) => {
          if (result.status === "success") {
            this.setState({
              stats: { ...result.data },
              fetchInProgress: false,
            });
          } else {
            this.setState({
              errorMsg: "Error! Please try after some time.",
              fetchInProgress: false,
            });
          }
        }
      );
    });
  };

  componentDidMount() {
    this.loadStatistics();
    // this.state.activeType === 1
    //   ? this.loadVerificationsData()
    //   : this.loadUnitsData();
    this.loadUnitsData();
  }

  onChangeInDistrictSelect = (event) => {
    this.setState({ selectedDistrict: event.target.value });
  };

  onSearchTextChange = (event) => {
    event.preventDefault();
    this.setState({
      searchData: {
        ...this.state.searchData,
        [event.target.name]: event.target.value,
      },
    });
  };

  onSearchChange = (e) => {
    e.preventDefault();
    this.setState({
      paramData: {
        ...this.state.paramData,
        [e.target.name]: parseInt(e.target.value),
      },
    });
    // this.state.activeType === 1
    //   ? this.loadVerificationsData()
    //   : this.searchUnitsData();
    this.searchUnitsData();
  };

  reactSearchSelectCustomClass = (value) => {
    switch (value) {
      case "container":
        return "form-group w-100";
      case "value":
        return "p-0 dropdown w-100";
      case "input":
        return "form-control dropdown-toggle w-100";
      case "select":
        return "form-select";
      case "options":
        return "list-group d-grid gap-2 dropdown-menu districtOptions";
      case "row":
        return "list-group-item p-0";
      case "is-selected":
        return "active btn-primary";
      case "option":
        return "btn w-100 text-left";
      default:
        return "";
    }
  };

  render() {
    const { data, fetchInProgress, searchData, activeType, stats } = this.state;
    return (
      <>
        <Header />
        {fetchInProgress ? (
          <div className="progress2 justify-content-center">
            {" "}
            <img src={loder1} alt="" className="pad" />
          </div>
        ) : (
          <Container className="mt--7" fluid>
            <Row>
              <Col lg="6" xl="3">
                <Link to="complaint">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Complaints
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {stats.total_complaints}
                            
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-book" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col lg="6" xl="3">
                <Link to="complaint">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Pending Complaints
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {stats.pending_complaints}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-book" />
                          </div>
                          <span
                            className="icon icon-shape bg-warning text-white rounded-circle shadow"
                            style={{
                              fontSize: 10,
                              width: 10,
                              height: 10,
                              marginLeft: -10,
                              marginTop: 25,
                              position: "absolute",
                            }}
                          >
                            !
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col lg="6" xl="3">
                <Link to="complaint">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Units
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {stats.total_units}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-industry" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col lg="6" xl="3">
                <Link to="complaint">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Pending Units
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {stats.pending_units}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-industry" />
                          </div>
                          <span
                            className="icon icon-shape bg-warning text-white rounded-circle shadow"
                            style={{
                              fontSize: 10,
                              width: 10,
                              height: 10,
                              marginLeft: -10,
                              marginTop: 25,
                              position: "absolute",
                            }}
                          >
                            !
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12} md={6} className="mt-2">
                <ButtonGroup>
                  {/* <Button
                    className={`btn ${
                      activeType === 0 ? "btn-primary" : "btn-danger"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ activeType: 1 });
                      this.loadVerificationsData();
                    }}
                  >
                    Complaints
                  </Button> */}
                  <Button
                    className={`btn ${
                      activeType === 1 ? "btn-primary" : "btn-danger"
                    }`}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   this.setState({ activeType: 0 });
                    //   this.loadUnitsData();
                    // }}
                  >
                    Units
                  </Button>
                </ButtonGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Status</InputGroupText>
                  </InputGroupAddon>
                  <select
                    id="status"
                    className="form-control"
                    name="status"
                    onChange={(e) => {
                      this.setState({
                        paramData: {
                          ...this.state.paramData,
                          status: parseInt(e.currentTarget.value),
                        },
                      });
                      this.loadUnitsData();
                    }}
                    value={this.state.paramData.status}
                  >
                    <option value="3">All</option>
                    <option value="0">Initiated</option>
                    <option value="1">Approved</option>
                    <option value="2">Rejected</option>
                  </select>
                  <InputGroupAddon addonType="append">
                    <InputGroupText className="p-0">
                      <Button
                        className="btn btn-outline-secondary btn-md"
                        id="filterOptionsButton"
                      >
                        <i class="fas fa-filter"></i>
                      </Button>
                    </InputGroupText>
                  </InputGroupAddon>
                  <InputGroupAddon addonType="append">
                    <InputGroupText className="p-0">
                      <Button
                        className="btn btn-outline-secondary btn-md"
                        id="searchByNameButton"
                      >
                        <i class="fas fa-search"></i>
                      </Button>
                    </InputGroupText>
                  </InputGroupAddon>

                  {/* <InputGroupAddon addonType="append">
                    <InputGroupText className="p-0">
                      <Button
                        disabled={true}
                        className="btn btn-outline-secondary btn-md"
                        id="downloadXlsButton"
                      >
                        <i class="fas fa-file-download"></i>
                      </Button>
                    </InputGroupText>
                  </InputGroupAddon> */}
                </InputGroup>
              </Col>
            </Row>
            {activeType === 0 ? (
              <Row>
                <div className="col-md-12 mb-2 mt-2">
                  <div className="box">
                    <UncontrolledCollapse toggler="#searchByNameButton">
                      <Row className="pt-2">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText
                              style={{ backgroundColor: "#eeeeee" }}
                            >
                              Search Name
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="form-control"
                            id="unitNameInput"
                            placeholder="Name of Unit"
                            type="text"
                            name="name"
                            onChange={this.onSearchTextChange}
                            value={searchData.name}
                            style={{
                              borderTopWidth: 1,
                              borderBottomWidth: 1,
                              paddingLeft: 10,
                            }}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText className="p-0">
                              <Button
                                className="btn btn-outline-secondary btn-md"
                                onClick={this.searchUnitsData}
                              >
                                <i class="fas fa-search"></i>
                              </Button>
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Row>
                      <hr />
                    </UncontrolledCollapse>
                    <UncontrolledCollapse toggler="#filterOptionsButton">
                      <Row className="pt-2">
                        <Col md="4">
                          <label htmlFor="districtSelectSearch">District</label>
                          <SelectSearch
                            id="districtSelectSearch"
                            className={this.reactSearchSelectCustomClass}
                            closeOnSelect={false}
                            printOptions="on-focus"
                            multiple
                            search
                            placeholder="Select your items"
                            options={[
                              { name: "Hooghly", value: "Hooghly" },
                              {
                                name: "Darjeeling",
                                value: "Darjeeling",
                              },
                              {
                                name: "Medinipore(W)",
                                value: "Medinipore(W)",
                              },
                              {
                                name: "Medinipore(E)",
                                value: "Medinipore(E)",
                              },
                              { name: "Burdwan", value: "Burdwan" },
                              {
                                name: "Coochbehar",
                                value: "Coochbehar",
                              },
                              {
                                name: "Dinajpur(N)",
                                value: "Dinajpur(N)",
                              },
                              {
                                name: "Burdwan(W)",
                                value: "Burdwan(W)",
                              },
                              {
                                name: "South 24-Parganas",
                                value: "South 24-Parganas",
                              },
                              {
                                name: "North 24-Parganas",
                                value: "North 24-Parganas",
                              },
                              {
                                name: "Murshidabad",
                                value: "Murshidabad",
                              },
                              { name: "Bankura", value: "Bankura" },
                              {
                                name: "Alipurduar",
                                value: "Alipurduar",
                              },
                              { name: "Maldah", value: "Maldah" },
                              { name: "Birbhum", value: "Birbhum" },
                              { name: "Howrah", value: "Howrah" },
                              { name: "Kolkata", value: "Kolkata" },
                              { name: "Purulia", value: "Purulia" },
                              {
                                name: "Dinajpur(S)",
                                value: "Dinajpur(S)",
                              },
                              {
                                name: "Burdwan(E)",
                                value: "Burdwan(E)",
                              },
                              {
                                name: "Jalpaiguri",
                                value: "Jalpaiguri",
                              },
                              { name: "Nadia", value: "Nadia" },
                            ]}
                            value={this.state.searchData.district}
                            onChange={(e) =>
                              this.setState({
                                searchData: {
                                  ...this.state.searchData,
                                  district: [...e],
                                },
                              })
                            }
                          />
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <label htmlFor="unitTypeSelect">Status</label>
                            <select
                              id="unitTypeSelect"
                              className="form-control"
                              name="type"
                              onChange={(e) =>
                                this.setState({
                                  searchData: {
                                    ...this.state.searchData,
                                    type: parseInt(e.currentTarget.value),
                                  },
                                })
                              }
                              value={searchData.type}
                            >
                              <option value="all">All</option>
                              <option value="0">Registered</option>
                              <option value="1">Resolved</option>
                              <option value="2">Irrelevant</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <ButtonGroup className="w-100">
                            <Button
                              color="primary"
                              className="pad-t w-50"
                              size="md"
                              onClick={this.searchUnitsData}
                            >
                              <i className="fa fa-search"></i>{" "}
                            </Button>
                            <Button
                              color="secondary"
                              className="pad-t w-50"
                              size="md"
                              onClick={this.clearSearch}
                            >
                              Clear{" "}
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                      <hr />
                    </UncontrolledCollapse>
                  </div>
                </div>
              </Row>
            ) : null}
            <Row
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "stretch",
              }}
            >
              {data !== 0 ? (
                this.state.activeType === 1 ? (
                  <VerificationsList
                    data={data}
                    page_no={this.state.paramData.page_no}
                  />
                ) : (
                  <UnitList data={data} />
                )
              ) : null}
            </Row>
            <Row style={{ justifyContent: "center" }}>
              {data !== 0 ? (
                <div className="pag float-right">
                  <Pagination
                    className="pagination pagination-lg"
                    itemClass="pag-item"
                    activePage={this.state.activePage}
                    itemsCountPerPage={12}
                    totalItemsCount={this.state.totalDataCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              ) : null}
            </Row>
          </Container>
        )}
      </>
    );
  }
}

import React from "react";
import ReactExport from "react-export-excel";
import helper from "../../../helpers/helper";
import GreenPujaApi from "../../../API/greenpujaapi";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



class DownloadExcel extends React.Component {
    _isMounted = false;
    constructor(){
        super();
        this.state = {
          popularOrganiser:[],
          topOrganiser:[],
          topUsers:[],

        paramData:{
          per_page:100,
          pageno:1,
          committee_name:'',
          contest_id:1
        },
        fetchInProgress: false,
        redirect: false,
        errors: {}
        };
      }
      componentDidMount() { 
        this._isMounted = true;
       // this.loadData(); 
      }
      componentWillUnmount() {
        this._isMounted = false;
      }
      
      
    loadData=() =>{ 
       
      this._isMounted = true;
        this.setState({ fetchInProgress: true });
          helper.authenticateToken().then(()=>{
            GreenPujaApi.getPopularOrganiserList("/organiser/popular-organisers",this.state.paramData).then((result) => {
                if (this._isMounted) {
                if(result.data===undefined){
                    this.setState({popularOrganiser:[],fetchInProgress: false})
                }
                else{  
                  this.setState({popularOrganiser :result.data,fetchInProgress: false});
                }
            } 
            })
            GreenPujaApi.getTopOrganiserList("/organiser/top-organisers",this.state.paramData).then((result) => { 
                if (this._isMounted) {
                if(result.data===undefined){
                      this.setState({topOrganiser:[],fetchInProgress: false})
                  }
                  else{
                    this.setState({topOrganiser :result.data,fetchInProgress: false});
                  } 
                }
            })
            GreenPujaApi.getTopContestUserList("/contest/top-users",this.state.paramData).then((result) => { 
                if (this._isMounted) {
                if(result.data===undefined){
                  this.setState({topUsers:[],fetchInProgress: false})
               }
               else{
                this.setState({topUsers :result.data,fetchInProgress: false});
               } 
            }
              
            })
        });
      }



    render() {
        const { popularOrganiser,topOrganiser,topUsers}=this.state;
        return (
            
            <>
           
            <ExcelFile element={<button className="btn btn-success" onClick={this.loadData}><i className="fas fa-file-excel-o text-green"></i>Download Excel</button>}>
                <ExcelSheet data={topUsers} name="Top_Users">
                    <ExcelColumn label="Name" value="name"/>
                    <ExcelColumn label="Mobile Number" value="mobile_no"/>
                    <ExcelColumn label="UPI Id" value="upi_id"/>
                    <ExcelColumn label="Organisers Added" value="section_a"/>
                    <ExcelColumn label="Quiz Participated" value="section_b"/>
                     <ExcelColumn label="Score" value="Total"/>
                </ExcelSheet>

                <ExcelSheet data={topOrganiser} name="Top_Organiser">
                    <ExcelColumn label="Committee Name" value="puja_committee_name"/>
                    <ExcelColumn label="President Name" value="president_name"/>
                    <ExcelColumn label="President Contact" value="president_contact"/>
                    <ExcelColumn label="Secretary Name" value="secretary_name"/>
                    <ExcelColumn label="Secretary Contact" value="secretary_contact"/>
                    <ExcelColumn label="Idol Maker Name" value="idol_maker_name"/>
                    <ExcelColumn label="Idol Maker Contact" value="idol_maker_contact"/>
                    <ExcelColumn label="Score" value="TotalSum"/>
                    
                </ExcelSheet>

                <ExcelSheet data={popularOrganiser} name="Popular_Organiser">
                    <ExcelColumn label="Committee Name" value="puja_committee_name"/>
                    <ExcelColumn label="President Name" value="president_name"/>
                    <ExcelColumn label="President Contact" value="president_contact"/>
                    <ExcelColumn label="Secretary Name" value="secretary_name"/>
                    <ExcelColumn label="Secretary Contact" value="secretary_contact"/>
                    <ExcelColumn label="Idol Maker Name" value="idol_maker_name"/>
                    <ExcelColumn label="Idol Maker Contact" value="idol_maker_contact"/>
                    <ExcelColumn label="Total Users Participated" value="TotalUserParticipation"/>
                    <ExcelColumn label="Score" value="TotalSum"/>
                  
                </ExcelSheet>

                

                
                
            </ExcelFile>
            </>
        );
    }
}
 export default DownloadExcel
import React, { Fragment } from "react";
import '../../../assets/css/custom.css';
// reactstrap components
import { 
  Row,
  Col,
  Card,
  CardBody,
  Container, CardTitle
} from "reactstrap";
//import ReactDatetime from "react-datetime";
// core components
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import loder1 from '../../../assets/loder1.gif';
import Helper  from '../../../helpers/helper';
import GreenPujaApi from "../../../API/greenpujaapi";
import ContestHeader from "../../../components/Headers/ContestHeader";
import OrganiserList from "./OrganiserList";


class ContestDashboard extends React.Component {
  
  constructor() {
    super();
    this.state = {
        totalcount: {},
        fetchInProgress:false
    };
    
  }
  componentDidMount() {
     Helper.authenticateToken().then(() => {
      let  data={} 
      this.setState({ totalcount: data,fetchInProgress:true });
      GreenPujaApi.getContestSummary("/contest/summary").then((result) => {
        
        this.setState({ totalcount: result.data,fetchInProgress:false });
      }) 

    }) 
  } 

  
    
    render() {
        const { totalcount,fetchInProgress } = this.state;
        return (
          <Fragment>
            <ContestHeader/>
           {fetchInProgress ? <div className="progress-dash justify-content-center"> <img src={loder1} alt="" className="pad"/></div> :
            <div> 
         
              <Container className="mt--7" fluid>
                <div className="header-body">
                  {/* Card stats */}
                  <Row>
                    <Col lg="6" xl="3">
                      <Link to="contest-topresult">
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Top Organiser & Users
                              </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                <i className="fas fa-book"/>
                                </div>
                              </Col>
                            </Row>
    
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                    <Col lg="6" xl="3">
                      <Link to="question-list">
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Question
                              </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                 
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                <i className="far fa-newspaper"/>
                                </div>
                              </Col>
                            </Row>
    
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                    
                    <Col lg="6" xl="3">
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Participants
                              </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {totalcount.TotalParticipants} 
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                  <i className="fas fa-users" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" xl="3">
                      <Link to="contest-setting">
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  Contest Setting
                              </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                  <i className="fas fa-cogs" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                  </Row>
                  <br></br>
                  
                </div>

                <Row>
                <Col lg="12" xl="12" >
                  <CardBody>
                      <OrganiserList/>
                      
                  </CardBody>
                
                </Col>
                
                </Row>

              </Container>
            </div>
            
            
           }
         </Fragment>
        );
      }
    
  
}

export default ContestDashboard;

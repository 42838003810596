import React, { Component } from 'react'
        import Slider from 'react-slick';
        import 'slick-carousel/slick/slick.css';
        import 'slick-carousel/slick/slick-theme.css';
        import MagicSliderDots from 'react-magic-slider-dots';
        import 'react-magic-slider-dots/dist/magic-dots.css';

const ImageSlider = ({items}) => {
   const images=[]
    for(let i = 0; i < items.length; i++){
        images.push(items[i]);
      }
   
      const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 300,
        
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: (dots) => {
          return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
        }
      };
  return (
    <Slider {...settings} autoplay={100}>
   {images.map((e)=>{
    return  <div >
    <img src={e} alt={e} width={"100%"} height={300} style={{objectFit:'contain'}} />
  </div>
   })}
   
  </Slider>
  )
}

export default ImageSlider
import querystring from 'querystring';
import {BASE_URL} from './config/config';
//import moment from 'moment';
export  function  GetToken(url, userData) {
    return new Promise(async(resolve, reject) => {
       await fetch(BASE_URL + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic cGFyaWJlc2hWMTpwYXJpYmVzaFYxLTEyM1NlY3JldA'
            },
            body: querystring.stringify(userData)
        })
        .then((response) => resolve(response.json()))
        .catch((error) => {
            reject(error);
        });
    });
}
import React, { Fragment, SyntheticEvent, useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import { DateRange } from "react-date-range";

const DateRangePicker = ({ filterByDateRange }) => {
  const [isEndDate, setIsEndDate] = useState();
  const [isStartDate, setIsStartDate] = useState();

  const [anchorEl, setAnchorEl] = useState();
  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = (url) => {
    if (url) {
      // router.push(url)
    }
    setAnchorEl(null);
  };
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const onChangeHandler = (item) => {
    setState([
      //@ts-ignore
      item.selection,
    ]);
    filterByDateRange(item);
  };

  var endDate = moment(state[0].endDate).format("DD/MM/YYYY");
  var startDate = moment(state[0].startDate).format("DD/MM/YYYY");

  useEffect(() => {
    endDate = moment(state[0].endDate).format("DD/MM/YYYY");
    startDate = moment(state[0].startDate).format("DD/MM/YYYY");
    setIsEndDate(endDate);
    setIsStartDate(startDate);
    if (endDate !== startDate) {
      setAnchorEl(null);
    }
  }, [state]);

  return (
    <Fragment>
      <div onClick={handleDropdownOpen} className=" border rounded p-1">
        <h3
          style={{
            color: "#97a2c5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 3,
          }}
          className=" text-center  select-none "
        >
          {isStartDate}&nbsp; {isEndDate}
        </h3>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& .MuiMenu-paper": {
            width: 330,
            borderRadius: 4,
            alignItems: "center",
            marginTop: "20px",
          },
        }}
      >
        <DateRange
          editableDateInputs={true}
          onChange={(item) => onChangeHandler(item)}
          moveRangeOnFirstSelection={false}
          //@ts-ignore
          ranges={state}
        />
      </Menu>
    </Fragment>
  );
};

export default DateRangePicker;

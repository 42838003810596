import React, { Fragment } from "react";
import '../../../assets/css/custom.css';
import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  Alert
} from "reactstrap";
import Helper  from '../../../helpers/helper';
import GreenPujaApi from '../../../API/greenpujaapi'
import ContestHeader from "../../../components/Headers/ContestHeader";
import { withRouter } from 'react-router';
import 'react-datepicker/dist/react-datepicker.css';
class QuestionSetAdd extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      shareholders: [{ name: "" }],
      contest_id:"",
      visibleSuccess: false,
      visibleError: false,
      successmsg: {},
      fetchInProgress: false,
      redirect:false,
      errors: {},
      contest_list:[]
    };
    this.changeContest=this.changeContest.bind(this);
  }
  componentDidMount() { 
    let all_contest=[];
    this.setState({ fetchInProgress: true });
     Helper.authenticateToken().then(()=>{
      GreenPujaApi.getLatestContest("/organiser/getLatestContest").then((result) => {
        let item;
        for(var i=0;i<result.data.length;i++){
          item={
            contest_id:result.data[i].contest_id,
            contest_name:result.data[i].contest_name
        }
        all_contest.push(item);
        }
        if(all_contest.length===1){
          this.setState({ contest_id:all_contest[0].contest_id })
        }
        this.setState({contest_list:all_contest});
      
      })

    }) 
    
    
  }
  changeContest=e=>{   
    this.setState({ contest_id: e.target.value })
  }
  handleNameChange = evt => {
    this.setState({ name: evt.target.value });
  };

  handleShareholderNameChange = idx => evt => {
    const newShareholders = this.state.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, name: evt.target.value };
    });

    this.setState({ shareholders: newShareholders });
  };

  handleSubmit = evt => {
    evt.preventDefault();
    GreenPujaApi.addQuestionSet('/contest/question-set-add', this.state.shareholders,this.state.contest_id).then((result) => {
    
      if (result.data.status === "success") {
        this.setState({ visibleSuccess: true, fetchInProgress: false, successmsg: result.data.error_description });
        setTimeout(function () {
          this.setState({ visibleSuccess: false });
          window.location.reload();
        }.bind(this), 3000) 
      }  else {
        this.setState({ errors: result.errors, fetchInProgress: false, visibleError: true });
      }
    }).catch(err=>{
      //this.setState({ errors:err.response.data.error_description, fetchInProgress: false, visibleError: true });
    }) 
  };

  handleAddShareholder = () => {
    this.setState({
      shareholders: this.state.shareholders.concat([{ name: "" }])
    });
  };

  handleRemoveShareholder = idx => () => {
    if(this.state.shareholders.length>1){
      this.setState({
        shareholders: this.state.shareholders.filter((s, sidx) => idx !== sidx)
      });
    }
  };

  render() {
    const {  errors,visibleSuccess, visibleError,successmsg } = this.state;
    
    return (
      <Fragment>
        <ContestHeader/>
        <Container className="mt--7" fluid>
        <Row>
            <div className="col">
              <Card className="shadow card-bg">
                <CardHeader className="border-0">
                <div className="row">
                  <div className="col-md-6 col-6 pt-2">
                    Question Add
                  </div>
                  <div className="col-md-6 col-6 text-right">
                     <button type="button" className="btn btn-primary md" onClick={ () => this.props.history.go(-1)}>Back</button>
                  </div>
                 </div>
                </CardHeader>
                <CardBody>
                {visibleSuccess ? <Alert color="success" isOpen={visibleSuccess} toggle={this.onDismiss}>
                      {successmsg}
                  </Alert> : visibleError ? <Alert color="warning"  isOpen={visibleError} toggle={this.onDismiss}>
                      {
                        errors.map((item, index) =>{
                              return (<> <span key={index} >{item.msg} of {item.param}</span> <br /> </>);
                        })
                      }
                  </Alert>:''}
      <Form onSubmit={this.handleSubmit}>
                  <Row>
                      <Col md="2">
                          <FormGroup>
                            <label htmlFor="Contest">Contest Name<span className="error-message">*</span></label>
                            
                          </FormGroup>
                      </Col>
                      <Col md="6">
                          <FormGroup>
                            <select  id="contest_id" className="form-control" name="contest_id" onChange={this.changeContest}>
                                {this.state.contest_list.map((item, index) =>{
                                    return(<option value={item.contest_id} key={index}>{item.contest_name}</option>)
                                })}
                            </select>
                          </FormGroup>
                      </Col>
                  </Row>
                  <br></br>
              

        {this.state.shareholders.map((shareholder, idx) => (
          <div className="shareholder" key={idx} >
            <Row key={idx}>
            <Col md="8">
              <FormGroup>
                <Input className="form-control-alternative"
                  type="text"
                  placeholder={`Question No.${idx + 1}`}
                  value={shareholder.name}
                  onChange={this.handleShareholderNameChange(idx)}
                  required="required"
                />
              </FormGroup>
            </Col>
            
            <Col md="2">
              <FormGroup>
                <Button color="primary" className="is-active" size="md"
                  type="button"
                  onClick={this.handleRemoveShareholder(idx)}
                  style={this.state.shareholders.length===1 ? { display: 'none' } :{} }
                >
                  {' '}<i className="ni ni-fat-delete"></i> {' '}
                
                </Button>
              </FormGroup>
            </Col>
            </Row>
            <br></br>
          </div>
        ))}
        <br></br>
        <Button color="info" className="is-active" size="md"
          onClick={this.handleAddShareholder}>
          Add Question
        </Button>
        <Button color="primary" className="is-active" size="md">Submit</Button>
      </Form>
    
          </CardBody>
          </Card>
          </div>
          </Row>
      </Container>
      </Fragment>
    );
  }
}

export default withRouter(QuestionSetAdd);

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import history from "./history";
import "./index.css";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import AdminLayout from "./layouts/Admin.jsx";
import AuthLayout from "./layouts/Auth.jsx";
import { registerLicense } from "@syncfusion/ej2-base";



// Registering Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkJjXn9ccXdXRWhbWEM="
);

require("dotenv").config();

ReactDOM.render(
  <BrowserRouter history={history}>
    <Switch>
      {/* <Route path="/" render={props => <AdminLayout {...props} />} />
      <Route path="/auth" render={props => <AuthLayout {...props} />} />
      <Redirect from="/" to="/auth/login" /> */}

      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />

      <Redirect from="/" to="/auth/login" />
      <Redirect from="/privacy-policy" to="/auth/privacy-policy" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

import React from "react";
import {
  FormGroup,
  Form, 
  Input,
  Container,
  Row,
  Alert,
  Badge,
  Modal,
  Button,
  Col
} from 'reactstrap';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Pagination from "react-js-pagination";
import '../../../assets/css/custom.css';
import Header from '../../../components/Headers/Header';
import UserApi from '../../../API/userapi';
import Helper from '../../../helpers/helper';
import moment from '../../../../node_modules/moment';
import { Link } from 'react-router-dom';
import loder1 from '../../../assets/loder1.gif';
import magnifyIcon from '../../../assets/img/magnify.png';

class UserList extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      paramData: {
        per_page: 12,
        pageno: 1,
        userName:'',
        UserMobile:''
      },
      fetchInProgress: false,
      activePage: 1,
      totalDataCount: 1,
      action: {
        user_type: '',
        user_id: '',
        is_active: ''
      },
      successMsg: '',
      errorMsg: '',
      isModalOpen: false
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.onActionTaken = this.onActionTaken.bind(this);
  }
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    this.setState(prevState => ({
      paramData: { ...prevState.paramData, pageno: pageNumber }
    }))
    this.loadData();
  }
  loadData = () => {
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      UserApi.getUserList("all", this.state.paramData).then((result) => {
        let totalItem = result.total_data;
        this.setState({ totalDataCount: totalItem });
        this.setState({ data: result.data, fetchInProgress: false });
      });
    })
  }
  componentWillMount() {
    this.loadData();
  }

  onActionTaken(user_id, user_type, is_active) {
    let newdata = {
      user_id: user_id,
      user_type: user_type,
      is_active: is_active
    }
    this.setState({ action: newdata });
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      UserApi.updateUserAction('/admin/user-action', this.state.action).then((result) => {
        if (result.data.status === 'success') {
          this.setState({ successMsg: result.data.error_description, isModalOpen: true });
          this.setState({ fetchInProgress: false });
          setTimeout(function () {
            window.location.reload();
          }, 4000)
        } else {
          this.setState({ fetchInProgress: false, isModalOpen: true, errorMsg: 'Error! Please try after some time.' });
        }
      });
    })
  }

  toggleModal = state => {
    this.setState({ isModalOpen: false });
  };
  seachUser=(e)=>{
    e.preventDefault();
     this.loadData();
   }
 clearSearch=(e)=>{
   this.setState(prevState => ({
     paramData: {...prevState.paramData,userName:'',UserMobile:''}
   }))
   e.preventDefault();
     this.loadData();
 }
 onSearchChange=e=>{
  this.setState({
    paramData: { ...this.state.paramData, [e.target.name]: e.target.value }
  })
}

  render() {
    const { fetchInProgress, data,paramData } = this.state;

    return (
      <>
        <Header />
        {fetchInProgress ? <div className="progress2 justify-content-center"> <img src={loder1} alt="" className="pad"/></div> :
          <Container className="mt--7" fluid>
            <div className="mb-2" style={{ textAlign: "right" }}>
              <Link to="add-user">
                <Button className="is-active" color="primary" size="md">
                  <span>Add User</span>
                </Button>
              </Link>
            </div>
            <Row>
            <div className="col-md-12 mb-2">
                <div className="box">
                
                  <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton >
                          <div className="head"><img src={magnifyIcon} className="mr-3" alt=""/> Search...</div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                       <AccordionItemPanel>
                       <Form encType="multipart/form-data">
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <label>User Name</label>
                              <Input
                          className="form-control-alternative" id="userName" placeholder="User Name" type="text" name="userName" 
                          onChange={this.onSearchChange} value={paramData.userName} />
                            </FormGroup>
                          </Col>
                          
                          <Col md="4">
                          <FormGroup>
                              <label>Phone Number</label>
                              <Input
                          className="form-control-alternative" id="UserMobile" placeholder="Phone Number" type="text" name="UserMobile" 
                          onChange={this.onSearchChange} value={paramData.UserMobile} />
                            </FormGroup>
                          </Col>
                          <Col md="4" className="">
                          <Button color="primary" className="pad-t" size="md" onClick={this.seachUser}><i className="fa fa-search"></i> </Button>
                          <Button color="primary" className="pad-t" size="md" onClick={this.clearSearch}>Clear </Button>
                          </Col>
                        </Row>
                       </Form>
                       </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
             
              {(data !== 0) ?
                data.map((item, index) => {
                  return (
                    <Col lg="3" className="mb-2" key={index}>
                      <div className="card testimonial-card">
                        <div className="card-up indigo lighten-1"></div>
                        <div className="avatar mx-auto white" style={{ marginTop: "10px" }}>
                          <img src={(item.images.length >= 1) ? item.images : require("../../../assets/img/theme/dummy256x256.png")} className="rounded-circle" alt="user avatar" style={{ height: "100%" }} />
                        </div>
                        <div className="card-body">
                          <h5 className="text-center">
                            <Badge color="success">{(item.is_admin === 0 && item.user_type === 1) ? 'Volunteer' : (item.is_admin === 0 && item.user_type === 2) ? 'Regs. User' : (item.is_admin === 1 && item.user_type === 2) ? 'BOC User' : 'Admin User'} </Badge> &nbsp;&nbsp;
                          <Badge color={item.is_active === 1 ? 'info' : 'danger'}>
                              {item.is_active === 0 ? 'Inactive User' : item.is_active === 1 ? 'Active User' : item.is_active === 2 ? 'Deleted' : 'Banned'}
                            </Badge>
                          </h5>
                          <hr />
                          <h4 className="card-title" style={{ marginBottom: "0.25rem" }}>{((item.name !== null && item.name !== '') ? ((item.title !== null && item.title !== '') ? item.title : '') + ' ' + item.name : item.username)} </h4>
                          <small>Last Seen - {item.last_seen !== null && item.last_seen !== '' ? moment(item.last_seen).calendar() : 'Not Updated'}</small>
                          <hr />

                          <small>
                            Email ID - <br /><b>
                              {/* {((item.email).length > 25) ? (((item.email).substring(0,25-3)) + '...') : item.email} */}
                              {item.email !== null && item.email !== '' ? ((item.email).length > 28) ? (((item.email).substring(0, 28 - 3)) + '...') : item.email : 'Not Provided'}</b>
                            <br />
                            Mobile No. - <b>{item.mobile_no !== null && item.mobile_no !== '' ? item.mobile_no : 'Not Provided'}</b>
                          </small>
                        </div>
                        <div className="card-footer" style={{ textAlign: "right" }}>
                          {/* 1:volunteer 2:registereduser 3:admin 4:BOC */}
                          <ButtonDropdown dropup size="sm" className="mr-2">
                            <DropdownToggle caret color="primary" style={{ padding: "0.4rem 1.6rem" }}>
                              Take Action
                            </DropdownToggle>
                            <DropdownMenu basic>
                              {item.user_type === 1 ? <DropdownItem onClick={this.onActionTaken.bind(this, item.id, 2, item.is_active)}>Make Reg. User</DropdownItem> : <DropdownItem onClick={this.onActionTaken.bind(this, item.id, 1, item.is_active)}>Make Volunteer</DropdownItem>}
                              <DropdownItem divider />
                              {/* 0:Inactive 1:Active 2:Delete 3:Ban */}
                              <DropdownItem onClick={this.onActionTaken.bind(this, item.id, item.user_type, 1)}>Activate User</DropdownItem>
                              <DropdownItem onClick={this.onActionTaken.bind(this, item.id, item.user_type, 0)}>Inactivate User</DropdownItem>
                              <DropdownItem onClick={this.onActionTaken.bind(this, item.id, item.user_type, 2)}>Delete User</DropdownItem>
                              <DropdownItem onClick={this.onActionTaken.bind(this, item.id, item.user_type, 3)}>Ban User</DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                          <Link to={{ pathname: 'user-details', state: { userid: item.id } }} >
                            <Button className="is-active" color="success" size="sm" id={item.id} >
                              <span>View</span>
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  )
                }) : <Alert color="warning">
                  <strong>Sorry!</strong>  No Data Found!
            </Alert>
              }

              <Modal className="modal-dialog-centered"
                isOpen={this.state.isModalOpen}
                toggle={() => this.toggleModal("isModalOpen")}
              >
                <div className="modal-header">
                  <h3 className="modal-title" id="exampleModalLabel">
                    {this.state.successMsg !== '' ? 'Success' : this.state.errorMsg !== '' ? 'Error' : ''}
                  </h3>
                  <button aria-label="Close" className="close" data-dismiss="modal" type="button"
                    onClick={() => this.toggleModal("isModalOpen")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">

                  <Row>
                    <Col md="12">
                      <h1 color="green">{this.state.successMsg !== '' ? this.state.successMsg : this.state.errorMsg !== '' ? this.state.errorMsg : ''}</h1>
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("exampleModal")}
                  >
                    Close
                  </Button>
                </div>
              </Modal>
            </Row>
            <Row style={{justifyContent: "flex-end"}}>
              {data !== 0 ?
                <div className="pag">
                  <Pagination className="pagination pagination-lg"
                    listClassName="pagination-lg"
                    activePage={this.state.activePage}
                    itemsCountPerPage={paramData.per_page}
                    totalItemsCount={this.state.totalDataCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div> : ''
              }
            </Row>
          </Container>
        }
      </>
    );
  }
}
export default UserList;

import React from "react";
  import { Link } from 'react-router-dom';
import '../../../assets/css/custom.css';

import moment from 'moment';


const UnitList = ({data, page_no}) => {

    // const assignColor = (status) => {
    //     switch(parseInt(status)){
    //         case 0:     return {backgroundColor: '#004F93', background: 'linear-gradient(135deg, rgba(0, 79, 147,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)', boxShadow: 'box-shadow: 3px 3px 5px 0px rgba(0, 79, 147,0.6)'};
    //         case 1:     return {backgroundColor: '#11740F', background: 'linear-gradient(135deg, rgba(17, 116, 15,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)', boxShadow: 'box-shadow:3px 3px 5px 0px rgba(17, 116, 15,0.6)'};
    //         case 2:     return {backgroundColor: '#FF0000', background: 'linear-gradient(135deg, rgba(255,0,0,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)', boxShadow: 'box-shadow: 3px 3px 5px 0px rgba(255,0,0,0.6)'};
    //         default:    return {backgroundColor: '#FFffff', background: 'linear-gradient(135deg, rgba(0,0,0,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)', boxShadow: 'box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.6)'}
    //     }
    // }

    const getStatusColorAndText = (status) => {
        console.log("reaching here");
        switch (status) {
          case 0:
            return { color: "#ffd600", text: "Requested", background: 'linear-gradient(45deg, rgb(128 106 0), rgb(255 233 124))', bgcolor: 'rgb(255 233 124)' };
            // return { color: "info", text: "Requested", background: 'linear-gradient(45deg, #A4C0CC, #C3F2FB)' };
          case 1:
            return { color: "success", text: "Approved", background: 'linear-gradient(45deg, #47B276, #C9F3E1)' };
          case 2:
            return { color: "warning", text: "Rejected", background: 'linear-gradient(45deg, #fb6340, #fee4de)' };
          default:
            return { color: "light", text: "Unknown", background: 'linear-gradient(45deg, #888888, #111111)' };
        }
      };

    return (
        <>
        {data!==0?data.map((item, index) => {
            const VolunteerStatus = getStatusColorAndText(item.status)
            return (
              <div
                className="col-xl-4 col-lg-4 col-md-12 col-sm-6, mb-2"
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "stretch",
                  justifyContent: "stretch",
                  maxWidth: 400,
                  minWidth: 300,
                }}
              >
                <div
                  className="backgroundBorder p-1"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "stretch",
                    justifyContent: "center",
                    flexGrow: 1,
                    borderRadius: 10,
                    backgroundImage: VolunteerStatus.background,
                  }}
                >
                  <div
                    className="card testimonial-card"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                      justifyContent: "center",
                      alignItems: "space-around",
                    }}
                  >
                    <div className="card-up indigo lighten-1">
                      <div className="col-12">
                        <div className="row">
                          <h4 style={{ padding: 10}}>Unit ID: #{item.id}</h4>
                        </div>
                        <div className="row">
                          <h3
                            className="card-title p-2 mb-1 pt-0 mt-0"
                            style={{ fontSize: 16 }}
                          >
                            Unit Name: <b>{item.name}</b>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-1">
                      <div className="col-12">
                        <img
                          src={
                            item.images.length > 0
                              ? item.images[0]
                              : require("../../../assets/img/noimage.png")
                          }
                          className="img-fluid cat-img"
                          alt="user avatar"
                          style={{ height: "100%", minWidth: 190 }}
                        />
                      </div>
                      <div className="">
                        <div
                          className="col-12 p-1 pb-2"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignContent: "center",
                            gap: 10,
                          }}
                        >
                          <div className="small" style={{ flex: 1 }}>
                            Unit Type: <b>{item.type}</b>
                          </div>
                          <div className="small" style={{ flex: 1 }}>
                            Unit District: <b>{item.district}</b>
                          </div>
                        </div>

                        <div className="des mr-2 ml-1">
                          <b style={{color: '#555555'}}>Address: {" "}</b>
                          {String(item.address).length > 40
                            ? String(item.address).substring(0, 49) + "..."
                            : String(item.address)}
                        </div>
                        <div className="small mr-2 ml-1">
                          Added on:{" "}
                          <b>{moment(item.created_at).format("ll")}</b> by <b>{item["User.name"]}</b>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer" style={{ textAlign: "right" }}>
                      <Link
                        to={{
                          pathname: "unit-show",
                          state: { UnitData: item, page_no: page_no },
                        }}
                      >
                        <button type="button" className="btn btn-info btn-sm">
                          Show
                        </button>
                      </Link>{" "}
                    </div>
                  </div>
                </div>
              </div>
            );
        }):null}
        </>
    )
}

export default UnitList;
import axios from "axios";
import {BASE_URL} from '../config/config';
import qs from 'querystring'

  const getOrganizerList=(url, paramData) =>{ 
 
        const keyValuePairs = [];
        for (const key in paramData) {
             if(key==='committee_name' && paramData[key]==='')
                continue;
            /*if(key==='token_no' && paramData[key]==='')
                continue;  */ 
          keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(paramData[key]));
        }
       let newParam="?"+keyValuePairs.join('&');

        return new Promise((resolve, reject) => {
            let accesstoken=JSON.parse(localStorage.getItem('userData')).access_token;
            fetch(BASE_URL + url+newParam, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer '+accesstoken
                },
               // params: qs.stringify(userData)
            })
                .then((response) =>{ resolve(response.json())})
                .catch((error) => {
                    reject(error);
                });
    
        });
    } 
    
    const getOrganizerDetails = (url) => {  
        return new Promise((resolve, reject) => {
            let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
            fetch(BASE_URL + url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer ' + accesstoken
                },
                //params: qs.stringify(newsdetails)
            })
                .then((response) => { resolve(response.json()) })
                .catch((error) => {
                    reject(error);
                });
    
        });
    }
    const addQuestionSet = (url, newsData,contestID) => {
        return new Promise((resolve, reject) => {
            let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
            let formData = new FormData();
            var key = null;
            let all_question=[];
            let item;
             for(key in newsData) {
                formData.append( key, newsData[key] );
                item={
                    question:newsData[key].name,
                    contest_id:contestID
                }
                all_question.push(item);
            }
            axios.post(BASE_URL + url, all_question, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + accesstoken
                }})
                .then((response) => { resolve(response) })
                .catch((error) => {
                    reject(error);
                }); 
        });
    }

    const getLatestContest = (url) => {  
        return new Promise((resolve, reject) => {
            let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
            fetch(BASE_URL + url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer ' + accesstoken
                },
                //params: qs.stringify(newsdetails)
            })
                .then((response) => { resolve(response.json()) })
                .catch((error) => {
                    reject(error);
                });
    
        });
    }
    const getQuestionList = (url,data) => {  
        return new Promise((resolve, reject) => {
            let newParam="?organiserId="+data.organiser_id+"&contestId="+data.contest_id;
            let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
            fetch(BASE_URL + url+newParam, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer ' + accesstoken
                },
                //params: qs.stringify(newsdetails)
            })
                .then((response) => { resolve(response.json()) })
                .catch((error) => {
                    reject(error);
                });
    
        });
    }
const getTopOrganiserList=(url, paramData) =>{ 
 
        const keyValuePairs = [];
        for (const key in paramData) {
             if(key==='committee_name' && paramData[key]==='')
                continue;
            /*if(key==='token_no' && paramData[key]==='')
                continue;  */ 
          keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(paramData[key]));
        }
       let newParam="?"+keyValuePairs.join('&');

        return new Promise(async(resolve, reject) => {
            let accesstoken=JSON.parse(localStorage.getItem('userData')).access_token;
           await  fetch(BASE_URL + url+newParam, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer '+accesstoken
                },
               // params: qs.stringify(userData)
            })
                .then((response) =>{ resolve(response.json())})
                .catch((error) => {
                    reject(error);
                });
    
        });
    } 

const getTopContestUserList= (url, paramData) =>{ 
 
        const keyValuePairs = [];
        for (const key in paramData) {
             if(key==='committee_name' && paramData[key]==='')
                continue;
            /*if(key==='token_no' && paramData[key]==='')
                continue;  */ 
          keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(paramData[key]));
        }
       let newParam="?"+keyValuePairs.join('&');

        return  new Promise(async(resolve, reject) => {
            let accesstoken=JSON.parse(localStorage.getItem('userData')).access_token;
            await fetch(BASE_URL + url+newParam, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer '+accesstoken
                },
               // params: qs.stringify(userData)
            })
                .then((response) =>{ resolve(response.json())})
                .catch((error) => {
                    reject(error);
                });
    
        });
    } 
const getPopularOrganiserList=(url, paramData) =>{ 
 
        const keyValuePairs = [];
        for (const key in paramData) {
             if(key==='committee_name' && paramData[key]==='')
                continue;
            /*if(key==='token_no' && paramData[key]==='')
                continue;  */ 
          keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(paramData[key]));
        }
       let newParam="?"+keyValuePairs.join('&');

        return new Promise(async(resolve, reject) => {
            let accesstoken=JSON.parse(localStorage.getItem('userData')).access_token;
            await fetch(BASE_URL + url+newParam, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer '+accesstoken
                },
               // params: qs.stringify(userData)
            })
                .then((response) =>{ resolve(response.json())})
                .catch((error) => {
                    reject(error);
                });
    
        });
    } 
const getContestSummary=(url) =>{   
       let newParam="?contest_id=1";
        return new Promise(async (resolve, reject) => {
            let accesstoken=JSON.parse(localStorage.getItem('userData')).access_token;
            await fetch(BASE_URL + url+newParam, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer '+accesstoken
                },
               // params: qs.stringify(userData)
            })
                .then((response) =>{ resolve(response.json())})
                .catch((error) => {
                    reject(error);
                });
    
        });
    } 
    const contestStartComplete = (url, actionData) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        return new Promise((resolve, reject) => {
            axios.patch(BASE_URL + url, qs.stringify(actionData), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer ' + accesstoken
                }})
                .then((response) => { resolve(response) })
                .catch((error) => {
                    reject(error);
                }); 
            }) 
    }
    const updateQuestionStatus = (url) => {
        let actionData={status:0};
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        return new Promise((resolve, reject) => {
            axios.patch(BASE_URL + url,qs.stringify(actionData), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer ' + accesstoken
                }})
                .then((response) => { resolve(response) })
                .catch((error) => {
                    reject(error);
                }); 
            }) 
    }
const userSMSSend=(url, paramData) =>{ 

    const keyValuePairs = [];
    for (const key in paramData) {
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(paramData[key]));
    }
   let newParam="?"+keyValuePairs.join('&');
   

    return new Promise(async(resolve, reject) => {
        let accesstoken=JSON.parse(localStorage.getItem('userData')).access_token;
        await fetch(BASE_URL + url+newParam, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer '+accesstoken
            },
           // params: qs.stringify(userData)
        })
            .then((response) =>{ resolve(response.json())})
            .catch((error) => {
                reject(error);
            });

    }); 
} 
const organiserSMSSend=(url, paramData) =>{ 
    
    const keyValuePairs = [];
    for (const key in paramData) {
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(paramData[key]));
    }
   let newParam="?"+keyValuePairs.join('&');

  
    return new Promise(async(resolve, reject) => {
        let accesstoken=JSON.parse(localStorage.getItem('userData')).access_token;
        await fetch(BASE_URL + url+newParam, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer '+accesstoken
            },
           // params: qs.stringify(userData)
        })
            .then((response) =>{ resolve(response.json())})
            .catch((error) => {
                reject(error);
            });

    });
} 

const updateOrganisationRating=(url, paramData) =>{ 
    
    const keyValuePairs = [];
    for (const key in paramData) {
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(paramData[key]));
    }
   let newParam="?"+keyValuePairs.join('&');

  
    return new Promise(async(resolve, reject) => {
        let accesstoken=JSON.parse(localStorage.getItem('userData')).access_token;
        await fetch(BASE_URL + url+newParam, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer '+accesstoken
            },
           // params: qs.stringify(userData)
        })
            .then((response) =>{ resolve(response.json())})
            .catch((error) => {
                reject(error);
            });

    });
} 

    


export  default {  
                   getOrganizerList,
                   getOrganizerDetails,
                   addQuestionSet,
                   getLatestContest,
                   getQuestionList,
                   getTopContestUserList,
                   getTopOrganiserList,
                   getPopularOrganiserList,
                   getContestSummary,
                   contestStartComplete,
                   updateQuestionStatus,
                   userSMSSend,
                   organiserSMSSend,
                   updateOrganisationRating};
import qs from 'querystring';
import axios from "axios";
import {BASE_URL,plog} from '../config/config';

const getNewsList = (type, userData) => {
    let url = '/news/';

    let parameter="";
    if(userData.category==="0")
        parameter="?per_page="+userData.per_page+"&pageno="+userData.pageno;
    else
        parameter="?per_page="+userData.per_page+"&pageno="+userData.pageno+"&category="+userData.category;
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url + type+parameter, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
            //params: qs.stringify(userData)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });

    });
}


const addNews = (url, newsData) => {
    plog("result=",newsData);
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        let formData = new FormData();
        var key = null;
         for(key in newsData) {
            formData.append( key, newsData[key] );
        }
        for(key in newsData.images){  
            formData.append( 'images', newsData.images[key] );
        }  
        axios.post(BASE_URL + url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'authorization': 'Bearer ' + accesstoken
            }})
            .then((response) => { resolve(response) })
            .catch((error) => {
                reject(error);
            }); 
    });
}
const getNewsDetails = (url, news_id) => {
    plog("News api--",news_id);

    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url+"?news_id="+news_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
            //params: qs.stringify(newsdetails)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });

    });
}
const updateNews = (url, newsData) => {
    plog("result=",newsData);
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        let formData = new FormData();
        var key = null;
         for(key in newsData) {
            formData.append( key, newsData[key] );
        }
        for(key in newsData.images){  
            formData.append( 'images', newsData.images[key] );
        } 
        axios.patch(BASE_URL + url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'authorization': 'Bearer ' + accesstoken
            }})
            .then((response) => { resolve(response) })
            .catch((error) => {
                reject(error);
            }); 
    });
}
const delteNewsImage = (url, imageDetails) => {
    let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
    return new Promise((resolve, reject) => {
        axios.post(BASE_URL + url, qs.stringify(imageDetails), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            }})
            .then((response) => { resolve(response) })
            .catch((error) => {
                reject(error);
            }); 
        }) 
}

export default { getNewsList, addNews,getNewsDetails,updateNews,delteNewsImage };
import React from "react";
// node.js library that concatenates classes (strings)

import HeaderDashboard from "../components/Headers/HeaderDashboard";

class Index extends React.Component {
  render() {
    return (
      <>
        <HeaderDashboard />
      </>
    );
  }
}

export default Index;

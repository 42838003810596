import React from "react";
import '../../../assets/css/custom.css';
// reactstrap components
import {
  Form,
  Row,
  Card,
  Container,
  CardHeader
} from "reactstrap";
//import ReactDatetime from "react-datetime";
//import { Redirect } from 'react-router-dom';
// core components
import Header from "../../../components/Headers/Header.jsx";
import FedbackApi from '../../../API/feedbackapi';
import Helper  from '../../../helpers/helper';
import moment from 'moment'
import  Rating from  'react-rating';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import loder1 from '../../../assets/loder1.gif';
import { Link } from 'react-router-dom';
class FeedbackShow extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {
        feedback_id: '',
        description: '',
        feedback_images: null,
        added_datetime: '',
        status: '',
        category: '',
        rating_point:'',
        rating_datetime: '',
        reply_message:'',
        reply_datetime: '',
        createdAt: '',
        updatedAt: '',
        addedby: '',
        rating_by:'',
        reply_by: '',
        addedbyName:'',
        ratingbyName: '',
        replybyName: ''
      },
      imageLink:{},
      redirect: false,
      fetchInProgress:false,
      errors: {}
    };
    
  }
  componentDidMount() {
    this.setState({fetchInProgress:true});
    const{ feedback_id } = this.props.location.state;
     Helper.authenticateToken().then(()=>{
      FedbackApi.getFeedbackDetails("/feedback/feedback-details",feedback_id).then((result) => {
            this.setState({data:result.data,imageLink:result.data.images,fetchInProgress:false});
          })
    }) 
  }

  render() {
    
    const { data,imageLink,fetchInProgress } = this.state;
    const items = [];
    if(data.feedback_images!=null){
  let allimages=data.feedback_images.split('|*|');
  for (var i=0;i<allimages.length;i++){
    items.push(<div style={{ background: `url('${imageLink[i]}') no-repeat center`,'backgroundSize': 'cover'  }} key={i}  alt="preview" name={allimages[i]}></div>)
  }
}
else{
  items.push(<div style={{ background: `url('${require("../../../assets/img/noimage.png")}') no-repeat center`,'backgroundSize': 'cover'  }} key={0}  alt="preview"  ></div>)
}
    return (
      <>
        <Header />
        {/* Page content */}
        {fetchInProgress ? <div className="progress2 justify-content-center"> <img src={loder1} alt="" className="pad"/></div> :
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow card-bg-view">
                <CardHeader>
                <div className="row">
                  <div className="col-md-6 col-6 pt-2">
                    Feedback Details
                  </div>
                  <div className="col-md-6 col-6 text-right">
                  <Link to="feedback">
                    <button type="button" className="btn btn-primary md">Back</button>
                    </Link>
                  </div>
                 </div>
                 </CardHeader>
                <Form encType="multipart/form-data">
                  <div className="row">
                    <div className="col-md-6 text-center">
                      <Slider autoplay={3000}>
                        {items}
                      </Slider>
                    </div>
                    
                    <div className="col-md-6 text-md-left pl-md-2">
                      <div className="text-md-left status">
                          <label htmlFor="event_venue"> <i className="fas fa-user"></i> Name : { data.addedbyName}</label>
                      </div>
                      <div className="text-md-left status">
                          <label htmlFor="event_venue"> <i className="fas fa-star"></i> Rating : <Rating
                                  emptySymbol="fa fa-star custom custom-full"
                                  fullSymbol="fa fa-star custom custom-full" stop={data.rating_point} readonly></Rating></label>
                      </div>
                    </div>                
                    </div>
                  
                    <div className="row">
                      <div className="col-md-12 description">
                      <span className="c-date"> { moment(data.added_datetime).format('ll')}</span>
                      <br/>
                          <label htmlFor="description">{data.description}</label>
                      </div>
                    </div>
                </Form>
              </Card>
            </div>
          </Row>
        </Container>
        }
      </>
    );
  }
}

export default FeedbackShow;

import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import Header from "../../../components/Headers/Header";
import UserApi from '../../../API/userapi';
import Helper from '../../../helpers/helper';
import Loader from '../../../components/Spinner/spinner';
import InlineError from '../../../components/messages/InlineError';
import { Redirect } from 'react-router-dom';

class ChangePassword extends React.Component {
    constructor() {
        super();
        this.state = {
            data: {
                password: '',
                confirmpassword: ''
            },
            errors: {},
            redirect: false,
            fetchInProgress: false
        };
        this.changePassword = this.changePassword.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    validate = (data) => {
        const errors = {};
        if (!data.password) errors.password = "New password is required";
        if (!data.confirmpassword) errors.confirmpassword = "Confirm password is required";
        if (data.password !== data.confirmpassword) errors.confirmpassword = "New  & Confirm password didn't matched";
        return errors;
    }
    onChange = e => {
        this.setState({
            data: { ...this.state.data, [e.target.name]: e.target.value }
        })
    }

    changePassword = () => {
        this.setState({ fetchInProgress: true });
        Helper.authenticateToken().then(() => {
            const errors = this.validate(this.state.data);
            this.setState({ errors, fetchInProgress: false });
            if (Object.keys(errors).length === 0) {
                this.setState({ fetchInProgress: true });
                UserApi.changePass('/users/change-password', this.state.data).then((result) => {
                    if (result.data.status === "success") {
                        this.setState({ redirect: true, fetchInProgress: false});
                    } else {
                        this.setState({ errors: result.errors.msg, redirect: false, fetchInProgress: false });
                    }
                })
            }
        })
    }

    render() {
        const { fetchInProgress, errors, redirect } = this.state;
        if (redirect) {
            return <Redirect to={'/admin/index'} />
        }
        return (
            <>
                <Header />
                <Container className="mt--7" fluid>
                    <Row>
                        <div className="col">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-2">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Change Password</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <div className="pl-lg-4">
                                            <Row style={{justifyContent:"center"}}>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-newpassword"
                                                        >
                                                            New Password
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-newpassword"
                                                            placeholder="Enter New Password"
                                                            type="password"
                                                            name="password"
                                                            autoComplete="off"
                                                            onChange={this.onChange}
                                                        />
                                                        {errors.password && <InlineError text={errors.password} />}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row style={{justifyContent:"center"}}>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-confirmpassword"
                                                        >
                                                            Confirm Password
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-confirmpassword"
                                                            placeholder="Enter Confirm Password"
                                                            type="password"
                                                            name="confirmpassword"
                                                            autoComplete="off"
                                                            onChange={this.onChange}
                                                        />
                                                        {errors.confirmpassword && <InlineError text={errors.confirmpassword} />}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />
                                        <div className="pl-lg-4 text-center">
                                            <Button
                                                color="info"
                                                onClick={this.changePassword}
                                                disabled={fetchInProgress}
                                            >
                                                Change Password &nbsp;
                                                {fetchInProgress ? <Loader size="smaller" inProgress={fetchInProgress} /> : ''}
                                             </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }

}
export default ChangePassword;
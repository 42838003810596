import React, { Fragment } from "react";
import '../../../assets/css/custom.css';
// reactstrap components
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,CardHeader
} from "reactstrap";
//import ReactDatetime from "react-datetime";
// core components
import 'react-datepicker/dist/react-datepicker.css';
import loder1 from '../../../assets/loder1.gif';
 import UserCategory from "./UserCategory";
/*import PopularOrganiser from "./PopularOrganiser";
import TopOrganiser from "./TopOrganiser"; */
import ContestHeader2 from "../../../components/Headers/ContestHeader2";
 import DownloadComplaintExcel from "./DownloadComplaintExcel"; 
import { withRouter } from 'react-router';
import GreenPujaApi from '../../../API/greenpujaapi';
import Helper  from '../../../helpers/helper';


class UserwiseCategory extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {
        images:{}
      },
        totalcount: {},
        fetchInProgress:false,
        contest_start:false,
        contest_over:false,
    };
    
  }
  startContest=()=>{
    this.setState({fetchInProgress:true});
    Helper.authenticateToken().then(()=>{
         GreenPujaApi.contestStartComplete('/contest/complete/2',{status:3}).then((result) => {
           this.setState({data:result,fetchInProgress:false,contest_start:true,contest_over:false});           
         })
   }) 
  }
  stopContest=()=>{
    this.setState({fetchInProgress:true});
     Helper.authenticateToken().then(()=>{
         GreenPujaApi.contestStartComplete('/contest/complete/2',{status:2}).then((result) => {
           this.setState({data:result,fetchInProgress:false,contest_over:true,contest_start:false});           
         })
    }) 
  }
 

  
    
    render() {
        const { fetchInProgress } = this.state;
        return (
          <Fragment>
            <ContestHeader2/>
            
           {fetchInProgress ? <div className="progress-dash justify-content-center"> <img src={loder1} alt="" className="pad"/></div> :
            <div> 
         
              <Container className="mt--7" fluid>
                <Row className="pb-2">
                    
                    <Col lg="6" xl="6" >
                        <DownloadComplaintExcel/>  
                    </Col>
                    <Col lg="3" xl="2" >
                        <button type="button" className="btn btn-success md" onClick={this.startContest}>Start Contest</button>
                    </Col>
                    <Col lg="3" xl="2" >
                        <button type="button" className="btn btn-danger md" onClick={this.stopContest}>Stop Contest</button>
                    </Col>
                    <Col lg="2" xl="2" className="text-right">
                     <button type="button" className="btn btn-primary md" onClick={ () => this.props.history.go(-1)}>Back</button>
                  </Col>
                </Row>
             
                <Row className="pt-2">
                  <Col lg="6" xl="12">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardHeader style={{backgroundColor: "#ff935c",color:"white"}}>Kali puja/ Diwali contest</CardHeader>
                      <CardBody className="leaderboard-body">
                         <UserCategory/>  
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                
              </Container>
            </div>
            
            
           }
         </Fragment>
        );
      }
    
  
}
export default withRouter(UserwiseCategory);

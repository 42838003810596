import qs from 'querystring';
import axios from "axios";
import {BASE_URL} from '../config/config';

const getFeedbackList = (url, paramData) => {
    let parameter="?per_page="+paramData.per_page+"&pageno="+paramData.pageno;
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url +parameter , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
           // params: qs.stringify(paramData)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });
    });
}
 
const replyAndRating = (url, actionData) => {
    let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
    return new Promise((resolve, reject) => {
        axios.patch(BASE_URL + url, qs.stringify(actionData), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            }})
            .then((response) => { resolve(response) })
            .catch((error) => {
                reject(error);
            }); 
        }) 
}
const getFeedbackDetails = (url, feedback_id) => {
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url+"/"+feedback_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
            //params: qs.stringify(newsdetails)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });

    });
}

export default { getFeedbackList,replyAndRating,getFeedbackDetails };
import React, { Component } from "react";
import Helper from "../../../helpers/helper";
import Header from "../../../components/Headers/Header";
import { Container, Row, Badge } from "reactstrap";
import {
  ButtonGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import VolunteerApi from "../../../API/volunteerapi";
import loder1 from "../../../assets/loder1.gif";

class VolunteerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_no: 1,
      per_page: 12,
      activePage: 1,
      totalDataCount: 0,
      data: [],
      dropdownOpen: -1,
      fetchInProgress: false,
      status: 3,
      errorMsg: "",
    };
    this.toggle = this.toggle.bind(this);
    this.loadVolunteerList = this.loadVolunteerList.bind(this);
  }

  loadVolunteerList() {
    const paramData = {
      page_no: this.state.page_no,
      per_page: this.state.per_page,
      status: this.state.status,
    };
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      VolunteerApi.getVolunteerList(
        "/volunteer/get_all_volunteership_requests",
        paramData
      ).then((result) => {
        if (result.status === "success") {
          let totalItem = result.total_data;
          this.setState({ totalDataCount: totalItem });
          this.setState({ data: result.data, fetchInProgress: false });
          console.log(this.state.data);
        } else {
          this.setState({
            errorMsg: "Error! Please try after some time.",
            fetchInProgress: false,
          });
        }
      });
    });
  }

  getStatusColorAndText = (status) => {
    console.log("reaching here");
    switch (status) {
      case 0:
        return { color: "#ffd600", text: "Requested", background: 'linear-gradient(45deg, rgb(128 106 0), rgb(255 233 124))', bgcolor: 'rgb(255 233 124)' };
        // return { color: "info", text: "Requested", background: 'linear-gradient(45deg, #A4C0CC, #C3F2FB)' };
      case 1:
        return { color: "success", text: "Approved", background: 'linear-gradient(45deg, #47B276, #C9F3E1)' };
      case 2:
        return { color: "warning", text: "Rejected", background: 'linear-gradient(45deg, #fb6340, #fee4de)' };
      default:
        return { color: "light", text: "Unknown", background: 'linear-gradient(45deg, #888888, #111111)' };
    }
  };

  componentDidMount() {
    this.loadVolunteerList();
  }

  toggle = (index) => {
      const prev = this.state.dropdownOpen;
    this.setState({ dropdownOpen: index===prev? -1: index });
    console.log(index, this.state.dropdownOpen)
  };
  render() {
    return (
      <>
        <Header />
        {this.state.fetchInProgress ? (
          <div className="progress2 justify-content-center">
            <img src={loder1} alt="" className="pad" />
          </div>
        ) : (
          <Container className="mt--7" fluid>
            <Row style={{flexWrap: "wrap", flexDirection: "row", justifyContent: "space-evenly", alignItems: 'stretch',}}>
              {this.state.data !== 0 && this.state.data.length > 0
                ? this.state.data.map((item, index) => {
                    const VolunteerStatus = this.getStatusColorAndText(
                      item.status
                    );
                    return (
                      <div
                        className="col-xl-3 col-lg-4 col-md-12 col-sm-6"
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "stretch",
                          justifyContent: "stretch",
                          maxWidth:400,
                          minWidth: 300,
                          marginTop: 20,
                        }}
                      >
                        <div className="backgroundBorder p-1" style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "stretch",
                          justifyContent: "center",
                          flexGrow: 1,
                          borderRadius: 10,
                          backgroundImage: VolunteerStatus.background
                        }}>
                          <div
                            className="card testimonial-card"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flexGrow: 1,
                              justifyContent: "center",
                              alignItems: "space-around",
                            }}
                          >
                            {/* <div className="card-up indigo lighten-1"></div> */}
                            <div
                              className="avatar mx-auto white"
                              style={{ marginTop: "10px" }}
                            >
                              <img
                                src={
                                  item.hasOwnProperty("User.profile_image") &&
                                  !!item["User.profile_image"]
                                    ? item["User.profile_image"]
                                    : require("../../../assets/img/theme/dummy256x256.png")
                                }
                                className="rounded-circle"
                                alt="user avatar"
                                style={{ height: "100%" }}
                              />
                              {/* <img
                        src={require("../../../assets/img/theme/dummy256x256.png")}
                        className="rounded-circle"
                        alt="user avatar"
                        style={{ height: "100%" }}
                      /> */}
                            </div>
                            <div className="card-body">
                              <h5 className="text-center">
                                {/* <Badge color="success">{(item.is_admin === 0 && item.user_type === 1) ? 'Volunteer' : (item.is_admin === 0 && item.user_type === 2) ? 'Regs. User' : (item.is_admin === 1 && item.user_type === 2) ? 'BOC User' : 'Admin User'} </Badge> &nbsp;&nbsp;
                          <Badge color={item.is_active === 1 ? 'info' : 'danger'}>
                              {item.is_active === 0 ? 'Inactive User' : item.is_active === 1 ? 'Active User' : item.is_active === 2 ? 'Deleted' : 'Banned'}
                            </Badge> */}
                                <Badge color="info">Volunteer</Badge>{" "}
                                &nbsp;&nbsp;
                                {/* <Badge color="success">Approved</Badge> &nbsp;&nbsp; */}
                                <Badge color={VolunteerStatus.color} style={{backgroundColor: !!VolunteerStatus.bgcolor&&VolunteerStatus.bgcolor}}>
                                  {VolunteerStatus.text}
                                </Badge>
                              </h5>
                              <hr />
                              <h4
                                className="card-title"
                                style={{ marginBottom: "0.25rem" }}
                              >
                                Name - <b>{item["User.name"]}</b>
                              </h4>
                              <small>
                                Addess - <b>{item["User.addess"]}</b>
                              </small>
                              <hr />

                              <small>
                                Email ID - <b>{item["User.email"]}</b>
                                <br />
                                Mobile Number - <b>{item["User.mobile_no"]}</b>
                                {/* {((item.email).length > 25) ? (((item.email).substring(0,25-3)) + '...') : item.email} */}
                                {/* {item.email !== null && item.email !== '' ? ((item.email).length > 28) ? (((item.email).substring(0, 28 - 3)) + '...') : item.email : 'Not Provided'}</b> */}
                                {/* <br />
                            Mobile No. - <b>{item.mobile_no !== null && item.mobile_no !== '' ? item.mobile_no : 'Not Provided'}</b> */}
                              </small>
                              <hr />
                              <small>
                                Trees Adopted: <b>{item.trees_adopted}</b>
                              </small>
                            </div>
                            <div
                              className="card-footer"
                              style={{ textAlign: "right" }}
                            >
                              <ButtonGroup>
                                <Link to={{ pathname: 'volunteer-details', state: { userid: item.id, vol_id: item.user_id } }} className="btn btn-primary">Open Profile</Link>
                                {/* <ButtonDropdown
                                  isOpen={this.state.dropdownOpen === index}
                                  toggle={() => this.toggle(index)}
                                >
                                  <DropdownToggle caret></DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      header
                                      disabled
                                      className="text-primary"
                                    >
                                      Take Action
                                    </DropdownItem>
                                    <DropdownItem>Assign Trees</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                      Delete Volunteership
                                    </DropdownItem>
                                    <DropdownItem>Ban User</DropdownItem>
                                  </DropdownMenu>
                                </ButtonDropdown> */}
                              </ButtonGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </Row>
          </Container>
        )}
      </>
    );
  }
}

export default VolunteerDashboard;

import querystring from 'querystring';
import {BASE_URL} from './config/config';

export function PostData(type, userData) {
    let url = '/users/auth/adm/';
    return new Promise((resolve, reject) => {
        fetch(BASE_URL + url + type, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Basic cGFyaWJlc2hWMTpwYXJpYmVzaFYxLTEyM1NlY3JldA',
                'Access-Control-Allow-Origin': '*'
            },
            body: querystring.stringify(userData)
        })
            .then((response) =>{resolve(response.json())})
            .catch((error) => {reject(error)});
    });
}
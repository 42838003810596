import React from "react";
import { Link } from "react-router-dom";
import "../../../assets/css/custom.css";

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const TreeList = ({ data, page_no }) => {
  // const assignColor = (status) => {
  //   switch (parseInt(status)) {
  //     case 0:
  //       return {
  //         backgroundColor: "#004F93",
  //         background:
  //           "linear-gradient(135deg, rgba(0, 79, 147,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)",
  //         boxShadow: "box-shadow: 3px 3px 5px 0px rgba(0, 79, 147,0.6)",
  //       };
  //     case 1:
  //       return {
  //         backgroundColor: "#11740F",
  //         background:
  //           "linear-gradient(135deg, rgba(17, 116, 15,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)",
  //         boxShadow: "box-shadow:3px 3px 5px 0px rgba(17, 116, 15,0.6)",
  //       };
  //     case 2:
  //       return {
  //         backgroundColor: "#FF0000",
  //         background:
  //           "linear-gradient(135deg, rgba(255,0,0,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)",
  //         boxShadow: "box-shadow: 3px 3px 5px 0px rgba(255,0,0,0.6)",
  //       };
  //     default:
  //       return {
  //         backgroundColor: "#FFffff",
  //         background:
  //           "linear-gradient(135deg, rgba(34,139,34,0.5578606442577031) 0%, rgba(255,255,255,100) 100%)",
  //         boxShadow: "box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.6)",
  //       };
  //   }
  // };
  const getStatusColorAndText = (status) => {
    console.log("reaching here");
    switch (status) {
      case 0:
        return {
          color: "#ffd600",
          text: "Requested",
          background:
            "linear-gradient(45deg, rgb(128 106 0), rgb(255 233 124))",
          bgcolor: "rgb(255 233 124)",
        };
      // return { color: "info", text: "Requested", background: 'linear-gradient(45deg, #A4C0CC, #C3F2FB)' };
      case 1:
        return {
          color: "success",
          text: "Approved",
          background: "linear-gradient(45deg, #47B276, #C9F3E1)",
        };
      case 2:
        return {
          color: "warning",
          text: "Rejected",
          background: "linear-gradient(45deg, #fb6340, #fee4de)",
        };
      default:
        return {
          color: "light",
          text: "Unknown",
          background: "linear-gradient(45deg, #666666, #cccccc)",
        };
    }
  };

  return (
    <>
      {data !== 0
        ? data.map((item, index) => {
            const TreeStatus = getStatusColorAndText(item.status);
            return (
              <div
                className="col-xl-3 col-lg-4 col-md-12 col-sm-6 mt-2 mb-2"
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "stretch",
                  justifyContent: "stretch",
                  maxWidth: 400,
                  minWidth: 300,
                }}
              >
                <div
                  className="backgroundBorder p-1"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "stretch",
                    justifyContent: "center",
                    flexGrow: 1,
                    borderRadius: 10,
                    backgroundImage: TreeStatus.background,
                  }}
                >
                  <div
                    className="card testimonial-card p-2"
                    style={{
                      display: "flex",
                              flexDirection: "column",
                              flexGrow: 1,
                              justifyContent: "center",
                              alignItems: "space-around",
                    }}
                  >
                    <div className="card-up indigo lighten-1"></div>

                    <div className="col-12 mb-0">
                      <div className="row">
                        <h1 className="card-title" style={{ color: TreeStatus.color, fontSize: 20 }}>
                          {item.name}
                        </h1>
                      </div>
                    </div>
                    <div className="card-body p-1">
                      <div className="col-12 p-0">
                        <img
                          src={
                            item.hasOwnProperty("admin_uploaded_images") &&
                            typeof item.admin_uploaded_images !== "undefined" &&
                            item.admin_uploaded_images.length > 0
                              ? item.admin_uploaded_images[0]
                              : require("../../../assets/img/noimage.png")
                          }
                          className="img-fluid cat-img"
                          alt="user avatar"
                          style={{ height: "100%", minWidth: 190 }}
                        />
                      </div>
                      <div className="pl-0 pr-0 pb-2">
                        <div className="col-12 pb-2 pl-0 pr-0">
                          <div className="small">
                            Variety: <b>{capitalize(item.variety)}</b>
                          </div>
                        </div>

                        <div className="small">
                          Location: <b>{String(item.location).length > 40
                            ? String(item.location).substring(0, 37) + "..."
                            : String(item.location)}</b>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer" style={{ textAlign: "right" }}>
                      <Link
                        to={{
                          pathname: "tree-show",
                          state: { tree: item, page_no: page_no },
                        }}
                      >
                        <button type="button" className="btn btn-info btn-sm">
                          Show
                        </button>
                      </Link>{" "}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </>
  );
};

export default TreeList;

import React from "react";
import '../../../assets/css/custom.css';
import {
  Form,
  Row,
  Card,
  Container,
  CardHeader
} from "reactstrap";
import { Redirect } from 'react-router-dom';
import ContestHeader from "../../../components/Headers/ContestHeader";
import GreenPujaApi from '../../../API/greenpujaapi';
import Helper  from '../../../helpers/helper';
import loder1 from '../../../assets/loder1.gif';
import Slider from 'react-animated-slider';
import { Link } from 'react-router-dom';
import  Rating from  'react-rating';
import { withRouter } from 'react-router';
import 'react-animated-slider/build/horizontal.css';
class OrganizerShow extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {
        images:{}
      },
      imageLink:{},
      redirect: false,
      fetchInProgress:false,
      errors: {}
    };
    
  }
  componentDidMount() { 
    const { organiser_id } = this.props.location.state;
    let  url='';
    this.setState({fetchInProgress:true});
     Helper.authenticateToken().then(()=>{
          url="/organiser/"+organiser_id;
          GreenPujaApi.getOrganizerDetails(url).then((result) => {
            this.setState({data:result.data,fetchInProgress:false,imageLink:result.data.images});           
          })
    }) 
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/admin/organizer'} />
    }
    const { data,imageLink,fetchInProgress } = this.state;
    const items = [];
    if(data.puja_banner!=null){
  let allimages=data.puja_banner.split('|*|');
  for (var i=0;i<allimages.length;i++){
    items.push(<div style={{ background: `url('${imageLink[i]}') no-repeat center`,'backgroundSize': 'cover'  }} key={i}  alt="preview" name={allimages[i]}></div>)
  }
}
else{
  items.push(<div style={{ background: `url('${require("../../../assets/img/noimage.png")}') no-repeat center`,'backgroundSize': 'cover'  }} key={0}  alt="preview"  ></div>)
}
    return (
      <>
        <ContestHeader />
        {/* Page content */}
        { fetchInProgress ?<div className="progress2 justify-content-center"> <img src={loder1} alt=""/></div> :
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow card-bg-view">
                <CardHeader>
                <div className="row">
                  <div className="col-md-6 col-6 pt-2">
                    Organizer Details
                  </div>
                  <div className="col-md-6 col-6 text-right">
                  <Link to="organizer">
                     <button type="button" className="btn btn-primary md" onClick={ () => this.props.history.go(-1)}>Back</button>
                  </Link>
                  </div>
                 </div>
                 </CardHeader>
                <Form encType="multipart/form-data">
                  <div className="row">
                    <div className="col-md-6 text-center">
                      <Slider autoplay={3000}>
                        {items}
                      </Slider>
                    </div>
                    
                    <div className="col-md-6 text-md-left pl-md-2">
                        <div className="text-md-left t-no">
                        <label htmlFor="event_venue"> {data.puja_committee_name}</label>
                        </div>
                        <div className="text-md-left date">
                            <label htmlFor="event_venue"><i className="ni ni-pin-3"></i> {data.location}</label>
                        </div>
                        <div className="text-md-left">
                          <label className="date"><i className="ni ni-circle-08"></i> President Name:</label> <label>{data.president_name}</label>
                        </div>
                        <div className="text-md-left ">
                          <label className="date"><i className="fa fa-phone" aria-hidden="true"></i> President Contact: </label><label>{data.president_contact}</label>
                        </div>
                        <div className="text-md-left">
                            <label className="date"><i className="ni ni-single-02"></i> Secretary Name:</label> <label >{data.secretary_name}</label>
                        </div>
                        <div className="text-md-left ">
                            <label className="date"><i className="fa fa-phone" aria-hidden="true"></i> Secretary Contact:</label> <label> {data.secretary_contact}</label>
                        </div>
                        <div className="text-md-left">
                            <label className="date"><i className="ni ni-single-02"></i> Idol Maker Name: </label><label>{data.idol_maker_name}</label>
                        </div>
                        <div className="text-md-left ">
                            <label className="date"><i className="fa fa-phone" aria-hidden="true"></i> Idol Maker Contact: </label><label>{data.idol_maker_contact}</label>
                        </div>
                        <div className="text-md-left ">
                            <label >
                              {data.rating!==0?
                                 <p>{data.avg_rating/10}{" "}<Rating emptySymbol="fa fa-star custom custom-full"
                                        fullSymbol="fa fa-star custom custom-full" stop={Math.round(data.avg_rating/10)} readonly>
                                        </Rating>{" "}({data.rating_count})</p>:""
                              }
                            </label>
                        </div>  
                    </div>                
                    </div>
                </Form>
              </Card>
            </div>
          </Row>
        </Container>
        }
      </>
    );
  }
}

export default withRouter(OrganizerShow);

import axios from "axios";
import { BASE_URL, plog } from '../config/config';

export const postTwitter = async ({ url, values, numberOfImage }) => {
    let data = new FormData();
    data.append('description', values?.description);
    data.append('publish_datetime', values?.publish_datetime);


    if (numberOfImage > 0) {

        for (let i = 0; i < numberOfImage; i++) {
            data.append(`images`, values?.images[i]);

        }
    }

    let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
    return new Promise((resolve, reject) => {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BASE_URL}/tweet/create`,
            headers: {
                'authorization': 'Bearer ' + accesstoken,

            },
            data: data
        };

        axios.request(config)
            .then((response) => resolve(response))
            .catch((error) => {
                reject(error);
            });


    })


}

export const postUpdateTwitter = async ({ url, values, numberOfImage }) => {
    let data = new FormData();
    data.append('description', values?.description);
    data.append('publish_datetime', values?.publish_datetime);


    if (numberOfImage > 0) {

        for (let i = 0; i < numberOfImage; i++) {
            data.append(`images`, values?.images[i]);

        }
    }

    let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
    return new Promise((resolve, reject) => {
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${BASE_URL}${url}`,
            headers: {
                'authorization': 'Bearer ' + accesstoken,

            },
            data: data
        };

        axios.request(config)
            .then((response) => resolve(response))
            .catch((error) => {
                reject(error);
            });


    })


}
export const readTwitter = (url) => {

    console.log(url)
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(
            localStorage.getItem("userData")).access_token;
        fetch(BASE_URL + url, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                authorization: "Bearer " + accesstoken,
            },
        })
            .then((response) => {
                resolve(response.json());
            })
            .catch((error) => {
                reject(error);
            });
    });
};


export const deteleTwitter = (url) => {
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(
            localStorage.getItem("userData")).access_token;
        fetch(BASE_URL + url, {
            method: "delete",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                authorization: "Bearer " + accesstoken,
            },
        })
            .then((response) => {
                resolve(response.json());
            })
            .catch((error) => {
                reject(error);
            });
    });

}
import React from "react";
import {
  Container,
  Row,
  Alert,
  Col,
  Button,
  Modal,
  FormGroup,
  Form, 
  Input
} from 'reactstrap';
//import { MDBBtn } from 'mdbreact';
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import '../../../assets/css/custom.css';
import Header from '../../../components/Headers/Header';
import FeedbackApi from '../../../API/feedbackapi';
import Helper from '../../../helpers/helper';
import moment from 'moment';
import  Rating from  'react-rating';
import loder1 from '../../../assets/loder1.gif';
// import { Link } from 'react-router-dom';

class Feedbacklist extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      paramData: {
        per_page: 8,
        pageno: 1
      },
      errorMsg: '',
      actionData:{
        feedback_id:'',
        rating_point:0,
        reply_message:'',
      },
      isModalOpen: false,
      fetchInProgress: false,
      visibleSuccess: false,
      visibleError: false,
      successmsg: {},
      activePage: 1,
      errors: {},
      totalDataCount: 1 
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  handleAdd= (feedbackid) =>{
    this.setState(prevState => ({
      actionData: {...prevState.actionData,feedback_id:feedbackid},
      isModalOpen: true 
    })) 
  }
  replyAndRating=()=>{
    Helper.authenticateToken().then(()=>{
  const newActionData=this.state.actionData;
  if(this.state.actionData.rating_point===0)
      delete newActionData.rating_point;
  if(this.state.actionData.reply_message==='')
      delete newActionData.reply_message;
  
      FeedbackApi.replyAndRating('/feedback/feedback-action', newActionData).then((result) => {
      if (result.data.status === "success") {
        this.setState({ visibleSuccess: true, fetchInProgress: false, successmsg: result.data.error_description });
        setTimeout(function () {
          this.setState({isModalOpen:false});
          window.location.reload();
        }.bind(this), 3000)
        
      }  else {
        this.setState({ errors:result.data.error_description, fetchInProgress: false, visibleError: true });
        setTimeout(function () {
          this.setState({isModalOpen:false});
          window.location.reload();
        }.bind(this), 3000)
      }
    })

  
  
    })

  }
  onChange = e =>{
    this.setState({
      actionData: { ...this.state.actionData, [e.target.name]: e.target.value }
    })}
   addRating=(rate)=>{
    this.setState(prevState => ({
      actionData: {...prevState.actionData,rating_point:rate},
    }))
  } 
  
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    this.setState(prevState => ({
      paramData: { ...prevState.paramData, pageno: pageNumber }
    }))
    this.loadData();
  }
  toggleModal = state => {
    this.setState({isModalOpen:false});
  };
  loadData = () => {
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      FeedbackApi.getFeedbackList('/feedback/all', this.state.paramData).then((result) => {
        if(result.status === 'success'){
          let totalItem = result.total_data;
          this.setState({ totalDataCount: totalItem });
          this.setState({ data: result.data, fetchInProgress: false });
        }else{
          this.setState({ errorMsg: 'Error! Please try after some time.', fetchInProgress: false });
        }
      });
    })
  }
  componentWillMount() {
    this.loadData();
  }

  render() {
    const { fetchInProgress,errors, data,visibleSuccess,visibleError,successmsg,paramData } = this.state;

    return (
      <>
        <Header />
        {fetchInProgress ? <div className="progress2 justify-content-center"> <img src={loder1} alt="" className="pad"/></div> :
          <Container className="mt--7" fluid>
            <Row>

              {(data !== 0) ?
                data.map((item, index) => {
                  return (
                    <Col lg="3" className="mb-2" key={index}>
                      <div className="card testimonial-card">
                        <div className="card-up indigo lighten-1"></div>
                          
                          <div className="col-12 mb-0">
                          <div className="row">
                            <div className="col-2 ml-0">
                              <div className="avatar2 white" style={{ marginTop: "10px" }}>
                                <img src={(item.profileImages.length >= 1) ? item.profileImages : require("../../../assets/img/theme/dummy256x256.png")} className="rounded-circle" alt="user avatar" style={{ height: "100%" }} />
                                </div>
                            </div>
                            <div className="col-10 pt-3">
                              <h4 className="card-title" >{((item['AddedBy.name'] !== null && item['AddedBy.name'] !== '') ? item['AddedBy.name'] : '')} </h4>
                            </div>
                          </div>
                        </div>
                        <div className="card-body p-0">
                          <div className="col-12 p-0">
                            <img src={(item.images.length >= 1) ? item.images[0] : require("../../../assets/img/noimage.png")} className="img-fluid cat-img" alt="user avatar" style={{ height: "100%" }} />
                         </div>
                         <div className="pl-3 pr-3">
                          <div className="small"> {moment(item.added_datetime).format('ll')}</div>
                          <div className="des">
                              {item.description !== null && item.description !== '' ? ((item.description).length > 104) ? (((item.description).substring(0, 104 - 3)) + '...') : item.description : 'Not Provided'}
                            </div>
                            <div className="row pb-2">
                              <div className="col-md-6">
                              <Rating
                                  emptySymbol="fa fa-star custom custom-full-l"
                                  fullSymbol="fa fa-star custom custom-full-l" stop={item.rating_point} readonly></Rating>
                              </div>
                              {item.rating_datetime!=null?
                              <div className="col-md-6 text-right" >
                                <div className="small-date"> {moment(item.rating_datetime).format('ll')}</div>
                              </div> :""
                              } 
                            </div>
                          </div>
                          
                        </div>
                        <div className="card-footer" style={{ textAlign: "right" }}> 
                        <Link to={{pathname: 'feedback-show', state: {feedback_id: item.feedback_id}}}>
                          <button type="button" className="btn btn-info btn-sm">Show</button>
                        </Link>{" "}
                          <Button className="is-active" color="success" size="sm" name={item.feedback_id} onClick={(e) => this.handleAdd(item.feedback_id)} disabled={(item.rating_by!==null&&item.reply_by!==null)?true:false} >
                              <span >Reply</span>
                            </Button>
                         
                        </div>
                      </div>
                    </Col>
                  )
                }) : <Alert color="warning" style={{textAlign: "center"}}>
                  <strong>Sorry!</strong>  No Data Found!
            </Alert>
              }
            </Row>
            <Row style={{justifyContent: "flex-end"}}>
            {data !== 0 ?
                <div className="pag">
                  <Pagination className="pagination pagination-lg"
                    listClassName="pagination-lg"
                    activePage={this.state.activePage}
                    itemsCountPerPage={paramData.per_page}
                    totalItemsCount={this.state.totalDataCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div> : ''
              }
            </Row>

             <Modal className="modal-dialog-centered" 
            isOpen={this.state.isModalOpen}
            toggle={() => this.toggleModal("isModalOpen")}
            >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button aria-label="Close" className="close" data-dismiss="modal" type="button"
                onClick={() => this.toggleModal("isModalOpen")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <Form >
            <div className="modal-body">
                {visibleSuccess ? <Alert color="success" isOpen={visibleSuccess} toggle={this.onDismiss}>
                          {successmsg}
                      </Alert> : visibleError ? <Alert color="warning"  isOpen={visibleError} toggle={this.onDismiss}>
                          {
                            <span  >{errors} </span>
                          }
                      </Alert>:''}
              
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label htmlFor="exampleEmail">Action Message</label>
                          <Input className="form-control-alternative" placeholder="Write a large text here ..." rows="3" type="textarea"
                            name="reply_message"
                            onChange={this.onChange} />
                        </FormGroup>
                      </Col>
                      <Col md="12" className="mt-3">
                      <Rating 
                                    style={{fontSize:"28px !important"}}
  emptySymbol="fa fa-star-o custom custom-empty"
  fullSymbol="fa fa-star custom custom-full"
  name="rating_point"
  onChange={(e) => this.addRating(e)}
  renderStarIcon={10}
/>
                      </Col>
                    </Row>
              
  
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
               onClick={() => this.toggleModal("exampleModal")}
              >
                Close
              </Button>
              <Button color="primary" type="button" onClick={this.replyAndRating}>
                Save changes
              </Button>
            </div>
            </Form>
          </Modal>
          
          </Container>
        }
      </>
    );
  }
}
export default Feedbacklist;

//import qs from 'querystring';
//import axios from "axios"
import {BASE_URL} from '../config/config';
const getTotalCount = (url) => {
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL+url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            }
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });

    });
}

export default { getTotalCount };
import React, { Component } from "react";
import {
  Container,
  Button,
  Card,
  Col,
  Form,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import InlineError from "../../../components/messages/InlineError";
import Loader from "../../../components/Spinner/spinner";
import Header from "../../../components/Headers/Header";
import { Redirect, Link } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import Helper from "../../../helpers/helper";
import BouquetApi from "../../../API/bouquetapi";

export default class AddTree extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      variety: "",
      location: "",
      price: "",
      geotag: { lat: 0, long: 0 },
      errors: {},
      mapPosition: {
        lat: 22.81,
        lng: 50.22,
      },
      markerPosition: {
        lat: 0,
        lng: 0,
        setOnce: false,
      },
      images: {},
      fetchInProgress: false,
      redirect: false,
    };
    this.changeMarkerPosition = this.changeMarkerPosition.bind(this);
    this.addNewTree = this.addNewTree.bind(this);
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
  }

  componentDidMount() {}

  addNewTree() {
    this.setState({ fetchInProgress: true });
    const paramData = {
      name: this.state.name,
      variety: this.state.variety,
      location: this.state.location,
      price: this.state.price,
      lat: String(this.state.markerPosition.lat.toFixed(8)),
      long: String(this.state.markerPosition.lng.toFixed(8)),
      images: this.state.images,
      status: 0,
    };
    console.log(paramData);
    Helper.authenticateToken(this.state).then(() => {
      const errors = this.validate(this.state);
      this.setState({ errors });
      if (Object.keys(errors).length === 0) {
        BouquetApi.addNewTree("/bouquet/add_new_tree", paramData)
          .then((result) => {
            if (result.data.status === "success") {
              this.setState({ redirect: true, fetchInProgress: false });
            } else {
              this.setState({ fetchInProgress: false });
            }
          })
          .catch((err) => {
            this.setState({ fetchInProgress: false });
          });
      } else this.setState({ fetchInProgress: false });
    });
  }
  validate = (state) => {
    const errors = {};
    if (!state.name) errors.name = "Name is Required";
    if (!state.price) errors.price = "Price is Required";
    if (!state.variety) errors.variety = "Variety is Required";
    if (!state.location) errors.location = "Enter correct location";
    if (!state.markerPosition.setOnce)
      errors.marker = "Please select a marker position";
      if(state.images.length > 4) errors.images = "Maximum 4 images are allowed";
    return errors;
  };

  onChange = (e) => {
    this.setState({
      ...this.state.data,
      [e.target.name]: e.target.value,
    });
  };
  fileChangedHandler(event) {
    let img = event.target.files;
    this.setState(prev => ({images:img}))
  }

  changeMarkerPosition(event) {
    this.setState({
      markerPosition: {
        lat: event.lat,
        lng: event.lng,
        setOnce: true,
      },
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/bouquet"} />;
    }
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-2">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Add New Tree</h3>
                    </Col>
                    <Col xs="4" className="text-right">
                      <Link to="bouquet">
                        <Button
                          className="btn btn-sm"
                          color="primary"
                          type="button"
                        >
                          <span>Back</span>
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                <Form encType="multipart/form-data">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-name"
                        >
                          Name <span className="error-message">*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-name"
                          placeholder="Enter tree name"
                          type="text"
                          name="name"
                          value={this.state.name}
                          autoComplete="off"
                          onChange={this.onChange}
                        />
                        {this.state.errors.name && (
                          <InlineError text={this.state.errors.name} />
                        )}
                      </FormGroup>
                      <FormGroup className="mt-3">
                        <label
                          className="form-control-label"
                          htmlFor="input-variety"
                        >
                          Variety <span className="error-message">*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-variety"
                          placeholder="Enter tree variety"
                          type="text"
                          name="variety"
                          value={this.state.variety}
                          autoComplete="off"
                          onChange={this.onChange}
                        />
                        {this.state.errors.variety && (
                          <InlineError text={this.state.errors.variety} />
                        )}
                      </FormGroup>
                      <FormGroup className="mt-3">
                        <label
                          className="form-control-label"
                          htmlFor="input-price"
                        >
                          Price <span className="error-message">*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-price"
                          placeholder="Enter tree price"
                          type="text"
                          name="price"
                          value={this.state.price}
                          autoComplete="off"
                          onChange={this.onChange}
                        />
                        {this.state.errors.price && (
                          <InlineError text={this.state.errors.price} />
                        )}
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-image"
                        >
                          Tree Images
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="images"
                          placeholder="images"
                          type="file"
                          name="images"
                          accept="image/*"
                          multiple={true}
                          onChange={this.fileChangedHandler}
                        />
                        {this.state.errors.images && (
                          <InlineError text={this.state.errors.images} />)}
                      </FormGroup>
                      <FormGroup className="mt-3">
                        <label
                          className="form-control-label"
                          htmlFor="input-location"
                        >
                          Location <span className="error-message">*</span>
                        </label>
                        <Input
                          type="textarea"
                          className="form-control-alternative"
                          id="input-location"
                          placeholder="Enter tree location"
                          name="location"
                          value={this.state.location}
                          autoComplete="off"
                          onChange={this.onChange}
                          multiline={true}
                          rows={4}
                        />
                        {this.state.errors.location && (
                          <InlineError text={this.state.errors.location} />
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      {/* <AsyncMap google="AIzaSyBJ-RKwTIsTWX_4eKsBN0bBUBBx8dP1-Ls" zoom='12' mapPosition={this.state.mapPosition}></AsyncMap> */}
                      <div
                        style={{
                          position: "absolute",
                          zIndex: 5,
                          backgroundImage:
                            "linear-gradient(45deg, white, transparent)",
                          marginLeft: 0,
                          paddingLeft: 5,
                        }}
                      >
                        {this.state.markerPosition.setOnce ? (
                          <>
                            <h4 className="ml-0 pl-1 mt-0 mb-0 pt-1">
                              Latitude: {this.state.markerPosition.lat}
                            </h4>
                            <h4 className="ml-0 pl-1 mt-0 mb-0 pt-1">
                              Longitude: {this.state.markerPosition.lng}
                            </h4>
                          </>
                        ) : (
                          <h4 className="ml-0 pl-1 mt-0 mb-0 pt-1">
                            Click on map to select a marker
                            <span className="error-message">*</span>
                          </h4>
                        )}
                      </div>
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          // key: "AIzaSyBJ-RKwTIsTWX_4eKsBN0bBUBBx8dP1-Ls",
                          key: "AIzaSyBJ-RKwTIsTWX_4eKsBN0bBUBBx8dP1-Ls",
                        }}
                        defaultCenter={{
                          lat: parseFloat(22.5726),
                          lng: parseFloat(88.3639),
                        }}
                        defaultZoom={12}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onClick={this.changeMarkerPosition}
                      >
                        <i
                          className="fas fa-tree"
                          style={{ fontSize: 20, color: "green" }}
                          lat={this.state.markerPosition.lat}
                          lng={this.state.markerPosition.lng}
                        ></i>
                      </GoogleMapReact>
                      {this.state.errors.marker && (
                        <InlineError text={this.state.errors.marker} />
                      )}
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <Button
                        className="btn btn-success"
                        disabled={this.state.fetchInProgress}
                        onClick={this.addNewTree}
                      >
                        Add{" "}
                        {this.state.fetchInProgress ? (
                          <Loader
                            size="smaller"
                            inProgress={this.state.fetchInProgress}
                          />
                        ) : (
                          ""
                        )}
                      </Button>
                    </Col>
                  </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

import React from "react";
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

class Loader extends React.Component {
    render() {
        const { inProgress, size } = this.props;
        if (inProgress) {
            return (
                <>
                    <Spinner
                        animating={true}
                        style={{ flex: 1, alignItems: "center", justifyContent: "center", height: 80,marginLeft: "525px"}}
                        size={size}
                    />
                </>
            );
        }
    }
}

export default Loader;
import React, { Component } from "react";
import Helper from "../../../helpers/helper";
import Header from "../../../components/Headers/Header";
import {
  Col,
  Container,
  Row,
} from "reactstrap";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  ButtonGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Input
} from "reactstrap";
import { Link } from "react-router-dom";
import "../../../assets/css/custom.css";
import loder1 from "../../../assets/loder1.gif";
import BouquetApi from "../../../API/bouquetapi";
import Pagination from "react-js-pagination";
import TreeList from "./TreeList";
import DashboardApi from "../../../API/dashboardapi";
import InlineError from "../../../components/messages/InlineError";

export default class BouquetDashboard extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      activeType: 1,
      activePage: props.location.state&&props.location.state.page_no?props.location.state.page_no:1,
      fetchInProgress: false,
      paramData: {
        per_page: 12,
        page_no: props.location.state&&props.location.state.page_no?props.location.state.page_no:1,
      },
      totalDataCount: 0,
      data: [],
      stats: {},
      ButtonOptionsDropdown: false,
      openedModal: -1,
      errors: []
    };
    this.loadStatistics = this.loadStatistics.bind(this);
    this.toggleButtonOptionsDropdown = this.toggleButtonOptionsDropdown.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    //if(this.props.location.state.page_no) this.setState({activePage: this.props.location.state.page_no, paramData: {per_page: 12, page_no:this.props.location.state.page_no } })
  }

  toggleModal = (index) => {
    const prev = this.state.openedModal;
  this.setState({ openedModal: index===prev? -1: index });
};

  toggleButtonOptionsDropdown() {    
    const currentButtonOptionsDropdown = this.state.ButtonOptionsDropdown;
    this.setState({ButtonOptionsDropdown: !currentButtonOptionsDropdown});
  }

  loadTreesData = () => {
    // if(typeof this.props.location?.state !== "undefined" && this.props.location.state.hasOwnProperty('page_no')&& typeof this.props.location.state.page_no === 'number'): this.setState({paramData: {...this.state.paramData,page_no: this.props.location.state.page_no}})
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      BouquetApi.getTreeList(
        "/bouquet/get_all_trees",
        this.state.paramData
      ).then((result) => {
        if (result.status === "success") {
          let totalItem = result.total_data;
          this.setState({ totalDataCount: totalItem });
          this.setState({ data: result.data, fetchInProgress: false });
          console.log(this.state.data);
        } else {
          this.setState({
            errorMsg: "Error! Please try after some time.",
            fetchInProgress: false,
          });
        }
      });
    });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    this.setState((prevState) => ({
      paramData: { ...prevState.paramData, page_no: pageNumber },
    }));
    this.loadTreesData();
  };

  loadStatistics = () => {
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      DashboardApi.getTotalCount("/admin/get_statistics").then(
        (result) => {
          if (result.status === "success") {
            this.setState({
              stats: { ...result.data },
              fetchInProgress: false,
            });
          } else {
            this.setState({
              errorMsg: "Error! Please try after some time.",
              fetchInProgress: false,
            });
          }
        }
      );
    });
  };

  
  componentDidMount() {
    this.loadTreesData();
    this.loadStatistics();
  }

  render() {
    const {
      activeType,
      fetchInProgress,
      data,
      activePage,
      totalDataCount,
    } = this.state;
    return (
      <>
        <Header />
        {fetchInProgress ? (
          <div className="progress2 justify-content-center">
            <img src={loder1} alt="" className="pad" />
          </div>
        ) : (
          <Container className="mt--7" fluid>
            <Row>
              <Col lg="6" xl="3">
                <Link to="complaint">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Trees Planted
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {!!this.state.stats.no_of_trees_planted?this.state.stats.no_of_trees_planted:<img src={loder1} alt="" style={{width: 50}} />}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                            <i className="fas fa-seedling" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col lg="6" xl="3">
                <Link to="complaint">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            CO<sub>2</sub> Absorbed
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                          {!!this.state.stats.co2_count?this.state.stats.co2_count:<img src={loder1} alt="" style={{width: 50}} />}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-burn" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col lg="6" xl="3">
                <Link to="complaint">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Volunteers
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                          {!!this.state.stats.no_of_volunteers?this.state.stats.no_of_volunteers:<img src={loder1} alt="" style={{width: 50}} />}
                            </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col lg="6" xl="3">
                <Link to="complaint">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            O<sub>2</sub> Infused
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                          {!!this.state.stats.o2_count?this.state.stats.o2_count:<img src={loder1} alt="" style={{width: 50}} />}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                            <i className="fas fa-tree" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12} md={6} className="mt-2">
                <ButtonGroup>
                  <Button
                    className={`btn ${
                      activeType === 0 ? "btn-primary" : "btn-danger"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ activeType: 1 });
                    }}
                  >
                    View All Trees
                  </Button>
                  {/* <Button
                    className={`btn ${
                      activeType === 1 ? "btn-primary" : "btn-danger"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ activeType: 0 });
                    }}
                  >
                    View Statistics
                  </Button> */}
                </ButtonGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2 text-right">
                
                <ButtonGroup>
                <Link to="/admin/add-tree" className="btn text-white bg-green">Add New Tree</Link>
                                <ButtonDropdown
                                  isOpen={this.state.ButtonOptionsDropdown}
                                  toggle={this.toggleButtonOptionsDropdown}
                                  direction="left"
                                >
                                  <DropdownToggle caret></DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      header
                                      disabled
                                      className="text-primary"
                                    >
                                      Import / Export
                                    </DropdownItem>
                                    <DropdownItem onClick={() => this.toggleModal(1)}>Import from CSV</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => this.toggleModal(2)}>Export to Excel</DropdownItem>
                                  </DropdownMenu>
                                </ButtonDropdown>
                              </ButtonGroup>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {data !== 0 ? (
                <TreeList data={data} page_no={this.state.paramData.page_no} />
              ) : null}
            </Row>
            <Row style={{ justifyContent: "center" }}>
              {data !== 0 ? (
                <div className="pag float-right">
                  <Pagination
                    className="pagination pagination-lg"
                    itemClass="pag-item"
                    activePage={activePage}
                    itemsCountPerPage={12}
                    totalItemsCount={totalDataCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              ) : null}
            </Row>
            <Modal isOpen={this.state.openedModal===1} toggle={() => this.toggleModal(1)}>
        <ModalHeader toggle={() => this.toggleModal(1)}>Import from CSV</ModalHeader>
        <ModalBody>
        <Form encType="multipart/form-data">
        <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-image"
                        >
                          File<span className="error-message">*</span> (.csv/.xls/.xlsx files supported)
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="import"
                          placeholder="import"
                          type="file" 
                          name="import"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          multiple={false}
                          // onChange={this.fileChangedHandler}
                        />
                        {this.state.errors.import && (
                          <InlineError text={this.state.errors.import} />)}
                      </FormGroup>
        </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={true} onClick={() => this.toggleModal(1)}>Start Import</Button>{' '}
          <Button color="secondary" onClick={() => this.toggleModal(1)}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={this.state.openedModal===2} toggle={() => this.toggleModal(2)}>
        <ModalHeader toggle={() => this.toggleModal(1)}>Export Data</ModalHeader>
        <ModalBody>
        <Form encType="multipart/form-data">
        <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-image"
                        >
                          Select Start Date<span className="error-message">*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="startDate"
                          placeholder="Start Date"
                          type="date" 
                          name="startDate"
                          // onChange={this.fileChangedHandler}
                        />
                        {this.state.errors.startDate && (
                          <InlineError text={this.state.errors.startDate} />)}
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-image"
                        >
                          Select End Date<span className="error-message">*</span>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="endDate"
                          placeholder="End Date"
                          type="date" 
                          name="endDate"
                          // onChange={this.fileChangedHandler}
                        />
                        {this.state.errors.endDate && (
                          <InlineError text={this.state.errors.endDate} />)}
                      </FormGroup>
        </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary"  disabled={true} onClick={() => this.toggleModal(2)}>Do Something</Button>{' '}
          <Button color="secondary" onClick={() => this.toggleModal(2)}>Cancel</Button>
        </ModalFooter>
      </Modal>
          </Container>
        )}
      </>
    );
  }
}

import React,{Component, Fragment} from "react";
import '../../../assets/css/custom.css';
// reactstrap components
import {
  Form,
  Row,
  Card,
  Container,
  CardHeader
} from "reactstrap";
//import ReactDatetime from "react-datetime";
import { Redirect } from 'react-router-dom';
// core components
import Header from "../../../components/Headers/Header.jsx";
import NewsApi from '../../../API/newsapi';
import Helper  from '../../../helpers/helper';
import moment from 'moment'
import Slider from 'react-animated-slider';
import loder1 from '../../../assets/loder1.gif';
import 'react-animated-slider/build/horizontal.css';
import { Link } from 'react-router-dom';

class NewsShow extends Component {
  constructor() {
    super();
    this.state = {
      data: {
        news_id:'',
        headline: '',
        description: '',
        event_venue: '',
        news_banner:'',
        news_from: '',
        news_till: '',
        status: 0,
        category: 1,
        images:{}
      },
      imageLink:{},
      redirect: false,
      showQuotes:true,
      fetchInProgress:false,
      errors: {}
    };
    
  }
  componentDidMount() { 
    const { newsid } = this.props.location.state;
    this.setState({fetchInProgress:true});
     Helper.authenticateToken().then(()=>{
        NewsApi.getNewsDetails("/news/news-details",newsid).then((result) => {
          this.setState({fetchInProgress:false});
            let newdata={
              news_id:result.data.news_id,
              headline:result.data.headline,
              description:result.data.description,
              event_venue:result.data.event_venue,
              news_banner:result.data.news_banner,
              news_from:result.data.news_from,
              news_till:result.data.news_till,
              status:result.data.status,
              category:result.data.category,
              images:{}
            }
            this.setState({imageLink:result.data.images});
            this.setState({data:newdata});
            if(result.data.category=== 4)
              this.setState({showQuotes:false});
          })
    }) 
    
    
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/admin/news'} />
    }
    const { data,imageLink,showQuotes,fetchInProgress } = this.state;
    const items = [];
    if(data.news_banner!=null){
  let allimages=data.news_banner.split('|*|');
  for (var i=0;i<allimages.length;i++){
    items.push(<div style={{ background: `url('${imageLink[i]}') no-repeat center`,'backgroundSize': 'cover'  }} key={i}  alt="preview" name={allimages[i]}  ></div>)
  }}
  else{
    items.push(<div style={{ background: `url('${require("../../../assets/img/noimage.png")}') no-repeat center`,'backgroundSize': 'cover'  }} key={0}  alt="preview"  ></div>)
  }


    return (
      <Fragment>
        <Header />
        {/* Page content */}
        {fetchInProgress ? <div className="progress2 justify-content-center"> <img src={loder1} alt="" className="pad"/></div> :
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow card-bg-view">
              <CardHeader>
                <div className="row">
                <div className="col-md-6 col-6 pt-2">
                 News Details
                 </div>
                 <div className="col-md-6 col-6 text-right">
                 <Link to="news">
                  <button type="button" className="btn btn-primary md">Back</button>
                </Link>
                 </div>
                 </div>
                 </CardHeader>
                <Form encType="multipart/form-data">
                  <div className="row">
                    <div className="col-md-6 text-center">
                    <Slider autoplay={3000}>
                      {items}
                    </Slider>
                    </div>
                    <div className="col-md-6 text-md-left pl-md-2">
                      <div className="headline">
                            <label htmlFor="headline">{data.headline}</label>
                        </div>
                        <div className="venue" style={showQuotes ? {} : { display: 'none' }}>
                        <label htmlFor="event_venue"><i className="ni ni-pin-3"></i> Event venue : {data.event_venue}</label>
                        </div>
                        <div className="text-md-left date" >
                        <label htmlFor="event_venue"><i className="ni ni-calendar-grid-58"></i> News From : {moment(data.news_from).format('DD/MM/YYYY')}</label>
                        </div>
                        <div className="text-md-left date">
                            <label htmlFor="event_venue"> <i className="ni ni-calendar-grid-58"></i> News Till : {moment(data.news_till).format('DD/MM/YYYY')}</label>
                        </div>
                        <div className="text-md-left status">
                          <label htmlFor="event_venue"><i className="ni ni-user-run"></i> Status : {data.status==='0'?"Draft":(data.status==='1')?"Published":"Delete"}</label>
                      </div>
                      <div className="text-md-left status">
                          <label htmlFor="event_venue"><i className="ni ni-collection"></i> Category : {data.category==='1'?"Paribesh News":(data.status==='2')?"WBPCB Intiative":(data.status==='3')?"Info-in Graphics":"Quotes"}</label>
                      </div>
                    </div>                
                    </div>
                  
                    <div className="row" style={showQuotes ? {} : { display: 'none' }}>
                      <div className="col-md-12 description">
                          <label htmlFor="description">{data.description}</label>
                      </div>
                    </div>
                </Form>
              </Card>
            </div>
          </Row>
        </Container>
        }
      </Fragment>
    );
  }
}

export default NewsShow;

import React from "react";
// reactstrap components
import "../../../assets/css/custom.css";
import ImgUser from "../../../assets/img/user.png";
//import {plog} from '../../../config/config';
import {
  FormGroup,
  Form,
  Input,
  Col,
  Card,
  Container,
  Row,
  Button,
  Modal,
  Alert,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
// core components
import Header from "../../../components/Headers/Header";
import ComplaintsApi from "../../../API/complainapi";
import Helper from "../../../helpers/helper";
import InlineError from "../../../components/messages/InlineError";
import Pagination from "react-js-pagination";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-accessible-accordion/dist/fancy-example.css";
import Rating from "react-rating";
import magnifyIcon from "../../../assets/img/magnify.png";
import loder1 from "../../../assets/loder1.gif";
import "font-awesome/css/font-awesome.min.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import BasicTimeline from "./TimelineCompnents";
import FitlterPages from "./FilterPage";
import FilterPage from "./FilterPage";
import {
  filterBy,
  filterByDateRange,
  getKPIData,
} from "../../../API/complainApiNew";
import DateRangePicker from "./DateRangePicker";
class ComplaintList extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      paramData: {
        per_page: 10,
        pageno: 1,
        token_no: "",
        status_by: "all",
      },
      actionData: {
        complaint_id: "",
        status: 0,
        action_message: "",
      },
      isModalOpen: false,
      fetchInProgress: false,
      activePage: 1,
      totalDataCount: 1,
      redirect: false,
      ratingmessage: false,
      errors: {},
      ratingButton: -1,
      Total_Register_Count: 0,
      Total_Open_Count: 0,
      Total_Resolve_count: 0,
      Total_Irrelevant_count: 0,
      filterSearch: 0,
    };

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    this.setState((prevState) => ({
      paramData: { ...prevState.paramData, pageno: pageNumber },
    }));
    this.loadData();
  };
  seachComplaint = (e) => {
    e.preventDefault();
    this.loadData();
  };
  clearSearch = (e) => {
    this.setState((prevState) => ({
      paramData: { ...prevState.paramData, token_no: "", status_by: "all" },
    }));
    e.preventDefault();
    this.loadData();
  };
  componentWillMount() {
    this.loadData();
    this.getKPIData();
  }
  loadData = () => {
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      ComplaintsApi.getComplaintsList(
        "/complaint/all",
        this.state.paramData
      ).then((result) => {
        let totalItem = result.total_data;
        this.setState({ totalDataCount: totalItem });
        this.setState({ data: result.data, fetchInProgress: false });
        // console.log(result.data);
      });
    });
  };
  getKPIData = async () => {
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(async () => {
      const getKPI = await getKPIData("/complaint/getAllRegisterComplain");
      // kpiData
      // console.log(getKPI.total_data.Total_Register_Count);

      this.setState({
        Total_Register_Count: getKPI?.total_data[0].Total_Register_Count,
        Total_Open_Count: getKPI?.total_data[1].Total_Open_Count,
        Total_Resolve_count: getKPI?.total_data[2].Total_Resolve_count,
        Total_Irrelevant_count: getKPI?.total_data[3].Total_Irrelevant_count,
        fetchInProgress: false,
      });
      // console.log(getKPI.total_data);
    });
  };
  handleAdd = (item) => {
    this.setState((prevState) => ({
      actionData: {
        ...prevState.actionData,
        complaint_id: item.complaint_id,
        action_message: item.action_message,
        status: item.status,
      },
      isModalOpen: true,
    }));
  };

  toggleModal = (state) => {
    this.setState({ isModalOpen: false });
  };
  onSearchChange = (e) => {
    this.setState({ filterSearch: 0 });
    this.setState({
      paramData: { ...this.state.paramData, [e.target.name]: e.target.value },
    });
  };
  onSelectedChange = (e) => {
    if (Object.keys(e).length > 1) {
      console.log(e[0].label, e.filed);
      this.setState({ filterSearch: 1 });

      if (e.filed === "name") {
        this.setState({ fetchInProgress: true });
        Helper.authenticateToken().then(async () => {
          const dataList = await filterBy(e.filed, e[0].label);
          console.log(dataList);

          let totalItem = dataList.total_data;
          this.setState({ totalDataCount: totalItem });
          this.setState({ data: dataList.data, fetchInProgress: false });
          console.log(dataList.data);
        });
      }
      if (e.filed === "mobile_no") {
        this.setState({ fetchInProgress: true });
        Helper.authenticateToken().then(async () => {
          const dataList = await filterBy(e.filed, e[0].label);
          console.log(dataList);

          let totalItem = dataList.total_data;
          this.setState({ totalDataCount: totalItem });
          this.setState({ data: dataList.data, fetchInProgress: false });
          console.log(dataList.data);
        });
      }
      if (e.filed === "token_no") {
        this.setState({ fetchInProgress: true });
        Helper.authenticateToken().then(async () => {
          const dataList = await filterBy(e.filed, e[0].label);
          console.log(dataList);

          let totalItem = dataList.total_data;
          this.setState({ totalDataCount: totalItem });
          this.setState({ data: dataList.data, fetchInProgress: false });
          console.log(dataList.data);
        });
      }
    }
  };
  onChange = (e) => {
    this.setState({
      actionData: { ...this.state.actionData, [e.target.name]: e.target.value },
    });
  };
  filterByDateRange = (e) => {
    console.log(e);
    if (e.selection.endDate !== e.selection.startDate) {
      this.setState({ filterSearch: 1 });
      console.log(e.selection.endDate, e.selection.startDate);
      var endDate = moment(e.selection.endDate).format("YYYY-MM-DD");
      var startDate = moment(e.selection.startDate).format("YYYY-MM-DD");
      console.log(endDate, startDate);
      Helper.authenticateToken().then(async () => {
        const dataList = await filterByDateRange(endDate, startDate);
        console.log(dataList);

        let totalItem = dataList.total_data;
        this.setState({ totalDataCount: totalItem });
        this.setState({ data: dataList.data, fetchInProgress: false });
        console.log(dataList.data);
      });
    }

    // console.log(Sdate, Edate);
  };
  complaintAction = () => {
    Helper.authenticateToken().then(() => {
      const errors = this.validate(this.state.actionData);
      this.setState({ errors });
      if (Object.keys(errors).length === 0) {
        ComplaintsApi.complaintAction(
          "/complaint/complaint-action",
          this.state.actionData
        ).then((result) => {
          if (result.data.status === "success") {
            this.setState({ isModalOpen: false });
            window.location.reload();
          } else {
            this.setState({
              errors: result.data.error_description,
              loading: false,
            });
          }
        });
      }
      this.setState((prevState) => ({
        actionData: { ...prevState.actionData, status: 0 },
      }));
    });
  };
  validate = (actionData) => {
    const errors = {};
    if (!actionData.action_message)
      errors.action_message = "Please Give Action Message";
    return errors;
  };
  addRating = (rate, complaint_id) => {
    let rating = {
      complaint_id: complaint_id,
      rating_point: rate,
    };
    ComplaintsApi.addRating("/complaint/complaint-rating", rating).then(
      (result) => {
        if (result.data.status === "success") {
          this.setState({ ratingmessage: true });
          setTimeout(
            function () {
              this.setState({ redirect: true, ratingmessage: false });
              window.location.reload();
            }.bind(this),
            3000
          );
        } else {
          this.setState({
            errors: result.data.error_description,
            loading: false,
          });
        }
      }
    );
  };

  render() {
    const {
      data,
      errors,
      fetchInProgress,
      paramData,
      ratingButton,
      Total_Register_Count,
      Total_Resolve_count,
      Total_Irrelevant_count,
      Total_Open_Count,
      filterSearch,
    } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        {fetchInProgress ? (
          <div className="progress2 justify-content-center">
            <img src={loder1} alt="" className="pad" />
          </div>
        ) : (
          <Container className="mt--7" fluid>
            {/* Table */}
            <Row>
              <div className="col-md-12 mb-2">
                <div className="row " style={{ margin: 2, gap: 8 }}>
                  <div className="col-sm bg-primary p-3 rounded text-white font-weight-bold">
                    Registered :{Total_Register_Count}
                  </div>
                  <div className="col-sm bg-success p-3 rounded text-white font-weight-bold">
                    Resolved : {Total_Resolve_count}
                  </div>{" "}
                  <div className="col-sm bg-warning p-3 rounded text-white font-weight-bold">
                    Irrelevant : {Total_Irrelevant_count}
                  </div>{" "}
                  <div className="col-sm bg-info p-3 rounded text-white font-weight-bold">
                    Open : {Total_Open_Count}
                  </div>
                </div>
              </div>

              <div className="col-md-12 mb-2">
                <div className="box">
                  <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <div className="head">
                            <img src={magnifyIcon} className="mr-3" alt="" />{" "}
                            Search...
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <Form encType="multipart/form-data">
                          <FilterPage
                            onSelectedChange={this.onSelectedChange}
                          />
                        </Form>
                        <Form encType="multipart/form-data">
                          <Row className="pt-4">
                            {/* <Col md="3">
                              <FormGroup>
                                <label htmlFor="status" className="float-left">
                                  Token No.
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="token_no"
                                  placeholder="token no"
                                  type="text"
                                  name="token_no"
                                  onChange={this.onSearchChange}
                                  value={paramData.token_no}
                                />
                              </FormGroup>
                            </Col> */}
                            <Col md="4">
                              <label htmlFor="Date Range">
                                Filter By Date Range
                              </label>
                              <DateRangePicker
                                filterByDateRange={this.filterByDateRange}
                              />
                            </Col>

                            <Col md="4">
                              <FormGroup>
                                <label htmlFor="status">Status</label>
                                <select
                                  id="status"
                                  className="form-control"
                                  name="status_by"
                                  onChange={this.onSearchChange}
                                  value={paramData.status_by}
                                >
                                  <option value="all">All</option>
                                  <option value="0">Registered</option>
                                  <option value="1">Resolved</option>
                                  <option value="2">Irrelevant</option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col md="4" className="">
                              <Button
                                color="primary"
                                className="pad-t"
                                size="md"
                                onClick={this.seachComplaint}
                              >
                                <i className="fa fa-search"></i>{" "}
                              </Button>
                              <Button
                                color="primary"
                                className="pad-t"
                                size="md"
                                onClick={this.clearSearch}
                              >
                                Clear{" "}
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>

              <div className="col">
                {!!data && data !== 0 ? (
                  data.map((item, index) => {
                    return (
                      <Card className="shadow table" key={index}>
                        <div className="col-md-12">
                          <div className="card-view">
                            <div className="row">
                              <div className="col-md-3 col-12 text-center p-0">
                                {item.complaint_images == null ? (
                                  <img
                                    src={ImgUser}
                                    width={100}
                                    alt=""
                                    className="img-fluid bx-img"
                                  />
                                ) : (
                                  <img
                                    src={item.images[0]}
                                    width={100}
                                    alt=""
                                    className="img-fluid bx-img"
                                  />
                                )}
                              </div>
                              <div className="col-md-9 col-12 pt-2 pl-3 pr-3 pb-3">
                                <h3>Token Number:{item.token_no}</h3>
                                <span className="gray">
                                  {moment(item.createdAt).format("ll")}
                                </span>
                                <p>{item.description}</p>
                                <p>
                                  <span className="badge badge-success">
                                    {item.status === 0
                                      ? "Registered"
                                      : item.status === 1
                                      ? "Resolved"
                                      : "Irrelevent"}
                                  </span>
                                  <span>
                                    {" "}
                                    <Rating
                                      emptySymbol="fa fa-star custom custom-full"
                                      fullSymbol="fa fa-star custom custom-full"
                                      stop={item.rating_point}
                                      readonly
                                    ></Rating>
                                  </span>
                                </p>

                                <div className="float-md-right bottom-a">
                                  <ButtonDropdown
                                    sm
                                    isOpen={ratingButton === index}
                                    toggle={(e) => {
                                      if (ratingButton === index)
                                        this.setState({ ratingButton: -1 });
                                      else
                                        this.setState({ ratingButton: index });
                                    }}
                                  >
                                    {/* <DropdownToggle
                                      caret
                                      size="sm"
                                      color="primary"
                                      style={{ marginRight: 5 }}
                                      disabled={
                                        item.rating_point === 0 ? false : true
                                      }
                                    >
                                      Rating
                                    </DropdownToggle> */}
                                    <DropdownMenu>
                                      <DropdownItem header>
                                        <Rating
                                          style={{
                                            fontSize: "28px !important",
                                          }}
                                          emptySymbol="fa fa-star-o custom custom-empty"
                                          fullSymbol="fa fa-star custom custom-full"
                                          onChange={(e) =>
                                            this.addRating(e, item.complaint_id)
                                          }
                                          renderStarIcon={10}
                                        />
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </ButtonDropdown>
                                  {/* <span className="badge badge-success" style={ratingmessage ? {} : { display: 'none' }}>Rated Successfull</span> */}
                                  {/* <MDBDropdown dropup size="sm" className="mr-2 r-font" >
                                  <MDBDropdownToggle caret color="primary" style={{ padding: "0.4rem 1.6rem" }} disabled={item.rating_point===0?false:true}>
                                    Rating
                                  </MDBDropdownToggle>
                                  <MDBDropdownMenu basic>
                                  
                                    <MDBDropdownItem >
                                    
                                    <Rating 
                                    style={{fontSize:"28px !important"}}
  emptySymbol="fa fa-star-o custom custom-empty"
  fullSymbol="fa fa-star custom custom-full"
  onChange={(e) => this.addRating(e,item.complaint_id)}
  renderStarIcon={10}
/>
                                    </MDBDropdownItem>
                                  </MDBDropdownMenu>
                                </MDBDropdown> */}
                                  <Link
                                    to={{
                                      pathname: "show-complaint",
                                      state: { tokenno: item.token_no },
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-info btn-sm"
                                    >
                                      View
                                    </button>
                                  </Link>{" "}
                                  <button
                                    type="button"
                                    className="btn btn-success btn-sm"
                                    onClick={(e) => this.handleAdd(item)}
                                  >
                                    Action
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    );
                  })
                ) : (
                  <Alert color="warning">No Record Found!</Alert>
                )}
                {data !== 0 ? (
                  filterSearch === 0 ? (
                    <div className="pag float-right">
                      <Pagination
                        className="pagination pagination-lg"
                        listClassName="pagination-lg"
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalDataCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  ) : (
                    <div className="pag float-right">
                      <Pagination
                        className="pagination pagination-lg"
                        listClassName="pagination-lg"
                        activePage={this.state.activePage}
                        itemsCountPerPage={10000}
                        totalItemsCount={this.state.totalDataCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
            </Row>
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.isModalOpen}
              toggle={() => this.toggleModal("isModalOpen")}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Action
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("isModalOpen")}
                >
                  <span aria-hidden={true} style={{ fontSize: 30 }}>
                    ×
                  </span>
                </button>
              </div>
              <Form>
                <div className="modal-body">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label htmlFor="headline">Status</label>
                        <select
                          id="status"
                          className="form-control"
                          name="status"
                          value={this.state.actionData.status}
                          onChange={this.onChange}
                        >
                          <option value="0">Registered</option>
                          <option value="1">Resolved</option>
                          <option value="2">Irrelevant</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <label htmlFor="exampleEmail">Action Message</label>
                        <Input
                          className="form-control-alternative"
                          placeholder="Write a large text here ..."
                          rows="3"
                          type="textarea"
                          name="action_message"
                          value={this.state.actionData.action_message}
                          onChange={this.onChange}
                        />
                      </FormGroup>
                      {errors.action_message && (
                        <InlineError text={errors.action_message} />
                      )}
                    </Col>
                  </Row>
                </div>

                <div className="modal-footer" style={{ marginTop: -35 }}>
                  <Button
                    color="primary"
                    type="button"
                    onClick={this.complaintAction}
                  >
                    Send Message
                  </Button>
                </div>
                {/* Timeline */}
                <div>
                  <BasicTimeline id={this.state.actionData.complaint_id} />
                </div>
              </Form>
            </Modal>
          </Container>
        )}
      </>
    );
  }
}

export default ComplaintList;

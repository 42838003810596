import React, { useEffect, useState } from "react";
import { Container, Row, Card, Button, CardBody, Alert } from "reactstrap";
import Header from "../../../components/Headers/Header";
import { groupsRead, approveReject } from "../../../API/Groups.js";
import Helper from "../../../helpers/helper";
import toast, { Toaster } from "react-hot-toast";

const Groups = () => {
  const [groupsData, setGroupsData] = useState([]);
  const [reload, setReload] = useState(0);
  useEffect(() => {
    Helper.authenticateToken().then(() => {
      groupsRead("/group/pending_groups").then((result) => {
        if (result.status === "success") {
          console.log("Gropus data fetched successfully");
          console.log(result.data);
          setGroupsData(result.data);
        } else {
          console.log("failed to fetch");
        }
      });
    });
  }, [reload]);

  const approveRejectHanlder = ({ group_id, approval_state }) => {
    Helper.authenticateToken().then(() => {
      approveReject({
        group_id: group_id,
        approval_state: approval_state,
      }).then((result) => {
        console.log(result?.data?.status);
        if (result?.data?.status === "success") {
          console.log(result?.data?.error_description);
          toast.success(result?.data?.error_description);

          setReload((prev) => prev + 1);
        } else {
          toast.error("Something went wrong!");
        }
      });
    });
  };
  return (
    <>
      <Toaster />
      <Header />
      <Container className="mt--7" fluid>
        {/* <Alert color="warning">No Record Found!</Alert> */}
        <Row>
          {groupsData?.map((item) => {
            return (
              <Card
                outline
                style={{
                  width: "20rem",
                }}
                className="m-2"
              >
                <img
                  alt="Sample"
                  src={item?.image_path}
                  style={{ width: 300, height: 200, objectFit: "cover" }}
                />
                <CardBody>
                  <h1>{item?.name}</h1>

                  <h1 className=" text-sm">Name: {item?.entity_name}</h1>
                  <h1 className=" text-sm">Address: {item?.entity_address}</h1>
                  <h1 className=" text-sm">
                    User Role In Entry: {item?.user_role_in_entity}
                  </h1>
                  <h1 className=" text-sm">
                    Group Type: {item?.group_type_name}
                  </h1>
                  <h1
                    className="p-2 text-sm"
                    style={{
                      backgroundColor:
                        item?.approval_state === 1
                          ? "#fff"
                          : item?.approval_state === 2
                          ? "#0000FF"
                          : "red",
                      borderRadius: 10,
                      color: item?.approval_state !== 1 ? "#fff" : "#000",
                    }}
                  >
                    Status:
                    {`${
                      item?.approval_state === 1
                        ? "Pendding"
                        : item?.approval_state === 2
                        ? "Approved"
                        : "Rejected"
                    }`}
                  </h1>
                  {item?.approval_state === 1 && (
                    <Row>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() =>
                          approveRejectHanlder({
                            group_id: item?.id,
                            approval_state: 2,
                          })
                        }
                      >
                        Approve
                      </Button>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() =>
                          approveRejectHanlder({
                            group_id: item?.id,
                            approval_state: 3,
                          })
                        }
                      >
                        Reject
                      </Button>
                    </Row>
                  )}
                </CardBody>
              </Card>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default Groups;

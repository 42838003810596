import React, { Fragment } from "react";
import '../../../assets/css/custom.css';
// reactstrap components
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,CardHeader
} from "reactstrap";
//import ReactDatetime from "react-datetime";
// core components
import 'react-datepicker/dist/react-datepicker.css';
import loder1 from '../../../assets/loder1.gif';
import TopContestUsers from "./TopContestUsers";
import PopularOrganiser from "./PopularOrganiser";
import TopOrganiser from "./TopOrganiser";
import ContestHeader from "../../../components/Headers/ContestHeader";
import DownloadExcel from "./DownloadExcel";
import { withRouter } from 'react-router';


class AllTopList extends React.Component {
  constructor() {
    super();
    this.state = {
        totalcount: {},
        fetchInProgress:false
    };
    
  }
 

  
    
    render() {
        const { fetchInProgress } = this.state;
        return (
          <Fragment>
            <ContestHeader/>
            
           {fetchInProgress ? <div className="progress-dash justify-content-center"> <img src={loder1} alt="" className="pad"/></div> :
            <div> 
         
              <Container className="mt--7" fluid>
                <Row className="pb-2">
                    <Col lg="6" xl="6" >
                        <DownloadExcel/>  
                    </Col>
                    <Col lg="6" xl="6" className="text-right">
                     <button type="button" className="btn btn-primary md" onClick={ () => this.props.history.go(-1)}>Back</button>
                  </Col>
                </Row>
                <Row >
                  <Col lg="6" xl="4">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardHeader style={{backgroundColor: "#ff935c",color:"white"}}>Top User</CardHeader>
                      <CardBody className="leaderboard-body">
                         <TopContestUsers/>  
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="4">
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardHeader style={{backgroundColor: "#7f78d2",color:"white"}}>Top Organiser</CardHeader>
                        <CardBody className="leaderboard-body">
                             <TopOrganiser/>  
                        </CardBody>
                      </Card>
                  </Col>
                  <Col lg="6" xl="4">
                    

                    <Card className="card-stats mb-4 mb-xl-0">
                        <CardHeader style={{backgroundColor: "purple",color:"white"}}>Popular Organiser</CardHeader>
                        <CardBody className="leaderboard-body">
                            <PopularOrganiser/> 
                        </CardBody>
                      </Card>

                    
                  </Col>

                </Row>
                
              </Container>
            </div>
            
            
           }
         </Fragment>
        );
      }
    
  
}

export default withRouter(AllTopList);

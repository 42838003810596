import querystring from 'querystring';
import {BASE_URL} from './config/config';

export function PostLogOutData(type, userData, access_token) {
    let url = '/users/';
    return new Promise((resolve, reject) => {

        //for logout
        fetch(BASE_URL + url + type, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${access_token}`
            },
            body: querystring.stringify(userData)
        })
        .then((response) => resolve(response.json()))
        .catch((error) => {
            reject(error);
        });
    });
}
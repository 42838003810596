import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { getPreSms } from "../../../API/complainApiNew";
import Helper from "../../../helpers/helper";

const TimelineCompnents = ({ id }) => {
  const [dataItems, setDataItems] = React.useState([]);
  const [isResponeTaken, setISResponeTaken] = React.useState(false);
  React.useEffect(() => {
    const dataSms = () => {
      Helper.authenticateToken().then(async () => {
        const data = await getPreSms(id);
        if (data.status === "success") {
          console.log();
          if (data.data !== 0) {
            setISResponeTaken(true);
            setDataItems(data.data);
          }
        }
      });
    };
    dataSms();
  }, [id]);
  return (
    <>
      {isResponeTaken && (
        <div style={{ height: 230, overflowY: "scroll" }}>
          <Timeline position="alternate">
            {dataItems.map((item) => {
              return (
                <TimelineItem key={item.action_time}>
                  <TimelineOppositeContent color="text.secondary">
                    {item.action_time}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>{item.action_message}</TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </div>
      )}
    </>
  );
};

export default TimelineCompnents;

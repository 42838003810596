import React, { Fragment } from "react";
import '../../../assets/css/custom.css';
import {
  FormGroup,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  Alert
} from "reactstrap";
import ContestHeader from "../../../components/Headers/ContestHeader";
import Helper  from '../../../helpers/helper';
import GreenPujaApi from '../../../API/greenpujaapi'
import loder1 from '../../../assets/loder1.gif';
import { withRouter } from 'react-router';
import 'react-datepicker/dist/react-datepicker.css';

class QuestionList extends React.Component {
  constructor() {
    super();
    this.state = {
      contest_id:"",
      visibleSuccess: false,
      visibleError: false,
      successmsg: {},
      fetchInProgress: false,
      redirect:false,
      errors: {},
      contest_list:[],
      question_list:[]
    };
    this.changeContest=this.changeContest.bind(this);
  }
  deActiveQuestion=(question_id)=>{
    Helper.authenticateToken().then(()=>{
      GreenPujaApi.updateQuestionStatus("/contest/question-edit/"+question_id).then((result) => {
       this.loadData();

    }) 
  })
}
  componentDidMount() { 
      this.loadData();
  }
  loadData=()=>{
    let all_contest=[];
    this.setState({ fetchInProgress: true });
     Helper.authenticateToken().then(()=>{
      GreenPujaApi.getLatestContest("/organiser/getLatestContest").then((result) => {
        let item;
        for(var i=0;i<result.data.length;i++){
          item={
            contest_id:result.data[i].contest_id,
            contest_name:result.data[i].contest_name
        }
        all_contest.push(item);
        }
        if(all_contest.length===1){
          this.setState({ contest_id:all_contest[0].contest_id })
        }
        this.setState({contest_list:all_contest,fetchInProgress: false});
        this.getQuestionList({organiser_id:5,contest_id:all_contest[0].contest_id,fetchInProgress: false});
      })

    }) 

  }
  changeContest=e=>{   
    this.setState({ contest_id: e.target.value });
    this.getQuestionList({organiser_id:5,contest_id:e.target.value});
  }

  getQuestionList = (data) => {
    Helper.authenticateToken().then(()=>{
    GreenPujaApi.getQuestionList('/contest/questionaire', data).then((result) => {
     
      if (result.status === "success") {
        if(result.data===undefined){
            this.setState({question_list:[]})
         }
         else{ 
            this.setState({question_list:result.data})
         }
       
      }  else {
        this.setState({ errors: result.errors, fetchInProgress: false, visibleError: true });
      }
    }).catch(err=>{
      //this.setState({ errors:err.response.data.error_description, fetchInProgress: false, visibleError: true });
    }) 
    })
  };

  render() {
    const {fetchInProgress,question_list}=this.state;
    return (
      <Fragment>
        <ContestHeader/>
        { fetchInProgress ?<div className="progress2 justify-content-center"><img src={loder1} alt="" className="pad"/></div> :
        <Container className="mt--7" fluid>
        <Row>
            <div className="col">
              <Card className="shadow card-bg">
                <CardHeader className="border-0">
                  <div className="row"><div className="col-md-6 col-6 pt-2">Question List</div>
                  <div className="col-md-6 col-6 text-right">
                    <a href="/admin/question-add"><button type="button" className="btn btn-info md ">Add Question</button></a>{" "}
                    <button type="button" className="btn btn-primary md " onClick={ () => this.props.history.go(-1)}>Back</button>
                  </div></div>
                  
                </CardHeader>
                <CardBody>
                
                  <Row>
                      <Col md="2">
                          <FormGroup>
                            <label htmlFor="Contest">Contest Name<span className="error-message">*</span></label>
                            
                          </FormGroup>
                      </Col>
                      <Col md="6">
                          <FormGroup>
                            <select  id="contest_id" className="form-control" name="contest_id" onChange={this.changeContest}>
                                {this.state.contest_list.map((item, index) =>{
                                    return(<option value={item.contest_id} key={index}>{item.contest_name}</option>)
                                })}
                            </select>
                            
                          </FormGroup>
                      </Col>
                  </Row>
              <br></br>
                  

        {(question_list.length!==0)?question_list.map((item, idx) => {
            return (
          <div className="shareholder" key={idx} >
              <Row key={idx}>
              
              <Col md="10">
                <FormGroup>
                  <label color="primary" >
                    {(idx+1)+". "+item.question}
                  </label>
                 
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                    <Button className="btn btn-danger" name={item.question_id} onClick={(e) => this.deActiveQuestion(e.target.name)}>Delete</Button>
                  </FormGroup>
              </Col>
            </Row>
            <br></br>
          </div>)
        }):<Alert color="warning"  >No Data Found</Alert>   
          }
          </CardBody>
          </Card>
          </div>
          </Row>
      </Container>
        }
      </Fragment>
    );
  }
}

export default withRouter(QuestionList);

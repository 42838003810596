import React from "react";
import Helper from '../../helpers/helper';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Alert,
  Col
} from "reactstrap";
import {Redirect} from 'react-router-dom';
import {PostData} from '../../PostData';
import Validator from 'validator';
import InlineError from '../../components/messages/InlineError'
import {plog} from '../../config/config';
import loder1 from '../../assets/loder1.gif';
import ClientCaptcha from "react-client-captcha"
import "react-client-captcha/dist/index.css"
//import "./styles.css";

class Login extends React.Component {
  constructor(){
    super();
    this.state = {
      data:{
        username:'',
        password:'',
        adm_login:1
    },
      captcha: 'abcd',
      captchaEnter:'',
      redirect: false,
      fetchInProgress:false,
      captchaError:false,
      errors:{},
      captchaCode:''
    };
    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this);
    this.captchaChange = this.captchaChange.bind(this);
  }
   
 setCode = captchaCode => {
  this.setState({ captchaCode })
}
  
 login=()=>{
        const errors=this.validate(this.state.data);
        this.setState({ errors });
        this.setState({fetchInProgress:false});
        if(this.state.captchaCode !== this.state.captchaEnter)
        {
            this.setState({captchaError:true});
            setTimeout(function () {
              window.location.reload();
            }, 3000) 
        }
        
        if(Object.keys(errors).length===0 && this.state.captchaCode === this.state.captchaEnter){
          this.setState({fetchInProgress:true});
         PostData('login',this.state.data).then((result) => {     
          
        let responseJson = result;
        if(responseJson.status === "success"){
          
         // sessionStorage.setItem('userData',JSON.stringify(responseJson));
         // sessionStorage.setItem('loginid',JSON.stringify(responseJson.login_logid));
          Helper.userDetailsToken('/users/profile', responseJson.access_token).then((pdresult)=>{
            let userDetails = pdresult;
            plog("USer details--",userDetails);
            plog("Profile details--",userDetails.profile_details);
            localStorage.setItem('userDetails',JSON.stringify(userDetails.profile_details));
            localStorage.setItem('userData',JSON.stringify(responseJson));
            localStorage.setItem('loginid',JSON.stringify(responseJson.login_logid));
            this.setState({redirect: true});
          });
          // plog("inside login",this.state.redirect);
          
        }else{
          this.setState({fetchInProgress:false});
          this.setState({errors:responseJson.error_description,loading:false})
        }
      })  
    }
    else{
      setTimeout(function () {
        window.location.reload();
      }, 3000)
    }
  }
  validate=(data)=>{
    const errors={};
    if(!Validator.isEmail(data.username)) errors.username="Invalid Username";
    if(!data.password) errors.password="Invalid Password";
    return errors;
}
  onChange = e =>
  this.setState({
      data:{...this.state.data,[e.target.name]:e.target.value}
  })

  captchaChange = e =>{
  this.setState({
      captchaEnter:e.target.value
  })
}

  render() {
    const {data, errors,redirect,fetchInProgress,captchaError}=this.state;
    plog("Rerender in login-",redirect);
    if (redirect || localStorage.getItem('userData')){
      return (<Redirect to='/admin/index' />);
    }
    return (

      <>
        {fetchInProgress ? <div className="row justify-content-center"> <img src={loder1} alt="" className="pad"/></div> :
        
        <Col lg="5" md="7">
          <Card className="bg-secondary-login shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
              </div>
              <div className="btn-wrapper text-center">
              <img className="rounded" alt="..." src={require("../../assets/img/brand/logo.png")} />
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form" >
              {errors.message && <Alert color="warning">
              <p>{errors.message}</p>
                  </Alert>
                       
                }
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email"  name="username" value={data.username}
                     onChange={this.onChange}/>
                  </InputGroup>
                  {errors.username && <InlineError text={errors.username}/>}
                </FormGroup>
                <FormGroup >
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password"  name="password" value={data.password}
                     onChange={this.onChange}/>
                  </InputGroup>
                  {errors.password && <InlineError text={errors.password}/>}
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  {/* <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label> */}
                </div>
                <div className="row">
                  <div className="col-md-6">
                  <Input
                                placeholder="Enter captcha"
                                type="text"
                                name="captchaEnter"
                                onChange={this.captchaChange}
                              />
                  {captchaError && <InlineError text="Wrong Captcha"/>}
                  </div>
                  <div className="col-md-6 capcha-img text-center">
                  <ClientCaptcha captchaCode={this.setCode}/>
                    
                  </div>
                </div>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="button" onClick={this.login} >
                    Sign in
                  </Button>
                </div>
              </Form>
              
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              {/* <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a> */}
            </Col>
            <Col className="text-right" xs="6">
              {/* <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Create new account</small>
              </a> */}
            </Col>
          </Row>
        </Col>
        
        }
      </>
    );
  }
}

export default Login;

import React from "react";
class ContestHeader2 extends React.Component {
  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
          style={{
            minHeight: "450px",
            backgroundImage:
              "url(" + require("../../assets/img/greenpuja/kali_puja.jpeg") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }} 
        >   
        
        </div>
      </>
    );
  }
}

export default ContestHeader2;

// import qs from 'querystring';
// import axios from "axios";
import {BASE_URL} from '../config/config';
import qs from 'querystring'

var DEBUG_MODE = '';
// DEBUG_MODE is used to notify the API server that the test data stored in database does not get leaked in production environment while it works in development environment.
// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//     // dev code
//     DEBUG_MODE = `&debug=1`
// }
const getUnitDetails = (url, id) => {
    let parameter = "?unit_id="+id;
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url +parameter, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
          // params: qs.stringify(paramData)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });
    });
}

const getUnitList = (url, paramData) => {
    let parameter="?per_page="+paramData.per_page+"&page_no="+paramData.page_no+"&status="+paramData.status;
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url +parameter+DEBUG_MODE , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
          // params: qs.stringify(paramData)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });
    });
}

const searchUnitList = (url, paramData, searchData) => {
    let parameter="?per_page="+paramData.per_page+"&page_no="+paramData.page_no+"&status="+paramData.status;
    let searchParams = `${!!searchData.name && searchData.name!==''?'&name='+searchData.name:''}${!!searchData.district&&searchData.district.length>0?'&district='+searchData.district:''}${!!searchData.type?'&type='+searchData.type:''}`
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url +parameter+searchParams+DEBUG_MODE , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
          // params: qs.stringify(paramData)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });
    });
}

//scraped, to be removed
const getVerificationList = (url, paramData) => {
    let parameter="?per_page="+paramData.per_page+"&page_no="+paramData.page_no+"&status="+paramData.status;
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url +parameter+DEBUG_MODE , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
          // params: qs.stringify(paramData)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });
    });
}

const updateUnitStatus = (url, status, uid) => {
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url , {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
            body: qs.stringify({status: status, unit_id: uid})
          // params: qs.stringify(paramData)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });
    });
}

const updateUnitPaymentStatus = (url, status, uid, remarks) => {
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url , {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
            body: qs.stringify({payment_status: status, unit_id: uid, remarks: remarks})
          // params: qs.stringify(paramData)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });
    });
}

export default {getUnitList, getVerificationList, searchUnitList, updateUnitStatus, updateUnitPaymentStatus, getUnitDetails};
import React from "react";
import '../../../assets/css/custom.css';
import ImgUser from '../../../assets/img/user.png';
//import {plog} from '../../../config/config';
import {
  FormGroup,
  Form, 
  Input,
  Col,
  Card,
  Container,
  Row,
  Button,
  Alert
} from "reactstrap";
import { Link } from 'react-router-dom';
import GreenPujaApi from '../../../API/greenpujaapi'
import Helper  from '../../../helpers/helper';
import Pagination from "react-js-pagination";
import { withRouter } from 'react-router';
import magnifyIcon from '../../../assets/img/magnify.png';
import loder1 from '../../../assets/loder1.gif';
import  Rating from  'react-rating';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'font-awesome/css/font-awesome.min.css'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

class OrganizerList extends React.Component {
  constructor(){
    super();
    this.state = {
      data:[],
    paramData:{
      per_page:10,
      pageno:1,
      committee_name:''
    },
    actionData:{
      complaint_id:'',
      status:0,
      action_message:'',
    },
    isModalOpen: false,
    fetchInProgress: false,
    activePage: 1,
    totalDataCount:1,
    redirect: false,
    ratingmessage:false,
    errors: {}
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  handlePageChange=(pageNumber) =>{
    this.setState({ activePage: pageNumber });
    this.setState(prevState => ({
      paramData: {...prevState.paramData,pageno:pageNumber}
    }))
    this.loadData();
  }
   seachOrganiser=(e)=>{
     e.preventDefault();
      this.loadData();
    }
  clearSearch=(e)=>{
    this.setState(prevState => ({
      paramData: {...prevState.paramData,committee_name:""}
    }))
    e.preventDefault();
      this.loadData();
  }
  componentWillMount() { 
    this.loadData(); 
  }
  loadData=() =>{ 
    this.setState({ fetchInProgress: true });
      Helper.authenticateToken().then(()=>{
        GreenPujaApi.getOrganizerList("/organiser/all",this.state.paramData).then((result) => { 
          if(result.data===undefined){
            this.setState({data:[],fetchInProgress: false})
         }
         else{
          let totalItem=result.total_data;
          this.setState({data :result.data,totalDataCount:totalItem,fetchInProgress: false});
          this.setState({paramData: { ...this.state.paramData }})
         } 
      })
    });
  }
  onSearchChange=e=>{
      this.setState({
        paramData: { ...this.state.paramData, [e.target.name]: e.target.value }
      })
    }
    onChange = e =>{
      this.setState({
        actionData: { ...this.state.actionData, [e.target.name]: e.target.value }
      })}
    
    validate = (actionData) => {
      const errors = {};
      if (!actionData.action_message) errors.action_message = "Please Give Action Message";
      return errors;
    }
    
    render() {
      const {data,fetchInProgress,paramData}=this.state;
      return (
        <>
         {/*  <ContestHeader /> */}
          {/* Page content */}
          { fetchInProgress ?<div className="progress2 justify-content-center"> <img src={loder1} alt="" className="pad"/></div> :  
          <Container className="contestBody">
            {/* Table */}
            {/* <Row>
              <div className="col-md-12 col-6 text-right pb-2">
                      <button type="button" className="btn btn-primary md" onClick={ () => this.props.history.go(-1)}>Back</button>
              </div>
            </Row> */}
            <Row>
            
            <div className="col-md-12 mb-2">
                <div className="box">
                
                  <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton >
                          <div className="head"><img src={magnifyIcon} className="mr-3" alt=""/> Search...</div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                       <AccordionItemPanel>
                       <Form encType="multipart/form-data">
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <label>Committee name</label>
                              <Input
                          className="form-control-alternative" id="committee_name" placeholder="Committee Name" type="text" name="committee_name" 
                          onChange={this.onSearchChange} value={paramData.committee_name} />
                            </FormGroup>
                          </Col>
                          
                          <Col md="4">
                          </Col>
                          <Col md="4" className="">
                          <Button color="primary" className="pad-t" size="md" onClick={this.seachOrganiser}><i className="fa fa-search"></i> </Button>
                          <Button color="primary" className="pad-t" size="md" onClick={this.clearSearch}>Clear </Button>
                          </Col>
                        </Row>
                       </Form>
                       </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
              <div className="col-md-12 mb-2">
                {(data.length!==0)?
                  data.map((item, index) =>{
                    return(
                      <Card className="shadow table" key={index}>
                        <div className="col-md-12">
                          <div className="card-view">
                            <div className="row">
                              <div className="col-md-3 col-12 text-center p-0">
                               { item.puja_banner==null?
                                <img src={ImgUser} width={100} alt="" className="img-fluid bx-img"/>
                               : <img src={item.images[0]} width={100}  alt="" className="img-fluid bx-img"/>
                               }
                              </div>
                              <div className="col-md-9 col-12 pt-2 pl-3 pr-3 pb-3">
                                <h3>{item.puja_committee_name.toUpperCase()}</h3>
                                <span className="gray">{item.location}</span>
                                <p>{item.president_name}</p>
                                {item.rating!==0?
                                 <p>{item.avg_rating/10}{" "}<Rating emptySymbol="fa fa-star custom custom-full"
                                        fullSymbol="fa fa-star custom custom-full" stop={Math.round(item.avg_rating/10)} readonly>
                                        </Rating>{" "}({item.rating_count})</p>:""
                                }
                                <div className="float-md-right bottom-a">
                                   {/* <span className="badge badge-success" style={ratingmessage ? {} : { display: 'none' }}>Rated Successfull</span> */}
                                
                                <Link to={{pathname: 'show-organizer',state: {organiser_id: item.organiser_id}}}>
                                  <button type="button" className="btn btn-info btn-sm">View</button>
                                </Link>{' '}
                                

                              </div>
                              </div>
                            </div>
                          </div>
                          </div>
                      </Card>)
                  }):<Alert color="warning">
                      No Record Found!
                </Alert>
                
                }
                {data.length!==0?
                    <div className="pag float-right">
                      <Pagination className="pagination pagination-lg"
                                  listClassName="pagination-lg"
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.paramData.per_page}
                                totalItemsCount={this.state.totalDataCount}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange}
                              /> 
                     </div>:''
                }    
              </div>
              
            </Row>
           
          </Container>
          }
        </>
      );
    }
  }

export default withRouter(OrganizerList);



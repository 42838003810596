import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Header from "../../../components/Headers/Header";
import { Container, Card, Col, Input, Row, Button } from "reactstrap";

import Datetime from "react-datetime";

import "./TwitterPost.css";
import moment from "moment";
import Helper from "../../../helpers/helper";
import {
  postTwitter,
  readTwitter,
  deteleTwitter,
  postUpdateTwitter,
} from "../../../API/TwitterPost";
import toast, { Toaster } from "react-hot-toast";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
const TwitterPost = () => {
  const [formValues, setFormValues] = useState({
    description: "",
    images: [],
    publish_datetime: "",
  });
  const [twitte, setTwitte] = useState([]);
  const [scheduledPost, setScheduledPost] = useState([]);
  const [isEditObject, setIsEditObject] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    setFormValues((prev) => ({ ...prev, images: acceptedFiles }));
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const buttonStyle = {
    marginTop: "20px", // Adjust the margin-top value as needed
    marginBottom: "40px",
  };

  const handleTwitterPost = () => {
    Helper.authenticateToken().then(() => {
      postTwitter({
        url: "/tweet/create",
        values: formValues,
        numberOfImage: formValues?.images?.length,
      }).then((result) => {
        if (result?.status === 200) {
          getAllTwitte();
          setFormValues({
            description: "",
            images: [],
            publish_datetime: "",
          });
          toast.success(result?.data?.error_description || "Succeed");
        } else {
          toast.error(result?.data?.error_description || "Fail to post!");
        }
      });
    });
  };

  const handleUpdateTwitterPost = () => {
    Helper.authenticateToken().then(() => {
      postUpdateTwitter({
        url: `/tweet/update?tweet_id=${isEditObject?.id}`,
        values: formValues,
        numberOfImage: formValues?.images?.length,
      }).then((result) => {
        if (result?.status === 200) {
          getAllTwitte();
          setFormValues({
            description: "",
            images: [],
            publish_datetime: "",
          });
          setIsEditObject(null);
          getAllScheduledTwitte();
          toast.success(result?.data?.error_description || "Succeed");
        } else {
          toast.error(result?.data?.error_description || "Fail to post!");
        }
      });
    });
  };

  const getAllScheduledTwitte = () => {
    Helper.authenticateToken().then(() => {
      readTwitter("/tweet/admin/all?page=1&per_page=50&is_scheduled=true").then(
        (result) => {
          if (result.status === "success") {
            setScheduledPost(result.data);
          } else {
            console.log("failed to fetch");
          }
        }
      );
    });
  };
  const getAllTwitte = () => {
    Helper.authenticateToken().then(() => {
      readTwitter("/tweet/all?page=1&per_page=50").then((result) => {
        if (result.status === "success") {
          setTwitte(result.data);
        } else {
          console.log("failed to fetch");
        }
      });
    });
  };

  const deleetTwitteHandler = (id) => {
    Helper.authenticateToken().then(() => {
      deteleTwitter(`/tweet/delete?tweet_id=${id}`).then((result) => {
        if (result.status === "success") {
          getAllTwitte();
          getAllScheduledTwitte();
          toast.success(result?.data?.error_description || "Succeed");
        } else {
          console.log("failed to fetch");
        }
      });
    });
  };

  useEffect(() => {
    getAllTwitte();
    getAllScheduledTwitte();
  }, []);

  const gridTemplate = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{ cursor: "pointer", marginRight: "20px" }}
          onClick={() => {
            setIsEditObject(props);
            setFormValues({
              description: props?.description,
              publish_datetime: props?.publish_datetime,
              images: [],
            });
          }}
        >
          <i class="fa-solid fa-edit"></i>
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            deleetTwitteHandler(props?.id);
          }}
        >
          <i class="fa-solid fa-trash"></i>
        </div>
      </div>
    );
  };
  const scdhulePostTemplate = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{ cursor: "pointer", marginRight: "20px" }}
          onClick={() => {
            setIsEditObject(props);
            setFormValues({
              description: props?.description,
              publish_datetime: props?.publish_datetime,
              images: [],
            });
          }}
        >
          <i class="fa-solid fa-edit"></i>
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            deleetTwitteHandler(props?.id);
          }}
        >
          <i class="fa-solid fa-trash"></i>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Toaster />
      <Header />

      <Container className="mt--7 " fluid>
        <Row className="justify-content-md-center">
          <Col xs lg="2"></Col>
          <Col xs="auto" lg="4">
            <Card>
              <div
                style={{
                  width: "12rem",
                  height: "12rem",
                  borderRadius: 10,
                  alignSelf: "center",
                  border: "2px dashed #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#7fbb3d",
                }}
                {...getRootProps()}
              >
                {isEditObject === null && formValues.images.length === 0 && (
                  <i
                    class="fa fa-arrow-circle-up"
                    style={{ fontSize: "2rem" }}
                    aria-hidden="true"
                  ></i>
                )}
                <div className="grid grid-cols-2 ">
                  {formValues?.images.length > 0 ? (
                    formValues?.images.map((item) => (
                      <img
                        src={URL.createObjectURL(item)}
                        style={{
                          width: "80px",
                          height: "80px",
                          margin: "2px",
                          borderRadius: 4,
                        }}
                      />
                    ))
                  ) : isEditObject !== null ? (
                    isEditObject.image_paths?.map((item) => (
                      <img
                        src={item}
                        style={{
                          width: "80px",
                          height: "80px",
                          margin: "2px",
                          borderRadius: 4,
                        }}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </div>
                <input {...getInputProps()} />
              </div>
            </Card>
            <Card style={{ top: "1rem" }}>
              <h1 className="text-sm text-center">Description</h1>
              <Input
                type="textarea"
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
                value={formValues.description}
              />
            </Card>
            <Card style={{ top: "2rem" }}>
              <h1 className="text-sm text-center">Schedule Time</h1>
              <div className="w-full">
                <Datetime
                  className="w-full"
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      publish_datetime: moment(
                        moment(e?._d).format("ddd MMM DD YYYY HH:mm:ss"),
                        "ddd MMM DD YYYY HH:mm:ss"
                      ).format("YYYY-MM-DDTHH:mm:ssZ"),
                    }))
                  }
                  value={moment(
                    formValues?.publish_datetime || new Date()
                  ).format("MM/DD/YYYY hh:mm A")}
                />
              </div>
              <Button
                color="primary"
                style={buttonStyle}
                onClick={
                  isEditObject ? handleUpdateTwitterPost : handleTwitterPost
                }
              >
                {isEditObject ? "Update" : "Submit"}
              </Button>
            </Card>
          </Col>
          <Col xs lg="2"></Col>
        </Row>
        <Row>
          <h1>Scheduled Posts</h1>
          <div style={{ height: 300, overflow: "hidden", overflowY: "scroll" }}>
            <GridComponent dataSource={scheduledPost}>
              <ColumnsDirective>
                <ColumnDirective
                  field="id"
                  width="80"
                  textAlign="Right"
                  headerText="ID"
                />
                <ColumnDirective
                  field="description"
                  width="150"
                  textAlign="Center"
                  headerText="Description"
                />
                <ColumnDirective
                  field="image_paths"
                  width="200"
                  textAlign="Center"
                  headerText="Image Path"
                />
                <ColumnDirective
                  field="publish_datetime"
                  width="100"
                  textAlign="Center"
                  headerText="Publish & Datetime"
                  template={(args) => (
                    <div>
                      {moment(args?.publish_datetime).format("DD-MM-YYYY")}
                      &nbsp;
                      {moment(args?.publish_datetime).format("HH:MM:SS")}
                    </div>
                  )}
                />
                <ColumnDirective
                  field="Active"
                  width="100"
                  textAlign="Center"
                  headerText="Action"
                  template={scdhulePostTemplate}
                />
              </ColumnsDirective>
            </GridComponent>
          </div>
        </Row>
        <Row>
          <h1>Recent Posts</h1>
          <div style={{ height: 300, overflow: "hidden", overflowY: "scroll" }}>
            <GridComponent dataSource={twitte}>
              <ColumnsDirective>
                <ColumnDirective
                  field="id"
                  width="80"
                  textAlign="Right"
                  headerText="ID"
                />
                <ColumnDirective
                  field="description"
                  width="150"
                  textAlign="Center"
                  headerText="Description"
                />
                <ColumnDirective
                  field="image_paths"
                  width="200"
                  textAlign="Center"
                  headerText="Image Path"
                />
                <ColumnDirective
                  field="publish_datetime"
                  width="100"
                  textAlign="Center"
                  headerText="Publish & Datetime"
                  template={(args) => (
                    <div>
                      {moment(args?.publish_datetime).format("DD-MM-YYYY")}
                      &nbsp;
                      {moment(args?.publish_datetime).format("HH:MM:SS")}
                    </div>
                  )}
                />
                <ColumnDirective
                  field="Active"
                  width="100"
                  textAlign="Center"
                  headerText="Action"
                  template={gridTemplate}
                />
              </ColumnsDirective>
            </GridComponent>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default TwitterPost;

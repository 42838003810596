import React, { Component } from "react";
import Header from "../../../components/Headers/Header";
import Helper from "../../../helpers/helper";
import BouquetApi from "../../../API/bouquetapi";
import { Redirect, Link } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./ShowTree.css";
import Loader from "../../../components/Spinner/spinner";
//import GoogleMap from '../../../components/GoogleMap/index';
import GoogleMapReact from "google-map-react";
import moment from "moment";
import {
  Container,
  Button,
  Card,
  Col,
  CardHeader,
  CardBody,
  Row,
  Table,
  Badge
} from "reactstrap";
import Slider from "react-animated-slider";

const APPROVE = 1;
const REJECT = 2;

export default class ShowTree extends Component {
  constructor() {
    super();
    this.state = {
      photosfetchInProgress: false,
      photos: [],
      volunteers: [],
      lightBoxOpen: false,
      lightBoxImages: [],
      lightBoxIndex: 0,
      volunteersfetchInProgress: false,
      PhotoUpdateIDfetchInProgress: -1,
      PhotoUpdateValuefetchInProgress: 0,
      data: {},
      volunteersListExpanded: false,
    };
    this.openLightbox = this.openLightbox.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.moveNext = this.moveNext.bind(this);
    this.movePrev = this.movePrev.bind(this);
    this.refreshPageData = this.refreshPageData.bind(this);
    this.updateTreeProgressStatus = this.updateTreeProgressStatus.bind(this);
    this.toggleVolunteersList = this.toggleVolunteersList.bind(this);
  }
  static onImageLoadError(imageSrc, _srcType, errorEvent) {
    console.error(`Could not load image at ${imageSrc}`, errorEvent); // eslint-disable-line no-console
  }
  updateTreeProgressStatus = (id, status) => {
    this.setState({
      PhotoUpdateIDfetchInProgress: id,
      PhotoUpdateValuefetchInProgress: status,
    });
    Helper.authenticateToken().then(() => {
      BouquetApi.approveVolunteerUploadedPhotos(
        "/bouquet/approve_tree_photo",
        status,
        id
      ).then((result) => {
        if (result.status === "success") {
          console.log("updated successfully");
          this.setState({
            PhotoUpdateIDfetchInProgress: -1,
            PhotoUpdateValuefetchInProgress: 0,
          });
          this.refreshPageData(this.props.location.state.tree.id || this.props.location.state.tree_id);
        } else {
          console.log("failed to update");
          this.setState({
            PhotoUpdateIDfetchInProgress: -1,
            PhotoUpdateValuefetchInProgress: 0,
          });
        }
      });
    });
  };
  getTreeVolunteerAssignments = (id) => {
    this.setState({ volunteersfetchInProgress: true });
    Helper.authenticateToken().then(() => {
      BouquetApi.getTreePhotos(
        "/volunteer/get_tree_volunteer_assignments",
        id
      ).then((result) => {
        if (result.status === "success") {
          console.log("volunteer fetched successfully");
          console.log(result.data);
          this.setState({
            volunteers: result.data,
            volunteersfetchInProgress: false,
          });
        } else {
          console.log("failed to fetch");
          this.setState({ volunteersfetchInProgress: false });
        }
      });
    });
  };

  refreshPageData = (id) => {
    // this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      BouquetApi.getTreeDetails(
        "/bouquet/get_tree_details",
        id
      ).then((result) => {
        if (result.status === "success") {
          // let totalItem = result.total_data;
          // this.setState({ totalDataCount: totalItem });
          this.setState({ photos: result.data.volunteer_uploaded_images });
          console.log(this.state.data);
        } else {
          console.log("failed to update tree details");
          // this.setState({
          //   errorMsg: "Error! Please try after some time.",
          //   fetchInProgress: false,
          // });
        }
      });
    });
  };

  getStatusText(status) {
    switch (status) {
      case 1:
        return { text: "Requested Adoption", color: "info" };
      case 2:
        return { text: "Adopted", color: "success" };
      case 3:
        return { text: "Requested Resignation", color: "dark" };
      case 4:
        return { text: "Resigned", color: "danger" };
      default:
        return { text: "¯\\＿(ツ)＿/¯", color: 'primary'};
    }
  }


  componentDidMount() {
    this.getTreeVolunteerAssignments(this.props.location.state.tree.id);
    // if(this.props && this.props.location && this.props.location.state && this.props.location.state.tree && this.props.location.state.tree.volunteer_uploaded_images) {
    //   this.setState({photos: this.props.location.state.tree.volunteer_uploaded_images.map(image => image.images).reduce((prev, curr) => [...prev, ...curr], [])})
    // }
    this.setState({ data: this.props.location.state.tree });
    this.setState({
      photos: this.props.location.state.tree.volunteer_uploaded_images,
    });
    if(!this.props.location.state.tree) this.refreshPageData(this.props.location.state.tree_id)
  }
  openLightbox(images) {
    console.log(images);
    this.setState({ lightBoxOpen: true, lightBoxImages: images });
  }

  closeLightbox() {
    this.setState({ lightBoxOpen: false, lightBoxImages: [] });
  }

  moveNext() {
    this.setState((prevState) => ({
      lightBoxIndex:
        (prevState.lightBoxIndex + 1) % this.state.lightBoxImages.length,
    }));
  }

  movePrev() {
    this.setState((prevState) => ({
      lightBoxIndex:
        (prevState.lightBoxIndex + this.state.lightBoxImages.length - 1) %
        this.state.lightBoxImages.length,
    }));
  }

  toggleVolunteersList() {
    let volunteersListExpanded = !this.state.volunteersListExpanded;
    console.log('reaching here')

    this.setState({volunteersListExpanded: volunteersListExpanded})
  }

  render() {
    console.log(this.state);
    let lightbox;
    if (this.state.lightBoxOpen) {
      lightbox = (
        <Lightbox
          mainSrc={this.state.lightBoxImages[this.state.lightBoxIndex]}
          nextSrc={
            this.state.lightBoxImages[
              (this.state.lightBoxIndex + 1) % this.state.lightBoxImages.length
            ]
          }
          prevSrc={
            this.state.lightBoxImages[
              (this.state.lightBoxIndex +
                this.state.lightBoxImages.length -
                1) %
                this.state.lightBoxImages.length
            ]
          }
          // mainSrcThumbnail={thumbs[this.state.index]}
          // nextSrcThumbnail={thumbs[(this.state.index + 1) % this.state.lightBoxImages.length]}
          // prevSrcThumbnail={
          //   thumbs[(this.state.index + this.state.lightBoxImages.length - 1) % this.state.lightBoxImages.length]
          // }
          onCloseRequest={this.closeLightbox}
          onMovePrevRequest={this.movePrev}
          onMoveNextRequest={this.moveNext}
          onImageLoadError={ShowTree.onImageLoadError}
          // imageTitle={titles[this.state.index]}
          // imageCaption={captions[this.state.index]}
        />
      );
    }
    const { tree } = this.props.location.state;
    if (typeof tree === "undefined") {
      return <Redirect to={"/admin/bouquet"} />;
    }
    const items = [];
    if (
      tree.admin_uploaded_images != null &&
      tree.admin_uploaded_images.length > 0
    ) {
      for (let i = 0; i < tree.admin_uploaded_images.length; i++) {
        items.push(
          <div
            style={{
              background: `url('${tree.admin_uploaded_images[
                i
              ].trim()}') no-repeat center`,
              backgroundSize: "cover",
            }}
            key={i}
            alt="preview"
            name={tree.admin_uploaded_images[i]}
          ></div>
        );
      }
    } else {
      items.push(
        <div
          style={{
            background: `url('${require("../../../assets/img/noimage.png")}') no-repeat center`,
            backgroundSize: "cover",
          }}
          key={0}
          alt="preview"
        ></div>
      );
    }
    const pendingItems = [];
    if (tree.images != null && tree.images.length > 0) {
      for (let i = 0; i < tree.images.length; i++) {
        pendingItems.push(
          <div
            style={{
              background: `url('${tree.images[i].trim()}') no-repeat center`,
              backgroundSize: "cover",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
            key={i}
            alt="preview"
            name={tree.images[i]}
          >
            <Button className="btn btn-success shadow btn-sm ml-2 mr-2">
              Approve
            </Button>
            <Button className="btn btn-danger shadow btn-sm ml-2 mr-2">
              Reject
            </Button>
          </div>
        );
      }
    } else {
      pendingItems.push(
        <div
          style={{
            background: `url('${require("../../../assets/img/noimage.png")}') no-repeat center`,
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
          key={0}
          alt="preview"
        >
          <h2>No pending upload available</h2>
        </div>
      );
    }
    console.log(tree);
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <Col>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-2">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h1 className="mb-0">Tree Details</h1>
                    </Col>
                    <Col xs="4" className="text-right">
                      {/* <Link
                        to={{
                          pathname: "bouquet",
                          state: { page_no: page_no },
                        }}
                      >
                        
                      </Link> */}
                      <Button
                          className="btn btn-sm"
                          color="primary"
                          type="button"
                          onClick={this.props.history.goBack}
                        >
                          <span>Back</span>
                        </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row className="pt-2">
                    <Col md={12} lg={6}>
                      <div className="TreeDetails">
                        <b>Name: &nbsp;</b> {tree.name}
                      </div>
                    </Col>
                    <Col md={12} lg={6}>
                      <div className="TreeDetails">
                        <b>Variety: &nbsp;</b> {tree.variety}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col xs={12} xl={12}>
                      <div className="TreeDetails">
                        <b>Location: &nbsp;</b> {tree.location}
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      md={12}
                      lg={6}
                      style={{
                        border: "1px solid aliceblue",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className=" rounded shadow-lg pt-2"
                    >
                      <h2>Geographical Location</h2>
                      <div
                        style={{
                          flexGrow: 1,
                          padding: 10,
                          maxHeight: 400,
                          minHeight: 300,
                        }}
                      >
                        <GoogleMapReact
                          bootstrapURLKeys={{
                            key: "AIzaSyBJ-RKwTIsTWX_4eKsBN0bBUBBx8dP1-Ls",
                          }}
                          defaultCenter={{
                            lat: parseFloat(tree.lat),
                            lng: parseFloat(tree.long),
                          }}
                          defaultZoom={12}
                        >
                          <i
                            className="fas fa-tree"
                            style={{ fontSize: 20, color: "green" }}
                            lat={parseFloat(tree.lat)}
                            lng={parseFloat(tree.long)}
                          ></i>
                        </GoogleMapReact>
                      </div>
                    </Col>
                    <Col
                      md={12}
                      lg={6}
                      style={{ border: "1px solid aliceblue" }}
                      className=" rounded shadow-lg pt-2"
                    >
                      <h2>Tree Photos</h2>
                      <Slider autoplay={3000}>{items}</Slider>
                    </Col>
                  </Row>

                  <hr />
                  <Row className="pt-2">
                    <Col md={12} lg={12}>
                      <Table>
                        <thead>
                        <tr>
                          <th colSpan={2}>
                            <h2>Volunteer's Assigned</h2>
                          </th>
                        </tr>
                        <tr>
                          <th>Volunteer</th>
                          <th>Contact Number</th>
                          <th>Assigned On</th>
                          <th>Current Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {/* Volunteer Data Map */}
                        {/* <tr>
                          <td>Vounteer 1</td>
                          <td>Yesterday</td>
                        </tr> */}
                        {this.state.volunteers &&
                        this.state.volunteers.length > 0
                          ?(this.state.volunteersListExpanded ? (this.state.volunteers.map((volunteer, index) => (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to={{
                                      pathname: 'volunteer-details', state: { vol_id: volunteer["User.id"] } 
                                    }}
                                  >
                                    {volunteer["User.name"]}
                                  </Link>
                                </td>
                                <td>{volunteer["User.mobile_no"]}</td>
                                <td>
                                  {moment(volunteer.assignment_date).format(
                                    "LLL"
                                  )}
                                </td>
                                <td><Badge
                                      color={
                                        this.getStatusText(volunteer.status).color
                                      }
                                    >
                                      {this.getStatusText(volunteer.status).text}
                                    </Badge></td>
                              </tr>
                            )))
                            :
                            (this.state.volunteers.slice(0,9).map((volunteer, index) => (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to={{
                                      pathname: 'volunteer-details', 
                                      state: { vol_id: volunteer["User.id"] }
                                    }}
                                  >
                                    {volunteer["User.name"]}
                                  </Link>
                                </td>
                                <td>{volunteer["User.mobile_no"]}</td>
                                <td>
                                  {moment(volunteer.assignment_date).format(
                                    "LLL"
                                  )}
                                </td>
                                <td><Badge
                                      color={
                                        this.getStatusText(volunteer.status).color
                                      }
                                    >
                                      {this.getStatusText(volunteer.status).text}
                                    </Badge></td>
                              </tr>
                            ))))
                          : null}
                          <tr>
                            <td colSpan={4}>
                              <Button className="btn btn-light btn-block" onClick={() => this.toggleVolunteersList()}>{this.state.volunteersListExpanded?"Show Few Details":"Show Full Details"}</Button>
                            </td>
                          </tr>
                          </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="pt-2"
                    >
                      <h2
                        style={{
                          textAlign: "left",
                          marginLeft: 16,
                        }}
                      >
                        Tree Progress History
                      </h2>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <VerticalTimeline
                        layout="2-columns"
                        className="TimeLineContainer"
                      >
                        {this.state.photos && this.state.photos.length > 0
                          ? this.state.photos.map((photo, index) => (
                              <VerticalTimelineElement
                                key={index}
                                className="vertical-timeline-element--work"
                                contentStyle={{
                                  background: "rgb(33, 150, 243)",
                                  color: "#fff",
                                }}
                                contentArrowStyle={{
                                  borderRight: "7px solid #8bc43f",
                                }}
                                date={moment(photo.updated_at).format("LLL")}
                                dateClassName="TimeLineDate"
                                textClassName={
                                  index % 2 === 0
                                    ? "TimeLineTextContainerBackgroundEven"
                                    : "TimeLineTextContainerBackgroundOdd"
                                }
                                iconStyle={{
                                  backgroundColor: " #005f2e",
                                  backgroundImage:
                                    photo.images && photo.images.length > 0
                                      ? `url(${photo.images[0]})`
                                      : "linear-gradient(90deg, #8bc43f 0, #005f2e 100%) !important",
                                  color: "#fff",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                                icon={
                                  photo.images && photo.images.length > 0 ? (
                                    <i
                                      className="far fa-images"
                                      style={{ color: "#ffffff88" }}
                                    ></i>
                                  ) : (
                                    <i className="fas fa-exclamation-triangle"></i>
                                  )
                                }
                                iconOnClick={
                                  photo.images && photo.images.length > 0
                                    ? () => this.openLightbox(photo.images)
                                    : null
                                }
                              >
                                <Row>
                                  <Col>
                                    <p>{photo.comment}</p>
                                  </Col>
                                  {console.log("Photo Status: ", photo.status)}
                                  {photo.status === 0 ? (
                                    <Col
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                      }}
                                    >
                                      {photo.photo_group_id ===
                                      this.state
                                        .PhotoUpdateIDfetchInProgress ? (
                                        // <Spinner
                                        //   color={
                                        //     this.state
                                        //       .PhotoUpdateValuefetchInProgress ===
                                        //     1
                                        //       ? "success"
                                        //       : "danger"
                                        //   }
                                        // ></Spinner>
                                        <img src={Loader} alt=""  />
                                      ) : (
                                        <>
                                          <Button
                                            className="btn btn-danger btn-sm"
                                            onClick={() =>
                                              this.updateTreeProgressStatus(
                                                photo.photo_group_id,
                                                REJECT
                                              )
                                            }
                                          >
                                            <i
                                              class="fas fa-times"
                                              style={{ fontSize: 18 }}
                                            ></i>
                                          </Button>
                                          <Button
                                            className="btn btn-success btn-sm"
                                            onClick={() =>
                                              this.updateTreeProgressStatus(
                                                photo.photo_group_id,
                                                APPROVE
                                              )
                                            }
                                          >
                                            <i
                                              class="far fa-check-circle"
                                              style={{ fontSize: 18 }}
                                            ></i>
                                          </Button>
                                        </>
                                      )}
                                    </Col>
                                  ) : null}
                                </Row>

                                {/* <span style={{ color: 'rgba(255,255,255,0.5)'}}>{moment(photo.updated_at).format("LLL")}</span> */}
                              </VerticalTimelineElement>
                            ))
                          : null}
                      </VerticalTimeline>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {lightbox}
      </>
    );
  }
}

import React, { Fragment } from "react";
// reactstrap components
import '../../../assets/css/custom.css';
import ImgUser from '../../../assets/img/user.png';
import badgeImage from '../../../assets/img/badges.png';
//import {plog} from '../../../config/config';
import {
  Card,
  Container,
  Row,
  Alert
} from "reactstrap";
import GreenPujaApi from '../../../API/greenpujaapi'
import Helper  from '../../../helpers/helper';
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import loder1 from '../../../assets/loder1.gif';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'font-awesome/css/font-awesome.min.css'

class TopContestUsers extends React.Component {
  _isMounted = false;
  constructor(){
    super();
    this.state = {
      data:[],
    paramData:{
      per_page:5,
      pageno:1,
      committee_name:'',
      contest_id:1
    },
    actionData:{
      complaint_id:'',
      status:0,
      action_message:'',
    },
    isModalOpen: false,
    fetchInProgress: false,
    activePage: 1,
    totalDataCount:1,
    redirect: false,
    ratingmessage:false,
    errors: {}
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  handlePageChange=(pageNumber) =>{
    this.setState({ activePage: pageNumber });
    this.setState(prevState => ({
      paramData: {...prevState.paramData,pageno:pageNumber}
    }))
    this.loadData();
  }
   seachOrganiser=(e)=>{
     e.preventDefault();
      this.loadData();
    }
  clearSearch=(e)=>{
    this.setState(prevState => ({
      paramData: {...prevState.paramData,committee_name:""}
    }))
    e.preventDefault();
      this.loadData();
  }
  componentDidMount() { 
    this._isMounted = true;
    this.loadData(); 
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  loadData=() =>{ 
    this.setState({ fetchInProgress: true });
      Helper.authenticateToken().then(()=>{
        GreenPujaApi.getTopContestUserList("/contest/top-users",this.state.paramData).then((result) => { 
          
          if (this._isMounted) {
          if(result.data===undefined){
            this.setState({data:[],fetchInProgress: false})
         }
         else{
          let totalItem=result.total_data;
          this.setState({data :result.data,totalDataCount:totalItem,fetchInProgress: false});
          this.setState({paramData: { ...this.state.paramData }})
         } 
        }
      })
    });
  }
  onSearchChange=e=>{
      this.setState({
        paramData: { ...this.state.paramData, [e.target.name]: e.target.value }
      })
    }
    onChange = e =>{
      this.setState({
        actionData: { ...this.state.actionData, [e.target.name]: e.target.value }
      })}
    
    validate = (actionData) => {
      const errors = {};
      if (!actionData.action_message) errors.action_message = "Please Give Action Message";
      return errors;
    }
    
    render() {
      const {data,fetchInProgress,paramData}=this.state;
      return (
        <Fragment>
          { fetchInProgress ?<div className="progress2 justify-content-center"> <img src={loder1} alt="" className="pad"/></div> :  
          <Container className="contestBody">
            {/* Table */}
            <Row>
              <div className="col">
                {(data.length!==0)?
                  data.map((item, index) =>{
                    return(
                      <Card className="shadow table" key={index} style={{ marginBottom: "10px" }} >
                        <Link to={{ pathname: 'user-details', state: { userid: item.user_id } }} >
                        <div className="col-md-12">
                          <div className="contest-card-view">
                            <div className="row">
                              <div className="col-md-3 col-12 text-center p-0">
                               { item.profile_image==null?
                                <img src={ImgUser} width={100} alt="" className="img-fluid  contest-bx-img"/>
                               : <img src={item.images[0]} width={100}  alt="" className="img-fluid  contest-bx-img"/>
                               }
                              </div>
                              <div className="col-md-7 col-12 pt-2 pl-3 pr-3 pb-3">
                                <span>{item.name.toUpperCase()}</span><br></br>
                                <span className="gray">{item.mobile_no}</span>
                              </div>
                              <div className="col-md-2 col-12 pt-3 pr-2 pl-0 pb-3">
                                  <div className="box1">
                                    <img src={badgeImage} width={40} alt="Flying Kites"/>
                                      <div className="textcenter">
                                          <h4 style={{color:"#c70d3a"}}>{ (paramData.per_page*(paramData.pageno-1)+index)+1}</h4>
                                      </div>
                                  </div>
                              </div> 
                            </div>
                          </div>
                          </div>
                        </Link>
                      </Card>)
                  }):<Alert color="warning">
                      No Record Found!
                </Alert>
                
                }
                {data!==0?
                    <div className="pag float-right">
                      <Pagination className="pagination pagination-lg"
                                  listClassName="pagination-lg"
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.paramData.per_page}
                                totalItemsCount={this.state.totalDataCount}
                                pageRangeDisplayed={2}
                                onChange={this.handlePageChange}
                              /> 
                     </div>:''
                }    
              </div>
              
            </Row>
           
          </Container>
          }
        </Fragment>
      );
    }
  }

export default TopContestUsers;



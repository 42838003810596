import React from "react";
import ReactExport from "react-export-excel";
import helper from "../../../helpers/helper";
import ComplaintApi from "../../../API/complainapi";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



class DownloadComplaintExcel extends React.Component {
    _isMounted = false;
    constructor(){
        super();
        this.state = {
          user:[],

        paramData:{
          per_page:100,
          pageno:1,
          categories:"14,15,16"
        },
        fetchInProgress: false,
        redirect: false,
        errors: {}
        };
      }
      componentDidMount() { 
        this._isMounted = true;
       // this.loadData(); 
      }
      componentWillUnmount() {
        this._isMounted = false;
      }
      
      
    loadData=() =>{ 
       
      this._isMounted = true;
        this.setState({ fetchInProgress: true });
          helper.authenticateToken().then(()=>{
            ComplaintApi.getUserwiseCategory("/complaint/count-by-category",this.state.paramData).then((result) => { 
                if (this._isMounted) {
                if(result.data===undefined){
                  this.setState({topUsers:[],fetchInProgress: false})
               }
               else{
                 
                this.setState({user :result.data,fetchInProgress: false});
                console.log('data-',this.state.user);
               } 
            }
              
            })
        });
      }



    render() {
        const {user}=this.state;
        return (
            
            <>
             
            <ExcelFile element={<button className="btn btn-success" onClick={this.loadData}><i className="fas fa-file-excel-o text-green"></i>Download Excel</button>}>
                <ExcelSheet data={user} name="Top_Users">
                    <ExcelColumn label="Name" value="name"/>
                    <ExcelColumn label="Mobile Number" value="mobile_no"/>
                    <ExcelColumn label="UPI Id" value="upi_id"/>
                    <ExcelColumn label="Total" value="total"/>
                    <ExcelColumn label="Email" value="email"/>
                </ExcelSheet>

            </ExcelFile>
            </>
        );
    }
}
 export default DownloadComplaintExcel
import axios from "axios";
import {BASE_URL, plog} from '../config/config';
import qs from 'querystring'

const getTreeList = (url, paramData) => {
    let parameter="?per_page="+paramData.per_page+"&page_no="+paramData.page_no;
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url +parameter , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
          // params: qs.stringify(paramData)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });
    });
}

const addNewTree = (url,paramData) => {
    plog("result=",paramData);
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        let formData = new FormData();
        var key = null;
        for(key in paramData) {
            formData.append( key, paramData[key] );
        }
        for(key in paramData.images){  
            formData.append( 'images', paramData.images[key] );
        } 
        console.log(formData['images']);
        axios.post(BASE_URL + url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'authorization': 'Bearer ' + accesstoken
            }})
            .then((response) => { return resolve(response) })
            .catch((error) => {
                reject(error);
            }); 
    });

    
}

const approveVolunteerUploadedPhotos = (url, status, uid) => {
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url , {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
            body: qs.stringify({status: status, tree_photo_id: uid})
          // params: qs.stringify(paramData)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });
    });
}

// const addNewTree = (url, paramData) => {
//     let parameters = new URLSearchParams();
//     parameters.append('name', String(paramData.name));
//     parameters.append('variety', String(paramData.variety));
//     parameters.append('location',String(paramData.location));
//     parameters.append('price', String(paramData.price));
//     parameters.append('lat', String(paramData.lat))
//     parameters.append('long', String(paramData.long))
//     parameters.append('status', String(paramData.status))
//     return new Promise((resolve, reject) => {
//         let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
//         fetch(BASE_URL + url  , {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/x-www-form-urlencoded',
//                 'authorization': 'Bearer ' + accesstoken
//             },
//             //body: parameters,
//             redirect: 'follow',
//            body: qs.stringify(paramData)
//         })
//             .then((response) => {  resolve(response.json()) })
//             .catch((error) => {
//                 reject(error);
//             });
//     });
// }

const getTreePhotos = (url, id) => {
    let parameter="?tree_id="+id;
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url +parameter , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
          // params: qs.stringify(paramData)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });
    });
}

const getTreeDetails = (url, id) => {
    let parameter="?tree_id="+id;
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url +parameter , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
          // params: qs.stringify(paramData)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });
    });
}

export default {getTreeList, getTreePhotos, addNewTree, approveVolunteerUploadedPhotos, getTreeDetails}
import React from "react";
import '../../../assets/css/custom.css';
// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Container,
  Alert,
  Button
} from "reactstrap";
//import ReactDatetime from "react-datetime";
import { Redirect } from 'react-router-dom';
// core components
import Header from "../../../components/Headers/Header.jsx";
import InlineError from '../../../components/messages/InlineError';
import NewsApi from '../../../API/newsapi';
import Helper  from '../../../helpers/helper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {plog} from '../../../config/config'
import Loader from '../../../components/Spinner/spinner';
import moment from 'moment';
import { Link } from 'react-router-dom';

class NewsEdit extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {
        news_id:'',
        headline: '',
        description: '',
        event_venue: '',
        news_banner:'',
        news_from: moment(new Date()).format('YYYY-MM-DD'),
        news_till: moment(new Date()).format('YYYY-MM-DD'),
        status: 0,
        category: 1,
        images:{}
      },
      
      news_from:new Date(),
      news_till:new Date(),
      showQuotes:true,
      visibleSuccess: false,
      visibleError: false,
      successmsg: {},
      fetchInProgress: false,
      redirect: false,
      imageLink:{},
      errors: {}
    };
    this.addNews = this.updateNews.bind(this);
    this.onChange = this.onChange.bind(this);
    this.changeFromDate = this.changeFromDate.bind(this);
    this.changeTillDate = this.changeTillDate.bind(this);
    this.changeCategory=this.changeCategory.bind(this);
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    
  }
  changeCategory=e=>{
    //4 means quates
  (e.target.value==="4")?this.setState({showQuotes:false,data: { ...this.state.data, category: e.target.value,event_venue:'-',description:'-' }}):this.setState({showQuotes:true,data: { ...this.state.data, category: e.target.value,event_venue:'',description:'' }});
  }
  changeFromDate = date =>{
    let newdate=moment(date).format('YYYY-MM-DD');
    this.setState({
      data: { ...this.state.data, news_from:newdate },
      news_from:date
    });
  } 
  changeTillDate = date =>{
    let newdate=moment(date).format('YYYY-MM-DD');
    this.setState({
      data: { ...this.state.data, news_till:newdate },
      news_till:date
    });
  }
  componentDidMount() { 
    const { news_id } = this.props.location.state;
    plog("News id--",news_id);
    this.setState({ fetchInProgress: true });
     Helper.authenticateToken().then(()=>{
      NewsApi.getNewsDetails("/news/news-details",news_id).then((result) => {
        this.setState({ fetchInProgress: false });
        let newdata={
          news_id:result.data.news_id,
          headline:result.data.headline,
          description:result.data.description,
          event_venue:result.data.event_venue,
          news_banner:result.data.news_banner,
          news_from:result.data.news_from,
          news_till:result.data.news_till,
          status:result.data.status,
          category:result.data.category,
          images:{}
        }
        this.setState({imageLink:result.data.images,news_from:new Date(result.data.news_from),news_till:new Date(result.data.news_till)});
        this.setState({data:newdata});
        if(result.data.category=== 4)
            this.setState({showQuotes:false});
        plog('R--',this.state.data);
      })

    }) 
    
    
  }
  deleteImage= (image) => {
    let imageDetails={
      image_name:image,
      news_id:this.state.data.news_id
    }
    NewsApi.delteNewsImage('/news/delete-image',imageDetails).then((result) => {
      if (result.data.status === "success") {
        this.setState({ redirect: true });
      }  else {
        this.setState({ errors: result.data.error_description, loading: false });
      } 
    })
  }

  updateNews = () => {
    Helper.authenticateToken().then(()=>{
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    plog("All Data-",this.state.data);
    if (Object.keys(errors).length === 0) {
      
      NewsApi.updateNews('/news/edit', this.state.data).then((result) => {
        if (result.data.status === "success") {
          this.setState({ visibleSuccess: true, fetchInProgress: false, successmsg: result.data.error_description });
          setTimeout(function () {
            this.setState({ redirect: true });
          }.bind(this), 3000)
        }  else {
          this.setState({ errors: result.errors, fetchInProgress: false, visibleError: true });
        }
      }).catch(err=>{
        this.setState({ errors:err.response.data.error_description, fetchInProgress: false, visibleError: true });
      }) 

    }
  })
  }
  validate = (data) => {
    const errors = {};
    if (!data.headline) errors.headline = "HeadLine is Required";
    if(!data.description) errors.description="Description is Required";
    if(!data.event_venue) errors.event_venue="Event venue is Required";
    return errors;
  }
  onChange = e =>{
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    })}

    
  fileChangedHandler = event => {
    let img = event.target.files;
    //this.setState(this.state.data.images=img);
    this.setState(prevState => ({
      data: {...prevState.data,images: img}
    }))
  }
  

  render() {
    const { data, errors,imageLink ,redirect,showQuotes,fetchInProgress,visibleSuccess, visibleError,successmsg} = this.state;
    if (redirect) {
      return <Redirect to={'/admin/news'} />
    }
    
    const items = [];
    if(data.news_banner!=null){
  let allimages=data.news_banner.split('|*|');
  for (var i=0;i<allimages.length;i++){
    items.push(<div  key={i} md="4" className="text-left"><button type="button" className="close" name={allimages[i]} onClick={(e) => this.deleteImage(e.target.name)} data-dismiss="alert" aria-label="Close">&times;</button><img src={imageLink[i]} width="100" height="100" alt="preview" /></div>)
  }}
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          {fetchInProgress ? <div className="row justify-content-center"> <Loader size="x-large" inProgress={fetchInProgress} /></div> : 
          <Row>
            <div className="col">
              <Card className="shadow card-bg">
                <CardHeader className="border-0">
                  <div className="row">
                <div className="col-md-6 col-6 pt-2">
                  Edit News
                 </div>
                 <div className="col-md-6 col-6 text-right">
                 <Link to="news">
                  <button type="button" className="btn btn-primary md">Back</button>
                </Link>
                 </div>
                 </div>
                </CardHeader>
                <CardBody>
                {visibleSuccess ? <Alert color="success" isOpen={visibleSuccess} toggle={this.onDismiss}>
                      {successmsg}
                  </Alert> : visibleError ? <Alert color="warning"  isOpen={visibleError} toggle={this.onDismiss}>
                      {
                        errors.map((item, index) =>{
                              return (<> <span key={index} >{item.msg} of {item.param}</span> <br /> </>);
                        })
                      }
                  </Alert>:''}
                <Form encType="multipart/form-data">
                  <Row>
                      <Col md="6">
                          <FormGroup>
                            <label htmlFor="category">Category<span className="error-message">*</span></label>
                            <select  id="category" className="form-control" name="category" value={data.category}
                              onChange={this.changeCategory}>

                              <option value="1">Paribesh News</option>
                              <option value="2">WBPCB Intiative</option>
                              <option value="3">Info-in Graphics</option>
                              <option value="4">Quotes</option>
                              <option value="5">Event</option>

                            </select>
                          </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label htmlFor="headline">Headline<span className="error-message">*</span></label>
                        <Input
                          className="form-control-alternative" id="headline" placeholder="headline" type="text" name="headline" value={data.headline}
                          onChange={this.onChange} />
                      </FormGroup>
                      {errors.headline && <InlineError text={errors.headline} />}
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label htmlFor="exampleEmail">News From<span className="error-message">*</span></label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                           <DatePicker className="form-control" selected={this.state.news_from} onChange={this.changeFromDate} dateFormat="yyyy-MM-dd"/>
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <label htmlFor="exampleEmail">News Till<span className="error-message">*</span></label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <DatePicker className="form-control" selected={this.state.news_till} onChange={this.changeTillDate} dateFormat="yyyy-MM-dd"/>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    
                    <Col md="6">
                      <FormGroup>
                        <label htmlFor="images">Images</label>
                        <Input
                          className="form-control-file" id="images" placeholder="images" multiple type="file" name="images" onChange={this.fileChangedHandler} />
                      </FormGroup>
                    </Col>
                    

                    <Col md="6">
                      <FormGroup>
                        <label htmlFor="status">Status<span className="error-message">*</span></label>
                        <select  id="status" className="form-control" name="status" value={data.status}
                          onChange={this.onChange}>
                          <option value="0">Draft</option>
                          <option value="1">Published</option>
                          <option value="2">Delete</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="6" style={showQuotes ? {} : { display: 'none' }}>
                      <FormGroup>
                        <label htmlFor="event_venue">Event Venue<span className="error-message">*</span></label>
                        <Input
                          className="form-control-alternative" id="event_venue" placeholder="event venue" type="text" name="event_venue" value={data.event_venue}
                          onChange={this.onChange} />
                      </FormGroup>
                      {errors.event_venue && <InlineError text={errors.event_venue} />}
                    </Col>
                    <Col md="6" style={showQuotes ? {} : { display: 'none' }}>
                      <FormGroup>
                        <label htmlFor="exampleEmail">Description<span className="error-message">*</span></label>
                        <Input className="form-control-alternative" placeholder="Write a large text here ..." rows="3" type="textarea"
                          name="description"
                          value={data.description}
                          onChange={this.onChange} />
                      </FormGroup>
                      {errors.description && <InlineError text={errors.description} />}
                    </Col>
                    {items}
                    
                   
                    <Col md="12">
                      <div className="text-center pt-3">
                        <Button color="primary" className="is-active" size="md" onClick={this.updateNews}>Update</Button>
                      </div>
                    </Col>
                  </Row>

                </Form>
              </CardBody>  

              </Card>
            </div>
          </Row>
          }
        </Container>
      </>
    );
  }
}

export default NewsEdit;

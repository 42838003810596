import { BASE_URL } from '../config/config';
export const getKPIData = async (url) => {

    let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
    const data = await fetch(BASE_URL + url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'authorization': 'Bearer ' + accesstoken
        },
    })
        .then((response) => { return response.json() })
        .catch((error) => {
            return error;
        });

    return data

}

export const getFilterAPi = async (url) => {
    let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
    const data = await fetch(BASE_URL + url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'authorization': 'Bearer ' + accesstoken
        },
    })
        .then((response) => { return response.json() })
        .catch((error) => {
            return error;
        });

    return data
}

export const getPreSms = async (id) => {
    console.log(id)

    let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;


    var myHeaders = new Headers();
    myHeaders.append("authorization", `Bearer ${accesstoken}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "complaint_id": id
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const data = await fetch("https://api.paribesh.wtlprojects.com/api/v1/complaint/get_all_reply", requestOptions)
        .then(response => response.json())
        .then(result => { return result })
        .catch(error => { return error });

    return data;



}
export const filterBy = async (typeofFilter, name) => {

    let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
    const data = await fetch(`https://api.paribesh.wtlprojects.com/api/v1/complaint/search_by_choice?${typeofFilter}=${name}&per_page=10000`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'authorization': 'Bearer ' + accesstoken,

        },
    })
        .then((response) => { return response.json() })
        .catch((error) => {
            return error;
        });

    return data

}
export const filterByDateRange = async (endDate, startDate) => {

    let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
    const data = await fetch(`https://api.paribesh.wtlprojects.com/api/v1/complaint/search_by_choice?start_datetime=${startDate} 00:00:00&end_datetime=${endDate} 23:59:59&per_page=10000`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'authorization': 'Bearer ' + accesstoken,

        },
    })
        .then((response) => { return response.json() })
        .catch((error) => {
            return error;
        });

    return data

}
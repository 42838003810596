import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Alert,
  Progress
} from "reactstrap";
// core components
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import UserHeader from "../../../components/Headers/UserHeader";
import 'react-activity/dist/react-activity.css';
import Helper from '../../../helpers/helper';
import moment from '../../../../node_modules/moment';
import { Redirect } from 'react-router-dom';
import UserApi from '../../../API/userapi';
import Pagination from "react-js-pagination";
import loder1 from '../../../assets/loder1.gif';

class UserDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {
        user_id: '',
        username: '',
        email: '',
        title: '',
        name: '',
        mobile_no: '',
        qualification: '',
        address: '',
        pincode: '',
        is_active: '',
        is_admin: '',
        user_type: '',
        last_seen: '',
        activity_points: '',
        createdAt: '',
        updatedAt: '',
        my_invitation_code: '',
        iam_invited_code: '',
        profile_completeness:0,
        totalPoints:0,
        images: []
      },
      paramData:{
        per_page:5,
        pageno:1
      },
      activePage: 1,
      imageLink: {},
      redirect: false,
      errors: {},
      activityLog:[],
      totalLogCount:1,
      fetchInProgress: false
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  handlePageChange=(pageNumber) =>{
    this.setState({ activePage: pageNumber });
    this.setState(prevState => ({
      paramData: {...prevState.paramData,pageno:pageNumber}
    }))
    this.loadData();
  }
  componentWillMount() { 
    this.loadData();
  }
  loadData=() =>{
    const { userid } = this.props.location.state;
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      UserApi.getUserDetails("/users/user-info", userid).then((pdresult) => {
        let result = pdresult.data[0];
        let newdata = {
          user_id: result.id !== null ? result.id : '',
          username: result.username !== null ? result.username : '',
          email: result.email !== null ? result.email : '',
          title: result.title !== null ? result.title : '',
          name: result.name !== null ? result.name : '',
          mobile_no: result.mobile_no !== null ? result.mobile_no : '',
          qualification: result.qualification !== null ? result.qualification : '',
          address: result.address !== null ? result.address : '',
          pincode: result.pincode !== null ? result.pincode : '',
          is_active: result.is_active !== null ? result.is_active : '',
          is_admin: result.is_admin !== null ? result.is_admin : '',
          user_type: result.user_type !== null ? result.user_type : '',
          last_seen: result.last_seen !== null ? result.last_seen : '',
          activity_points: result.activity_points !== null ? result.activity_points : '',
          createdAt: result.createdAt !== null ? result.createdAt : '',
          updatedAt: result.updatedAt !== null ? result.updatedAt : '',
          my_invitation_code: result.my_invitation_code !== null ? result.my_invitation_code : '',
          iam_invited_code: result.iam_invited_code !== null ? result.iam_invited_code : '',
          images: (result.images !== undefined || result.images.length >= 1) ? result.images : [],
          totalPoints:result.totalPoints,
          profile_completeness:result.profile_completeness
        }
        // this.setState({ imageLink: result.images !== null ? result.images : '' });
        this.setState({ data: newdata , fetchInProgress: false });

        UserApi.getActivityLog("/activity/user-log", userid,this.state.paramData).then((activitylog) => {
            this.setState({activityLog:activitylog.data,totalLogCount:activitylog.total_data});
        })


      })
    })
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={'/admin/news'} />
    }
    const { data, fetchInProgress,activityLog } = this.state;
    
    return (
      <>
        <UserHeader name={((data.name !== null && data.name !== '') ? ((data.title !== null && data.title !== '') ? data.title : '') + ' ' + data.name : data.username)} inProgress={fetchInProgress}/>
        {/* Page content */}
        { fetchInProgress ?<div className="progress2 justify-content-center"> <img src={loder1} alt="" className="pad"/></div> :
        <Container className="mt--7" fluid>

          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="DP"
                          className="rounded-circle"
                          src={(data.images !== null && data.images !== '' && data.images.length >= 1) ? data.images : require("../../../assets/img/theme/dummy256x256.png")}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    <Button
                      className="mr-4"
                      color={data.is_active === 1 ? 'info' : 'danger'}
                      size="sm"
                    >
                      {data.is_active === 0 ? 'Inactive User' : data.is_active === 1 ? 'Active User' : data.is_active === 2 ? 'Deleted' : 'Banned'}
                    </Button>
                    <Button
                      className="float-right"
                      color="default"
                      size="sm"
                    >
                      {(data.is_admin === 0 && data.user_type === 1) ? 'Volunteer' : (data.is_admin === 0 && data.user_type === 2) ? 'Regs. User' : (data.is_admin === 1 && data.user_type === 2) ? 'BOC User' : 'Admin User'}
                    </Button>
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                        <div>
                          <span className="heading">{data.totalPoints !== null && data.totalPoints !== '' ? data.totalPoints : '0'}</span>
                          <span className="description">Activity Point</span>
                        </div>
                        
                      </div>
                    </div> 
                  </Row>
                  <div className="text-center">
                  <div className="text-center">{ data.profile_completeness.toFixed(2)}%</div>
                     <Progress value={data.profile_completeness.toFixed(2)} />
                  </div>
                  <div className="text-center">
                    <h3>
                      {((data.name !== null && data.name !== '') ? ((data.title !== null && data.title !== '') ? data.title : '') + ' ' + data.name : data.username)}
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Last Seen - {data.last_seen !== null && data.last_seen !== '' ? moment(data.last_seen).calendar() : 'Not Updated'}
                    </div>
                    <div className="h5 mt-4" style={{ textAlign: "left" }}>
                      Email ID - {data.email !== null && data.name !== '' ? data.email : 'Not Provided'}
                      <br />
                      Mobile No. - {data.mobile_no !== null && data.mobile_no !== '' ? data.mobile_no : 'Not Provided'}
                      <br />
                      Profile Created - {data.createdAt !== null && data.createdAt !== '' ? moment(data.createdAt).format('LLLL') : 'Not Updated'}
                      <br />
                      Last Updated - {data.updatedAt !== null && data.updatedAt !== '' ? moment(data.updatedAt).format('LLLL') : 'Not Updated'}
                    </div>
                    <hr className="my-4" />
                    <div className="h5 mt-4" style={{ textAlign: "left" }}>
                      Address - {data.address !== null && data.address !== '' ? data.address : 'Not Provided'} , {data.pincode !== null && data.pincode !== '' ? data.pincode : ''}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My account</h3>
                    </Col>
                    {/* <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Settings
                      </Button>
                    </Col> */}
                  </Row>
                </CardHeader>
                <CardBody>
                <Tabs>
              <TabList>
                <Tab><i className="fas fa-user-circle"></i> Account Details</Tab>
                <Tab><i className="fas fa-walking"></i> Activity Log</Tab>
              </TabList>
          
              <TabPanel>
              <Form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Title
                            </label>
                            <select id="input-first-name" className="form-control" name="title" defaultValue={data.title}>
                              <option>Select Title</option>
                              <option value="Mr">Mr</option>
                              <option value="Mrs">Mrs</option>
                              <option value="Ms">Ms</option>
                              <option value="Shri">Shri</option>
                              <option value="Smt">Smt</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Full name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="Enter your name"
                              type="text"
                              name="name"
                              defaultValue={data.name}
                            // disabled
                            // onChange={this.onChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Username
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder="Username"
                              type="text"
                              name="username"
                              defaultValue={data.username}
                              disabled
                            // onChange={this.onChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="xyz@example.com"
                              type="email"
                              name="email"
                              defaultValue={data.email}
                            // disabled
                            // onChange={this.onChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-mobile"
                            >
                              Mobile Number
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-mobile"
                              placeholder="Mobile no."
                              type="text"
                              maxLength="10"
                              name="mobile_no"
                              defaultValue={data.mobile_no}
                            // disabled
                            // onChange={this.onChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-qualification"
                            >
                              Qualification
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-qualification"
                              placeholder="Qualification"
                              type="text"
                              name="qualification"
                              defaultValue={data.qualification}
                            // disabled
                            // onChange={this.onChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="8">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address
                            </label>
                            <Input
                              className="form-control-alternative"
                              // defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                              id="input-address"
                              placeholder="Home Address"
                              rows="2"
                              type="textarea"
                              name="address"
                              defaultValue={data.address}
                            // disabled
                            // onChange={this.onChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-postal-code"
                            >
                              Postal code
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-postal-code"
                              placeholder="Postal code"
                              type="text"
                              maxLength="6"
                              name="pincode"
                              defaultValue={data.pincode}
                            // disabled
                            // onChange={this.onChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Description */}

                    <div className="pl-lg-4 text-right">
                      {/* <Button
                        color="info"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        Update profile
                      </Button> */}
                    </div>
                  </Form>
                </TabPanel>
                <TabPanel>
                <div className="log-head"  key="-1">
                <div className="row">
                      <div className="col-md-5">
                          Description
                      </div>
                      <div className="col-md-2">
                       Type
                      </div>
                      <div className="col-md-2">
                        Points
                      </div>
                      <div className="col-md-3">
                        Date
                      </div>   
                  </div>
                </div>
               {(activityLog !== 0) ?
                activityLog.map((item, index) => {
                return(<div className="log" key={item}>
                  <div className="row">
                      <div className="col-md-5">
                      {item.activity_description}
                      </div>
                      <div className="col-md-2">
                      {item.activity_type}
                      </div>
                      <div className="col-md-2">
                      {item.activity_point}
                      </div>
                      <div className="col-md-3">
                      {moment(item.activity_date).format('DD/MM/YYYY HH:mm:ss')}
                      </div>
                      
                  </div>
               </div>) 
                }): <Alert color="warning" className="col-md-12 col-12 " >
                <strong>Sorry!</strong>  No Data Found!
          </Alert>
            }{activityLog!==0?
              <div className="pag text-right mr-2">
                <Pagination className="pagination pagination-lg"
                            listClassName="pagination-lg"
                          activePage={this.state.activePage}
                          itemsCountPerPage={5}
                          totalItemsCount={this.state.totalLogCount}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        /> 
               </div>:''
          }             
                </TabPanel>
              </Tabs>
                  
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        }
      </>
    );
  }

}

export default UserDetails;
//import querystring from 'querystring';
import axios from "axios";
import {BASE_URL,plog} from '../config/config';

const getUserList = (type, paramData) => { 
   // let parameter="?per_page="+paramData.per_page+"&pageno="+paramData.pageno;

    const keyValuePairs = [];
        for (const key in paramData) {
             if(key==='userName' && paramData[key]==='')
                continue;
            if(key==='UserMobile' && paramData[key]==='')
                continue; 
          keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(paramData[key]));
        }
       let newParam="?"+keyValuePairs.join('&');
    let url = '/users/';
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url + type + newParam, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
           // params: querystring.stringify(userData)
        })
            .then((response) => { resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });
    });
}

const getUserDetails = (url, userid) => {
    return new Promise((resolve, reject) => {
        // userData.userid = userid;
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url + '?userid=' + userid, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
            // params: querystring.stringify(userData)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });

    });
}

const userDetailsToken = (url) => {
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        //for user details
        fetch(BASE_URL + url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accesstoken}`
            }
        })
            .then((response) => {
                resolve(response.json())
            })
            .catch((error) => {
                reject(error);
            });
    });
}
const updateProfile = (url, profileData) => {
    plog("result=",profileData);
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        let formData = new FormData();
        var key = null;
        for(key in profileData) {
            formData.append( key, profileData[key] );
        }
        for(key in profileData.images){  
            formData.append( 'images', profileData.images[key] );
        } 
        axios.patch(BASE_URL + url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'authorization': 'Bearer ' + accesstoken
            }})
            .then((response) => { resolve(response) })
            .catch((error) => {
                reject(error);
            }); 
    });
}

const changePass = (url, state) => {
    plog("result=",state);
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        let formData = new FormData();
        var key = null;
        for(key in state) {
            formData.append( key, state[key] );
        }
        axios.post(BASE_URL + url, formData, {
            headers: {
                'Authorization': 'Bearer ' + accesstoken
            }})
            .then((response) => { resolve(response) })
            .catch((error) => {
                reject(error);
            }); 
    });
}

const addUser = (url, state) => {
    plog("result=",state);
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        let formData = new FormData();
        var key = null;
        for(key in state) {
            formData.append( key, state[key] );
        }
        axios.post(BASE_URL + url, formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + accesstoken
            }})
            .then((response) => { resolve(response) })
            .catch((error) => {
                reject(error);
            }); 
    });
}

const updateUserAction = (url, state) => {
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        let formData = new FormData();
        var key = null;
        for(key in state) {
            formData.append( key, state[key] );
        }
        axios.patch(BASE_URL + url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'authorization': 'Bearer ' + accesstoken
            }})
            .then((response) => { resolve(response) })
            .catch((error) => {
                reject(error);
            }); 
    });
}
const getActivityLog = (url, userid,paramData) => {
    let parameter="?per_page="+paramData.per_page+"&pageno="+paramData.pageno;
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url + '/' + userid + parameter, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            }
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });

    });
}
const myActivityLog = (url, paramData) => {
    let parameter="?per_page="+paramData.per_page+"&pageno="+paramData.pageno;
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url + parameter, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            }
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });

    });
}


export default { getUserList, getUserDetails, userDetailsToken, updateProfile, changePass, addUser, updateUserAction,getActivityLog,myActivityLog };
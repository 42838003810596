import Index from "./views/Index.jsx";
import Profile from "./views/components/User/Profile.jsx";
// import Register from "views/components/Register.jsx";
import Login from "./views/components/Login.jsx";
import News from "./views/components/News/News.jsx";
import NewsAdd from "./views/components/News/NewsAdd.jsx";
import NewsEdit from "./views/components/News/NewsEdit.jsx";
import NewsShow from "./views/components/News/NewsShow.jsx";
import Complain from "./views/components/Complain/Complain.jsx";
import ComplaintShow from "./views/components/Complain/ComplaintShow";
import User from "./views/components/User/User.jsx";
import UserDetails from "./views/components/User/UserDetails";
import ChangePassword from "./views/components/Password/ChangePassword";
import AddUser from "./views/components/User/AddUser";
import Feedbacklist from "./views/components/Feedback/Feedbacklist";
import NotificationList from "./views/components/Notification/Notification";
import FeedbackShow from "./views/components/Feedback/FeedbackShow";
import PrivacyPolicy from "./views/components/PrivacyPolicy/PrivacyPolicyShow";
import OrganizerList from "./views/components/GreenPuja/OrganiserList.jsx";
import OrganizerShow from "./views/components/GreenPuja/OrganizerShow.jsx";
import QuestionSetAdd from "./views/components/GreenPuja/QuestionSetAdd.jsx";
import QuestionList from "./views/components/GreenPuja/QuestionList.jsx";
import ContestDashboard from "./views/components/GreenPuja/ContestDashboard.jsx";
import ContestSetting from "./views/components/GreenPuja/ContestSetting.jsx";
import AllTopList from "./views/components/GreenPuja/AllTopList.jsx";
import DownloadExcel from "./views/components/GreenPuja/DownloadExcel.jsx";
import UserwiseCategory from "./views/components/Complain/UserwiseCategory";

import UnitDashboard from "./views/components/Units/UnitDashboard";
import ShowUnit from "./views/components/Units/ShowUnit";
import BouquetDashboard from "./views/components/Bouquet/BouquetDashboard";
import ShowTree from "./views/components/Bouquet/ShowTree";
import AddTree from "./views/components/Bouquet/AddTree";
import ViewTree from "./views/components/Bouquet/ViewTree";

import VolunteerDashboard from "./views/components/Volunteer/VolunteerDashboard";
import VolunteerDetails from "./views/components/Volunteer/VolunteerDetails";

import report from "./views/components/Report/Report.jsx";

import Download from "./views/components/temp/reactexcelwxample";
import Groups from "./views/components/Groups/Groups.jsx";
import TwitterPost from "./views/components/TwitterPost/TwitterPost.jsx";
var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth"
  // },
  {
    path: "/user",
    name: "App User",
    icon: "fas fa-users text-info",
    component: User,
    layout: "/admin",
  },
  {
    path: "/news",
    name: "News",
    icon: "fas fa-newspaper text-red",
    component: News,
    layout: "/admin",
  },
  {
    path: "/complaint",
    name: "Complaint",
    icon: "fas fa-book text-blue",
    component: Complain,
    layout: "/admin",
  },
  {
    path: "/feedback",
    name: "Feedback",
    icon: "ni ni-bullet-list-67 text-red",
    component: Feedbacklist,
    layout: "/admin",
  },
  {
    path: "/add-news",
    component: NewsAdd,
    layout: "/admin",
  },
  {
    path: "/edit-news",
    component: NewsEdit,
    layout: "/admin",
  },
  {
    path: "/show-news",
    component: NewsShow,
    layout: "/admin",
  },
  {
    path: "/user-details",
    component: UserDetails,
    layout: "/admin",
  },
  {
    path: "/show-complaint",
    component: ComplaintShow,
    layout: "/admin",
  },
  {
    path: "/change-password",
    component: ChangePassword,
    layout: "/admin",
  },
  {
    path: "/add-user",
    component: AddUser,
    layout: "/admin",
  },
  {
    path: "/notification-list",
    component: NotificationList,
    layout: "/admin",
  },
  {
    path: "/feedback-show",
    component: FeedbackShow,
    layout: "/admin",
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    layout: "/auth",
  },
  {
    path: "/contest-dashboard",
    name: "Green Puja Contest",
    icon: "fas fa fa-trophy text-red",
    component: ContestDashboard,
    layout: "/admin",
  },
  {
    path: "/complaint-category",
    name: "Kali puja/ Diwali contest",
    icon: "fas fa fa-users text-red",
    component: UserwiseCategory,
    layout: "/admin",
  },
  {
    path: "/organiser",
    name: "organiser",
    icon: "fas fa-book text-blue",
    component: OrganizerList,
    layout: "/admin",
  },
  {
    path: "/show-organizer",
    component: OrganizerShow,
    layout: "/admin",
  },
  {
    path: "/question-add",
    name: "Question Add",
    icon: "fas fa-book text-blue",
    component: QuestionSetAdd,
    layout: "/admin",
  },
  {
    path: "/question-list",
    name: "Question List",
    icon: "fas fa-book text-blue",
    component: QuestionList,
    layout: "/admin",
  },
  {
    path: "/contest-setting",
    name: "Contest Setting",
    icon: "fas fa-book text-blue",
    component: ContestSetting,
    layout: "/admin",
  },
  {
    path: "/contest-topresult",
    name: "Top List",
    icon: "fas fa fa-trophy text-red",
    component: AllTopList,
    layout: "/admin",
  },
  {
    path: "/download-excel",
    name: "Top List Excel",
    icon: "fas fa fa-trophy text-red",
    component: DownloadExcel,
    layout: "/admin",
  },

  {
    path: "/units",
    name: "Units",
    icon: "fas fa-industry text-gray",
    component: UnitDashboard,
    layout: "/admin",
  },

  {
    path: "/bouquet",
    name: "Bouquet",
    icon: "fas fa-seedling text-success",
    component: BouquetDashboard,
    layout: "/admin",
  },

  {
    path: "/unit-show",
    component: ShowUnit,
    layout: "/admin",
  },

  {
    path: "/tree-show",
    component: ViewTree,
    layout: "/admin",
  },

  {
    path: "/add-tree",
    component: AddTree,
    layout: "/admin",
  },

  {
    path: "/volunteer-home",
    component: VolunteerDashboard,
    layout: "/admin",
    name: "Volunteer",
    icon: "fas fa fa-users text-primary",
  },

  {
    path: "/volunteer-details",
    component: VolunteerDetails,
    layout: "/admin",
  },
  // {
  //   path: "/report",
  //   component: report,
  //   layout: "/admin",
  //   name: "Unit Reports",
  //   icon: "fas fa fa-file-lines text-success",
  // },
  {
    path: "/groups",
    component: Groups,
    layout: "/admin",
    name: "Groups",
    icon: "fas fa fa-users text-red",
  },
  {
    path: "/twitter_post",
    component: TwitterPost,
    layout: "/admin",
    name: "Twitter Post",
    icon: "fas fa fa-twitter text-success",
  },
  // <i class="fa-duotone fa-users-medical"></i>
  {
    path: "/download-excel",
    component: Download,
    layout: "/admin",
  },
];
export default routes;

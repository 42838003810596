/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import Helper from "../../helpers/helper";
import NotifiactionApi from "../../API/notificationapi";
import { PostLogOutData } from "../../PostLogOutData";
import { Redirect } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.state = {
      collapseOpen: false,
      userDetails: {},
      total_notification: 0,
      login_logid: "",
      redirect: true,
    };
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("userDetails")) {
      let userDetails1 = JSON.parse(localStorage.getItem("userDetails"));
      this.setState({ userDetails: userDetails1 });
      setTimeout(() => {
        Helper.authenticateToken().then(() => {
          NotifiactionApi.getNotificationCount(
            "/notification/unseen-notification"
          ).then((noticount) => {
            this.setState({ total_notification: noticount.total_data });
          });
        });
      }, 10000);
    } else {
      this.setState({ redirect: false });
    }
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      return prop.name === "Dashboard" ||
        prop.name === "App User" ||
        prop.name === "User Profile" ||
        prop.name === "News" ||
        prop.name === "Complaint" ||
        prop.name === "Feedback" ||
        // prop.name === "Green Puja Contest" ||
        // prop.name === "Kali puja/ Diwali contest" ||
        prop.name === "Units" ||
        prop.name === "Bouquet" ||
        prop.name === "Volunteer" ||
        prop.name === "Unit Reports" ||
        prop.name === "Groups" ||
        prop.name === "Twitter Post"? (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      ) : null;
    });
  };
  logout() {
    Helper.authenticateToken().then(() => {
      let responseJson = JSON.parse(localStorage.getItem("userData"));
      let loginid = localStorage.getItem("loginid");
      const access_token = responseJson.access_token;
      this.setState({ login_logid: loginid });
      if (this.state.login_logid && access_token) {
        PostLogOutData("logout", this.state, access_token).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            sessionStorage.setItem("userData", "");
            sessionStorage.clear();
            localStorage.setItem("userDetails", "");
            localStorage.clear();
            this.setState({ redirect: false });
          }
        });
      }
    });
  }

  render() {
    const { redirect, userDetails } = this.state;
    if (!redirect) {
      return <Redirect to={"/"} />;
    }
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className="pt-0" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : null}
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav className="nav--icon">
                <Link to={{ pathname: "notification-list" }}>
                  <div id="ex2">
                    {this.state.total_notification > 0 ? (
                      <span
                        className="fa-stack fa-1x has-badge"
                        data-count={this.state.total_notification}
                      >
                        <i className="ni ni-bell-55"></i>
                      </span>
                    ) : (
                      <span className="fa-stack fa-1x has-badge">
                        <i className="ni ni-bell-55"></i>
                      </span>
                    )}
                  </div>
                </Link>
              </DropdownToggle>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="DP"
                      src={
                        userDetails.images != ""
                          ? userDetails.images
                          : require("../../assets/img/theme/dummy256x256.png")
                      }
                    />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/change-password" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Change Password</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={this.logout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Navigation */}
            <Nav navbar>{this.createLinks(routes)}</Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;

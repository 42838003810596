import React, { useState, useEffect } from "react";
import Header from "../../../components/Headers/Header";
import Helper from "../../../helpers/helper";
import BouquetApi from "../../../API/bouquetapi";
import { Redirect, Link } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./ShowTree.css";
import Loader from "../../../components/Spinner/spinner";
//import GoogleMap from '../../../components/GoogleMap/index';
import GoogleMapReact from "google-map-react";
import moment from "moment";
import {
  Container,
  Button,
  Card,
  Col,
  CardHeader,
  CardBody,
  Row,
  Table,
  Badge,
} from "reactstrap";
import Slider from "react-animated-slider";

const APPROVE = 1;
const REJECT = 2;

const Redirection = {
  NONE: 0,
  BOUQUET: 1,
  VOLUNTEER: 2,
  location: ["", "/admin/bouquet", "/admin/volunteer-details"],
};

const ViewTree = (props) => {
  const [Tree, setTree] = useState(!!props.location.state?props.location.state.tree:{});
  const [TreeId, setTreeId] = useState(props.location.state.tree_id);
  const [RedirectNumber, setRedirect] = useState(Redirection.NONE);
  const [CoverImages, setCoverImages] = useState([]);
  const [VolunteersData, setVolunteersData] = useState([]);
  const [ShowFullVolunteersData, setShowFullVolunteersData] = useState(false);
  const [
    PhotoUpdateIDfetchInProgress,
    setPhotoUpdateIDfetchInProgress,
  ] = useState(-1);
  const [
    PhotoUpdateValuefetchInProgress,
    setPhotoUpdateValuefetchInProgress,
  ] = useState(0);
  const [lightBoxOpen, setlightBoxOpen] = useState(false);
  const [lightBoxIndex, setlightBoxIndex] = useState(-1);
  const [lightBoxImages, setlightBoxImages] = useState([]);
  const getTreeVolunteerAssignments = (id) => {
    Helper.authenticateToken().then(() => {
      BouquetApi.getTreePhotos(
        "/volunteer/get_tree_volunteer_assignments",
        id
      ).then((result) => {
        if (result.status === "success") {
          console.log("volunteer fetched successfully");
          console.log(result.data);
          setVolunteersData(result.data);
        } else {
          console.log("failed to fetch");
        }
      });
    });
  };

  const updateTreeProgressStatus = (id, status) => {
    setPhotoUpdateIDfetchInProgress(id);
    PhotoUpdateValuefetchInProgress(status);

    Helper.authenticateToken().then(() => {
      BouquetApi.approveVolunteerUploadedPhotos(
        "/bouquet/approve_tree_photo",
        status,
        id
      ).then((result) => {
        if (result.status === "success") {
          console.log("updated successfully");
          setPhotoUpdateIDfetchInProgress(-1);
          setPhotoUpdateValuefetchInProgress(0);
          getTreeDetails(TreeId || Tree.id);
        } else {
          console.log("failed to update");
          setPhotoUpdateIDfetchInProgress(-1);
          setPhotoUpdateValuefetchInProgress(0);
        }
      });
    });
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return { text: "Requested Adoption", color: "info" };
      case 2:
        return { text: "Adopted", color: "success" };
      case 3:
        return { text: "Requested Resignation", color: "dark" };
      case 4:
        return { text: "Resigned", color: "danger" };
      default:
        return { text: "¯\\＿(ツ)＿/¯", color: "primary" };
    }
  };

  const setTreeCoverImages = (data) => {
    const items = [];
    if (
      !!data &&
      data.admin_uploaded_images != null &&
      data.admin_uploaded_images.length > 0
    ) {
      for (let i = 0; i < data.admin_uploaded_images.length; i++) {
        items.push(
          <div
            style={{
              background: `url('${data.admin_uploaded_images[
                i
              ].trim()}') no-repeat center`,
              backgroundSize: "cover",
            }}
            key={i}
            alt="preview"
            name={data.admin_uploaded_images[i]}
          ></div>
        );
      }
    } else {
      items.push(
        <div
          style={{
            background: `url('${require("../../../assets/img/noimage.png")}') no-repeat center`,
            backgroundSize: "cover",
          }}
          key={0}
          alt="preview"
        ></div>
      );
    }
    setCoverImages(items);
  };

  const getTreeDetails = (TreeId) => {
    Helper.authenticateToken().then(() => {
      BouquetApi.getTreeDetails("/bouquet/get_tree_details", TreeId).then(
        (result) => {
          if (result.status === "success") {
            setTree(result.data);
            console.log(result);
          } else {
            console.log("failed to update tree details");
          }
        }
      );
    });
  };

  useEffect(() => {
    if (typeof Tree === "undefined" && typeof TreeId === "undefined") {
      setRedirect((p) => !p);
    } else if (typeof Tree !== "undefined" && typeof TreeId === "undefined") {
      setTreeId(Tree.id);
    } else if (typeof Tree === "undefined" && typeof TreeId !== "undefined") {
      getTreeDetails(TreeId);
    }
  }, []);
  //   useEffect(() => {
  //       if(PhotoUpdateIDfetchInProgress === -1) {
  //         getTreeDetails(TreeId || Tree.id);
  //       }
  //   }, [PhotoUpdateIDfetchInProgress])

  useEffect(() => {
    setTreeCoverImages(Tree);
  }, [Tree]);

  useEffect(() => {
    getTreeVolunteerAssignments(TreeId);
  }, [TreeId]);

  const openLightbox = (images) => {
    console.log(images);
    setlightBoxOpen(true);
    setlightBoxImages(images);
  };

  const closeLightbox = () => {
    setlightBoxOpen(false);
    setlightBoxImages([]);
  };

  const moveNext = () => {
    setlightBoxIndex((prevState) => (prevState + 1) % lightBoxImages.length);
  };

  const movePrev = () => {
    setlightBoxIndex(
      (prevState) =>
        (prevState + lightBoxImages.length - 1) % lightBoxImages.length
    );
  };

  let lightbox;
  if (lightBoxOpen) {
    lightbox = (
      <Lightbox
        mainSrc={lightBoxImages[lightBoxIndex]}
        nextSrc={lightBoxImages[(lightBoxIndex + 1) % lightBoxImages.length]}
        prevSrc={
          lightBoxImages[
            (lightBoxIndex + lightBoxImages.length - 1) % lightBoxImages.length
          ]
        }
        // mainSrcThumbnail={thumbs[this.state.index]}
        // nextSrcThumbnail={thumbs[(this.state.index + 1) % this.state.lightBoxImages.length]}
        // prevSrcThumbnail={
        //   thumbs[(this.state.index + this.state.lightBoxImages.length - 1) % this.state.lightBoxImages.length]
        // }
        onCloseRequest={closeLightbox}
        onMovePrevRequest={movePrev}
        onMoveNextRequest={moveNext}

        // imageTitle={titles[this.state.index]}
        // imageCaption={captions[this.state.index]}
      />
    );
  }

  if (RedirectNumber !== Redirection.NONE) {
    return <Redirect to={Redirection.location[Redirect]} />;
  }
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-2">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h1 className="mb-0">Tree Details</h1>
                  </Col>
                  <Col xs="4" className="text-right">
                    <Button
                      className="btn btn-sm"
                      color="primary"
                      type="button"
                      onClick={props.history.goBack}
                    >
                      <span>Back</span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="pt-2">
                  <Col md={12} lg={6}>
                    <div className="TreeDetails">
                      <b>Name: &nbsp;</b> {!!Tree && Tree.name}
                    </div>
                  </Col>
                  <Col md={12} lg={6}>
                    <div className="TreeDetails">
                      <b>Variety: &nbsp;</b> {!!Tree && Tree.variety}
                    </div>
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col xs={12} xl={12}>
                    <div className="TreeDetails">
                      <b>Location: &nbsp;</b> {!!Tree && Tree.location}
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col
                    md={12}
                    lg={6}
                    style={{
                      border: "1px solid aliceblue",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className=" rounded shadow-lg pt-2"
                  >
                    <h2>Geographical Location</h2>
                    <div
                      style={{
                        flexGrow: 1,
                        padding: 10,
                        maxHeight: 400,
                        minHeight: 300,
                      }}
                    >
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: "AIzaSyBJ-RKwTIsTWX_4eKsBN0bBUBBx8dP1-Ls",
                        }}
                        defaultCenter={{
                          lat: !!Tree ? parseFloat(Tree.lat) : 22,
                          lng: !!Tree ? parseFloat(Tree.long) : 88,
                        }}
                        center={{
                          lat: !!Tree && parseFloat(Tree.lat),
                          lng: !!Tree && parseFloat(Tree.long),
                        }}
                        defaultZoom={12}
                      >
                        <i
                          className="fas fa-tree"
                          style={{ fontSize: 20, color: "green" }}
                          lat={!!Tree && parseFloat(Tree.lat)}
                          lng={!!Tree && parseFloat(Tree.long)}
                        ></i>
                      </GoogleMapReact>
                    </div>
                  </Col>
                  <Col
                    md={12}
                    lg={6}
                    style={{ border: "1px solid aliceblue" }}
                    className=" rounded shadow-lg pt-2"
                  >
                    <h2>Tree Photos</h2>
                    <Slider autoplay={3000}>{CoverImages}</Slider>
                  </Col>
                </Row>
                <hr />
                <Row className="pt-2">
                  <Col md={12} lg={12}>
                    <Table>
                      <thead>
                        <tr>
                          <th colSpan={2}>
                            <h2>Volunteer's Assigned</h2>
                          </th>
                        </tr>
                        <tr>
                          <th>Volunteer</th>
                          <th>Contact Number</th>
                          <th>Assigned On</th>
                          <th>Current Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!!VolunteersData &&
                        VolunteersData.length > 0 &&
                        ShowFullVolunteersData
                          ? VolunteersData.map((volunteer, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <Link
                                      to={{
                                        pathname: "volunteer-details",
                                        state: { vol_id: volunteer["User.id"] },
                                      }}
                                    >
                                      {volunteer["User.name"]}
                                    </Link>
                                  </td>
                                  <td>{volunteer["User.mobile_no"]}</td>
                                  <td>
                                    {moment(volunteer.assignment_date).format(
                                      "LLL"
                                    )}
                                  </td>
                                  <td>
                                    <Badge
                                      color={
                                        getStatusText(volunteer.status).color
                                      }
                                    >
                                      {getStatusText(volunteer.status).text}
                                    </Badge>
                                  </td>
                                </tr>
                              );
                            })
                          : VolunteersData.slice(0, 9).map(
                              (volunteer, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Link
                                        to={{
                                          pathname: "volunteer-details",
                                          state: {
                                            vol_id: volunteer["User.id"],
                                          },
                                        }}
                                      >
                                        {volunteer["User.name"]}
                                      </Link>
                                    </td>
                                    <td>{volunteer["User.mobile_no"]}</td>
                                    <td>
                                      {moment(volunteer.assignment_date).format(
                                        "LLL"
                                      )}
                                    </td>
                                    <td>
                                      <Badge
                                        color={
                                          getStatusText(volunteer.status).color
                                        }
                                      >
                                        {getStatusText(volunteer.status).text}
                                      </Badge>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        <tr>
                          <td colSpan={4}>
                            <Button
                              className="btn btn-light btn-block"
                              onClick={() =>
                                setShowFullVolunteersData((p) => !p)
                              }
                            >
                              {ShowFullVolunteersData
                                ? "Show Few Details"
                                : "Show Full Details"}
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-2">
                    <h2
                      style={{
                        textAlign: "left",
                        marginLeft: 16,
                      }}
                    >
                      Tree Progress History
                    </h2>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <VerticalTimeline
                      layout="2-columns"
                      className="TimeLineContainer"
                    >
                      {!!Tree &&
                      !!Tree.volunteer_uploaded_images &&
                      Tree.volunteer_uploaded_images.length > 0
                        ? Tree.volunteer_uploaded_images.map((photo, index) => {
                            return (
                              <VerticalTimelineElement
                                key={index}
                                className="vertical-timeline-element--work"
                                contentStyle={{
                                  background: "rgb(33, 150, 243)",
                                  color: "#fff",
                                }}
                                contentArrowStyle={{
                                  borderRight: "7px solid #8bc43f",
                                }}
                                date={moment(photo.updated_at).format("LLL")}
                                dateClassName="TimeLineDate"
                                textClassName={
                                  index % 2 === 0
                                    ? "TimeLineTextContainerBackgroundEven"
                                    : "TimeLineTextContainerBackgroundOdd"
                                }
                                iconStyle={{
                                  backgroundColor: " #005f2e",
                                  backgroundImage:
                                    photo.images && photo.images.length > 0
                                      ? `url(${photo.images[0]})`
                                      : "linear-gradient(90deg, #8bc43f 0, #005f2e 100%) !important",
                                  color: "#fff",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                                icon={
                                  photo.images && photo.images.length > 0 ? (
                                    <i
                                      className="far fa-images"
                                      style={{ color: "#ffffff88" }}
                                    ></i>
                                  ) : (
                                    <i className="fas fa-exclamation-triangle"></i>
                                  )
                                }
                                iconOnClick={
                                  photo.images && photo.images.length > 0
                                    ? () => openLightbox(photo.images)
                                    : null
                                }
                              >
                                <Row>
                                  <Col>
                                    <p>{photo.comment}</p>
                                  </Col>
                                  {console.log("Photo Status: ", photo.status)}
                                  {photo.status === 0 ? (
                                    <Col
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                      }}
                                    >
                                      {photo.photo_group_id ===
                                      PhotoUpdateIDfetchInProgress ? (
                                        <img src={Loader} alt="" />
                                      ) : (
                                        <>
                                          <Button
                                            className="btn btn-danger btn-sm"
                                            onClick={() =>
                                              updateTreeProgressStatus(
                                                photo.photo_group_id,
                                                REJECT
                                              )
                                            }
                                          >
                                            <i
                                              class="fas fa-times"
                                              style={{ fontSize: 18 }}
                                            ></i>
                                          </Button>
                                          <Button
                                            className="btn btn-success btn-sm"
                                            onClick={() =>
                                              updateTreeProgressStatus(
                                                photo.photo_group_id,
                                                APPROVE
                                              )
                                            }
                                          >
                                            <i
                                              class="far fa-check-circle"
                                              style={{ fontSize: 18 }}
                                            ></i>
                                          </Button>
                                        </>
                                      )}
                                    </Col>
                                  ) : null}
                                </Row>

                                <span
                                  style={{ color: "rgba(255,255,255,0.5)" }}
                                >
                                  {moment(photo.updated_at).format("LLL")}
                                </span>
                              </VerticalTimelineElement>
                            );
                          })
                        : null}
                    </VerticalTimeline>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {lightbox}
    </>
  );
};
export default ViewTree;

import {BASE_URL} from '../config/config';

const getVolunteerList = (url, paramData) => {
    let parameter="?per_page="+paramData.per_page+"&page_no="+paramData.page_no+"&status="+paramData.status;
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url +parameter , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
          // params: qs.stringify(paramData)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });
    });
}

const getTreeAdoptionDetails = (url, volunteerId, status = 5) => {
    let parameter="?volunteer_id="+volunteerId+"&status="+status;
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url +parameter , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            },
          // params: qs.stringify(paramData)
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });
    });
}

export default {getVolunteerList, getTreeAdoptionDetails};
import React, { Fragment } from "react";
import '../../../assets/css/custom.css';
// reactstrap components
import {
  Row,
  Card,
  Container,
  CardHeader,
  CardBody,
  Alert
} from "reactstrap";
//import ReactDatetime from "react-datetime";
import { Redirect } from 'react-router-dom';
// core components

import ContestHeader from "../../../components/Headers/ContestHeader";
import GreenPujaApi from '../../../API/greenpujaapi';
import Helper  from '../../../helpers/helper';
import loder1 from '../../../assets/loder1.gif';
import { withRouter } from 'react-router';
import 'react-animated-slider/build/horizontal.css';
class ContestSetting extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {
        images:{}
      },
      contest_start:false,
      contest_over:false,
      imageLink:{},
      redirect: false,
      fetchInProgress:false,
      errors: {}
    };
    
  }
  componentDidMount() { 
   /*  const { organiser_id } = this.props.location.state;
    let  url='';
    this.setState({fetchInProgress:true});
     Helper.authenticateToken().then(()=>{
          url="/organiser/"+organiser_id;
          GreenPujaApi.getOrganizerDetails(url).then((result) => {
            this.setState({data:result.data,fetchInProgress:false,imageLink:result.data.images});           
          })
    })  */
  }
  startContest=()=>{
    this.setState({fetchInProgress:true});
    Helper.authenticateToken().then(()=>{
         GreenPujaApi.contestStartComplete('/contest/complete/1',{status:3}).then((result) => {
           this.setState({data:result,fetchInProgress:false,contest_start:true,contest_over:false});           
         })
   }) 
  }
  stopContest=()=>{
    this.setState({fetchInProgress:true});
     Helper.authenticateToken().then(()=>{
         GreenPujaApi.contestStartComplete('/contest/complete/1',{status:2}).then((result) => {
           this.setState({data:result,fetchInProgress:false,contest_over:true,contest_start:false});           
         })
    }) 
   
     Helper.authenticateToken().then(()=>{
      let userSMS={signature:"wBpCb@2019",per_page:100,position:1};
      GreenPujaApi.userSMSSend('/contest/send-sms-top-users',userSMS).then((result) => {
        this.setState({fetchInProgress:false});
      })
    }) 

     Helper.authenticateToken().then(()=>{
        let organiserSMS={signature:"wBpCb@2019",per_page:100,position:1};
        GreenPujaApi.organiserSMSSend('/contest/send-sms-top-organiser',organiserSMS).then((result) => {
          this.setState({fetchInProgress:false});       
      })
    }) 

  }

  sendSMSUserOrganiser=()=>{
    Helper.authenticateToken().then(()=>{
      let userSMS={signature:"wBpCb@2019",per_page:100,position:0};
      GreenPujaApi.userSMSSend('/contest/send-sms-top-users',userSMS).then((result) => {
        this.setState({fetchInProgress:false});
      })
    }) 

     Helper.authenticateToken().then(()=>{
        let organiserSMS={signature:"wBpCb@2019",per_page:100,position:0};
        GreenPujaApi.organiserSMSSend('/contest/send-sms-top-organiser',organiserSMS).then((result) => {
          this.setState({fetchInProgress:false});       
      })
    }) 
  }

  updateRatingOrganisation=()=>{
    Helper.authenticateToken().then(()=>{
      let organiserSMS={signature:"wBpCb@2019"};
      GreenPujaApi.updateOrganisationRating('/organiser/update-points',organiserSMS).then((result) => {
        this.setState({fetchInProgress:false});       
    })
  }) 
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/admin/organizer'} />
    }
    const { contest_start,contest_over,fetchInProgress } = this.state;
    return (
      <Fragment>
        <ContestHeader />
        {/* Page content */}
        { fetchInProgress ?<div className="progress2 justify-content-center"> <img src={loder1} alt=""/></div> :
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow card-bg-view">
                <CardHeader>
                <div className="row">
                  <div className="col-md-6 col-6 pt-2">
                    Contest Setting
                  </div>
                  <div className="col-md-6 col-6 text-right">
                     <button type="button" className="btn btn-primary md" onClick={ () => this.props.history.go(-1)}>Back</button>
                  </div>
                 </div>
                 </CardHeader>
                 <CardBody>
                 {contest_start ? <Alert color="success" isOpen={contest_start} toggle={this.onDismiss}>
                      Contest Started Successfully
                  </Alert>:""}
                  {contest_over ? <Alert color="success" isOpen={contest_over} toggle={this.onDismiss}>
                      Contest Completed Successfully
                  </Alert>:""}
                    <div className="row">
                            <div className="col-md-12 text-md-left pl-md-2">
                                <button type="button" className="btn btn-success md" onClick={this.startContest}>Start Contest</button>
                                <button type="button" className="btn btn-danger md" onClick={this.stopContest}>Stop Contest</button>
                                <button type="button" className="btn btn-primary md" onClick={this.sendSMSUserOrganiser}>Send Contestent SMS</button>
                                <button type="button" className="btn btn-primary md" onClick={this.updateRatingOrganisation}>Update Oranasation Rating</button>
                            </div>
                    </div>
                 </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        }
      </Fragment>
    );
  }
}

export default withRouter(ContestSetting);

import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";
import { Redirect } from 'react-router-dom';
import { PostLogOutData } from '../../PostLogOutData';
import Helper from '../../helpers/helper';
import NotifiactionApi from '../../API/notificationapi'

class AdminNavbar extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "Logout",
      redirect: true,
      userDetails: {},
      total_notification:0
    }
    this.logout = this.logout.bind(this);
  }
   

  componentDidMount() {
    if ( localStorage.getItem('userDetails')) {
      let userDetails1 = JSON.parse(localStorage.getItem('userDetails'));
      this.setState({userDetails : userDetails1});
     setTimeout(()=>{
      Helper.authenticateToken().then(()=>{ 
        NotifiactionApi.getNotificationCount("/notification/unseen-notification").then((noticount) => {
          this.setState({total_notification:noticount.total_data});
        })
      })
    },15000);
    } else {
      this.setState({ redirect: false });
    }
  }

  


  logout() {
    Helper.authenticateToken().then(()=>{
      let responseJson = JSON.parse(localStorage.getItem('userData'));
      let loginid = localStorage.getItem('loginid');
      const access_token = responseJson.access_token;
      this.setState({ login_logid: loginid });
      if (this.state.login_logid && access_token) {
        PostLogOutData('logout', this.state, access_token).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            sessionStorage.setItem('userData', '');
            sessionStorage.clear();
            localStorage.setItem('userDetails', '');
            localStorage.clear();
            this.setState({ redirect: false });
          } 
        });
      }
    });
    
  }

  render() {
     const {redirect, userDetails}=this.state;
    if (!redirect) {
      return <Redirect to={'/'} />
    }  
    return (
      <>
      
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </Link>
            
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                  <DropdownToggle nav className="nav-link-icon">
                    
                  <div id="ex2">
                  <Link to={{pathname:'notification-list'}}>
                    {
                      this.state.total_notification>0?<span className="fa-stack fa-1x has-badge" data-count={this.state.total_notification}>
                      <i className="ni ni-bell-55"></i>
                    </span>:
                    <span className="fa-stack fa-1x has-badge" >
                    <i className="ni ni-bell-55"></i>
                  </span>
                    }
                    
                    </Link>
                  </div>
                  
                  </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt='DP'
                        src={userDetails.images!=="" ? userDetails.images : require("../../assets/img/theme/dummy256x256.png")}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {userDetails.name !==null && userDetails.name !=='' ? userDetails.name : userDetails.username}
                      </span>
                    </Media> 
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/change-password" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Change Password</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem  onClick={this.logout}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;

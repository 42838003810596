import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col, CardHeader } from "reactstrap";
import DashboardApi from '../../API/dashboardapi';
import Helper from '../../helpers/helper';
import { Link } from 'react-router-dom';

// react plugin used to create charts
//import { Bar } from "react-chartjs-2";
import loder1 from '../../assets/loder1.gif';
import moment from 'moment'
// core components
/* import {
  chartOptions,
  parseOptions,
  chartExample1
} from "../../variables/charts"; */
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
 Pie, PieChart,Sector
} from "recharts";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#8bc43f'];
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, percent, total,statusText
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{statusText}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${statusText} (${total})`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`( ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};
const renderActiveShape2 = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, percent, total,categoryText
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{categoryText}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${categoryText} (${total})`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`( ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "Logout",
      redirect: false,
      totalcount: {},
      testData:{
        labels:[],
        datasets:[{label:"Performance",data:[1,2,3]}]
      } ,
      fetchInProgress:false,
      data1 : [],
      complaintCount:[],
      newsStats:[],
      activeIndex: 0,
      activeIndex2: 0
    }
  }
  onPieEnter(data, index) {
    this.setState({
      activeIndex: index,
    });
  }
  onPieEnter2(data, index) {
    this.setState({
      activeIndex2: index,
    });
  }

  componentDidMount() {
    Helper.authenticateToken().then(() => {
      let  data={} 
      this.setState({ totalcount: data,fetchInProgress:true });
       DashboardApi.getTotalCount("/admin/dashboard").then((result) => {
       for(var i=0;i<result.data.last7DaysOnline.length;i++)
         { 
           let item={};
           item.date=moment(result.data.last7DaysOnline[i].date).format('D - ddd ');
           item.total_user=result.data.last7DaysOnline[i].total_user;
          this.state.data1.push(item);
        } 
        for( i=0;i<result.data.complaintStats.length;i++){
          let item={};
           item.statusText=result.data.complaintStats[i].statusText;
           item.total=result.data.complaintStats[i].total;
          this.state.complaintCount.push(item);
        }
        for( i=0;i<result.data.newsStats.length;i++){
          let item={};
           item.categoryText=result.data.newsStats[i].categoryText;
           item.total=result.data.newsStats[i].total;
          this.state.newsStats.push(item);
        }
         
        this.setState({ totalcount: result.data,fetchInProgress:false });
      }) 
    })
    
  }



  render() {
    const { totalcount,fetchInProgress } = this.state;
    return (
      <>
       {fetchInProgress ? <div className="progress-dash justify-content-center"> <img src={loder1} alt="" className="pad"/></div> :
        <div> 
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
           
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="3">
                  <Link to="complaint">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Complaint
                          </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totalcount.totalComplaints}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-book"/>
                            </div>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg="6" xl="3">
                  <Link to="news">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              News
                          </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totalcount.totalNews}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="far fa-newspaper"/>
                            </div>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg="6" xl="3">
                
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Online Users
                          </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totalcount.onlineUser}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape onlineUser text-white rounded-circle shadow" >
                            <i className="fas fa-user-friends"/>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
              
                </Col>
                <Col lg="6" xl="3">
                  <Link to="user">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              App User
                          </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totalcount.totalAppUsers}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                              <i className="fas fa-users" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              </Row>
            </div>

          </Container>
        </div>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col-md-6">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-2">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Last 7 Days LoggedIn Users</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                     <ResponsiveContainer width="100%" height={300}>
                     <BarChart data={this.state.data1}
                        margin={{top: 10, right: 0, left: -15, bottom: 0}}>
                        <XAxis dataKey="date"/>
                        <YAxis yAxisId="left" orientation="left" stroke="#03275b"/>
                        <YAxis yAxisId="right" orientation="right" stroke="#FE9E15"/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip/>
                        <Legend/>
                        <Bar yAxisId="left" dataKey="total_user" fill="#8bc43f"/>
                    </BarChart>
                  </ResponsiveContainer>
                </CardBody>
              </Card>
            </div>
            <div className="col-md-6">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-2">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Complaint</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie dataKey="total"
                        activeIndex={this.state.activeIndex}
                        activeShape={renderActiveShape}
                        onMouseEnter={this.onPieEnter.bind(this)}
                        data={this.state.complaintCount}
                        innerRadius={60}
                        outerRadius={80}>
                        {
                          
              this.state.complaintCount.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
            }</Pie>
                  </PieChart>
                </ResponsiveContainer>
                </CardBody>
              </Card>
            </div>
          </Row>
          <Row className="mt-4">
          <div className="col-md-6">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-2">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">News</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie dataKey="total"
                        activeIndex={this.state.activeIndex2}
                        activeShape={renderActiveShape2}
                        onMouseEnter={this.onPieEnter2.bind(this)}
                        data={this.state.newsStats}
                        innerRadius={60}
                        outerRadius={80}>
                                {
                         this.state.newsStats.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                        }
                        </Pie>
                  </PieChart>
                </ResponsiveContainer>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        </div> 
       }
     </>
    );
  }
}

export default Header;

import React,{Component, Fragment} from "react";
import '../../../assets/css/custom.css';
// reactstrap components
import {
  Row,
  Card,
  Container,
  CardHeader,
  Alert,
  CardBody

} from "reactstrap";
//import ReactDatetime from "react-datetime";
// core components
import Header from "../../../components/Headers/Header.jsx";
import NotificationApi from '../../../API/notificationapi';
import Helper  from '../../../helpers/helper';
import Pagination from "react-js-pagination";
import moment from 'moment';
import loder1 from '../../../assets/loder1.gif';
import { Redirect } from 'react-router-dom';
class NewsShow extends Component {
  constructor() {
    super();
    this.state = {
      notilist: [],
      paramData:{
        per_page:6,
        pageno:1,
      },
      totalNotiCount:1,
      activePage: 1, 
      fetchInProgress: false,
      redirect:'',
      parent_id:''
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  handlePageChange=(pageNumber) =>{
    this.setState({ activePage: pageNumber });
    this.setState(prevState => ({
      paramData: {...prevState.paramData,pageno:pageNumber}
    }))
    this.loadData();
  }
  

  componentWillMount() {
    this.loadData();
  }
  loadData=() =>{ 
    this.setState({fetchInProgress:true});
    Helper.authenticateToken().then(()=>{
      NotificationApi.getNotificationList('/notification/all', this.state.paramData).then((pdresult) => {
        this.setState({fetchInProgress:false});
        this.setState({notilist:pdresult.data,totalNotiCount:pdresult.total_data});
      });
     
    });
  }
  showData=(notification_id,notification_parent,parent_id)=>{
    Helper.authenticateToken().then(()=>{
      NotificationApi.readNotification('/notification/read-notification', notification_id).then((pdresult) => {
      
          this.setState({parent_id:parent_id})
          if(notification_parent==='complaint')
            this.setState({redirect:'complaint'});
          else
            this.setState({redirect:'feedback'});

         
        });
     
      });
  }
  render() {
    const {notilist,fetchInProgress,paramData,redirect}=this.state;
    if (redirect==='complaint') {
      return <Redirect to={{pathname: 'show-complaint',state: {complaint_id:this.state.parent_id}}} />
    }
    if (redirect==='feedback') {
      return <Redirect to={{pathname: 'feedback-show',state: {feedback_id: this.state.parent_id}}} />
    }
    return (
      <Fragment>
        <Header />
        {/* Page content */}
        { fetchInProgress ?<div className="progress2 justify-content-center"> <img src={loder1} alt=""/></div> :
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
          <Card className="log-box m-2 m-md-0">
            <CardHeader>
              <div className="row">
                <div className="col-md-12 col-12 p-0 pl-1">
                  <h3 className="mb-0">Notification List</h3>
                </div>
               </div>
               </CardHeader>
               <CardBody>
                  <div className="row m-md-3 m-1" key="-1">
                      {(notilist !== 0) ?
                      notilist.map((item, index) => {
                      return(
                      
                      <div className="log" key={item} onClick={()=>this.showData(item.notification_id,item.parent_module,item.parent_id)} >
                        <div className="row" >
                          <div className="col-md-12 col-12">{item.description}</div>
                            <div className="col-md-4 col-12">
                            <div className="log-title">
                               <i className="fas fa-clock greyIcon "></i>{" "}{moment(item.notification_time).format('ll HH:mm:ss')}</div>
                              {/* <b>Module:</b> {item.parent_module} */} 
                            </div>
                            <div className="col-md-4 col-12 text-right"></div>
                            <div className="col-md-4 col-12 text-right">
                            <b className="ml-4 "> {item.isread===0? <i className="fas fa-check-double "></i>:<i className="fas fa-check-double green"></i>}</b>
                            </div>
                          
                        </div>
                        </div>
                    
   
                      )
                        }): <Alert color="warning">
                        <strong>Sorry!</strong>  No Data Found!
                  </Alert>
                    }
               </div>
               </CardBody>
               {notilist!==0?
              <div className="pag text-right mr-1" key="-2">
                <Pagination className="pagination pagination-lg"
                            listClassName="pagination-lg"
                          activePage={this.state.activePage}
                          itemsCountPerPage={paramData.per_page}
                          totalItemsCount={this.state.totalNotiCount}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        /> 
               </div>:''
              
          }
               
            </Card>
          </Row>
        </Container>
        }
      </Fragment>
    );
  }
}

export default NewsShow;

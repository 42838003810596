import React from "react";
import { FormGroup, Form, Input, Col, Row, Button } from "reactstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "font-awesome/css/font-awesome.min.css";
import Helper from "../../../helpers/helper";
import { getFilterAPi } from "../../../API/complainApiNew";
import DateRangePicker from "./DateRangePicker";
const FilterPage = ({ onSelectedChange }) => {
  const [filterTypes, setFilterTypes] = React.useState();

  const [selected, setSelected] = React.useState([]);
  const [selectedType, setSelectedType] = React.useState("");
  const [filed, setFiled] = React.useState(null);

  const [data, setData] = React.useState([]);

  const onSearchChange = (e) => {
    setFilterTypes(e.target.value);
    setSelected([]);
    if (e.target.value === "0") {
      setData([]);
      setSelectedType("complainant name");
      setFiled("name");
      Helper.authenticateToken().then(async () => {
        const dataOption = await getFilterAPi("/complaint/getAllComplaintname");
        setData(dataOption.data);
      });
    }

    if (e.target.value === "1") {
      setSelectedType("phone number");
      setFiled("mobile_no");

      setData([]);
      Helper.authenticateToken().then(async () => {
        const dataOption = await getFilterAPi(
          "/complaint/getAllComplaintmobile_no"
        );
        setData(dataOption.data);
      });
    }
    if (e.target.value === "2") {
      setSelectedType("email ID");

      setData([]);
      Helper.authenticateToken().then(async () => {
        const dataOption = await getFilterAPi("/complaint/getAllUserEmail");
        setData(dataOption.data);
      });
    }
    if (e.target.value === "3") {
      setSelectedType("token no");
      setFiled("token_no");

      setData([]);
      Helper.authenticateToken().then(async () => {
        const dataOption = await getFilterAPi(
          "/complaint/getAllComplainttoken_no"
        );
        setData(dataOption.data);
      });
    }
  };

  const onSelectedItem = (e) => {
    setSelected(e);
  };

  return (
    <Row>
      <Col md="4">
        <FormGroup>
          <label htmlFor="status">Select filter type</label>
          <select
            id="status"
            className="form-control"
            name="status_by"
            onChange={onSearchChange}
            value={filterTypes}
          >
            <option>Select one</option>
            <option value="0">Complainant Name</option>
            <option value="1">Phone number</option>
            {/* <option value="2">Email ID</option> */}
            <option value="3">Token No</option>
          </select>
        </FormGroup>
      </Col>

      <Col md="4">
        <label htmlFor="basic-example">Choose {selectedType}</label>
        <Typeahead
          id="basic-example"
          onChange={(e) => onSelectedItem(e)}
          options={data ? data : []}
          placeholder={`Select ${selectedType}`}
          selected={selected}
          onSelectedItem={onSelectedChange({ ...selected, filed })}
        />
      </Col>
     
    </Row>
  );
};

export default FilterPage;

/* import querystring from 'querystring';
import axios from "axios"; */
import {BASE_URL} from '../config/config';
const getNotificationCount = (url) => {
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            }
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });

    });
} 
const getNotificationList = (url,paramData) => {
    let parameter="?per_page="+paramData.per_page+"&pageno="+paramData.pageno;
    return new Promise((resolve, reject) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        fetch(BASE_URL + url + parameter, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': 'Bearer ' + accesstoken
            }
        })
            .then((response) => {  resolve(response.json()) })
            .catch((error) => {
                reject(error);
            });

    });
}
const readNotification = (url, notification_id) => {
    let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
    return new Promise((resolve, reject) => {
        fetch(BASE_URL + url + "/"+ notification_id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', 
                'authorization': 'Bearer ' + accesstoken
            }})
            .then((response) => { resolve(response) })
            .catch((error) => {
                reject(error);
            }); 
        }) 
}   
export default { getNotificationCount,getNotificationList,readNotification}
import { BASE_URL } from "../config/config";
import axios from "axios";


export const groupsRead = (url) => {
  return new Promise((resolve, reject) => {
    let accesstoken = JSON.parse(
      localStorage.getItem("userData")).access_token;
    fetch(BASE_URL + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        authorization: "Bearer " + accesstoken,
      },
    })
      .then((response) => {
        resolve(response.json());
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const approveReject = ({ group_id, approval_state }) => {
  return new Promise((resolve, reject) => {
    let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
    let data = JSON.stringify({
      "group_id": group_id,
      "approval_state": approval_state
    });
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: BASE_URL + '/group/update_approval_status',
      headers: {
        'Authorization': "Bearer " + accesstoken,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(config)
      .then((response) => { resolve(response) })
      .catch((error) => {
        reject(error);
      });
  });
}

import React from "react";
import '../../../assets/css/custom.css';
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  Alert
} from "reactstrap";
import { Redirect } from 'react-router-dom';
import Header from "../../../components/Headers/Header.jsx";
import Loader from '../../../components/Spinner/spinner';
import InlineError from '../../../components/messages/InlineError';
import NewsApi from '../../../API/newsapi';
import Helper  from '../../../helpers/helper';
import DatePicker from 'react-datepicker';
import {plog} from '../../../config/config';
import { withRouter } from 'react-router';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';


class NewsAdd extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {
        headline: '',
        description: '',
        event_venue: '',
        news_from: moment(new Date()).format('YYYY-MM-DD'),
        news_till:  moment(new Date().setFullYear(new Date().getFullYear() + 1)).format('YYYY-MM-DD'), 
        status: 1,
        category: 1,
        images:{}
      },
      news_from:new Date(),
      news_till:new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      redirect: false,
      showQuotes:true,
      visibleSuccess: false,
      visibleError: false,
      successmsg: {},
      fetchInProgress: false,
      errors: {}
    };
    this.addNews = this.addNews.bind(this);
    this.onChange = this.onChange.bind(this);
    this.changeFromDate = this.changeFromDate.bind(this);
    this.changeTillDate = this.changeTillDate.bind(this);
    
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.changeCategory=this.changeCategory.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }
  addNews = () => {
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(()=>{
    const errors = this.validate(this.state.data);
    this.setState({ errors, fetchInProgress: false });
    if (Object.keys(errors).length === 0) {
      if(this.state.data.category==='4'){
          this.setState({
              data: { ...this.state.data, event_venue: '-',description:'-' }
          });
      }
      plog('All data-',this.state.data);
        NewsApi.addNews('/news/add', this.state.data).then((result) => {
        if (result.data.status === "success") {
          this.setState({ visibleSuccess: true, fetchInProgress: false, successmsg: result.data.error_description });
          plog("Success message-",this.state.successmsg);
          setTimeout(function () {
            this.setState({ redirect: true });
          }.bind(this), 3000)
        }  else {
          this.setState({ errors: result.errors, fetchInProgress: false, visibleError: true });
        }
      }).catch(err=>{
        this.setState({ errors:err.response.data.error_description, fetchInProgress: false, visibleError: true });
      }) 

    }
  })
  }
  validate = (data) => {
    const errors = {};
    //if (!data.headline) errors.headline = "HeadLine is Required";
    //if(!data.description) errors.description="Description is Required";
    if(!data.event_venue) errors.event_venue="Event venue is Required";
    return errors;
  }
  onChange = e =>{
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    })}
    changeCategory=e=>{
      //4 means quates
    (e.target.value==="4")?this.setState({showQuotes:false,data: { ...this.state.data, category: e.target.value,event_venue:'-',description:'-' }}):this.setState({showQuotes:true,data: { ...this.state.data, category: e.target.value,event_venue:'',description:'' }});
    }

    changeFromDate = date =>{
      let newdate=moment(date).format('YYYY-MM-DD');
      this.setState({
        data: { ...this.state.data, news_from:newdate },
        news_from:date
      });
    } 
    changeTillDate = date =>{
      let newdate=moment(date).format('YYYY-MM-DD');
      this.setState({
        data: { ...this.state.data, news_till:newdate },
        news_till:date
      });
    }
    
  fileChangedHandler = event => {
    let img = event.target.files;
    this.setState(prevState => ({
      data: {...prevState.data,images: img}
    }))
  }
  onDismiss() {
    const { visibleSuccess } = this.state;
    (visibleSuccess) ? this.setState({ visibleSuccess: false }) : this.setState({ visibleError: false });
  }
  

  render() {
    const { data, errors,showQuotes, redirect, fetchInProgress, visibleSuccess, visibleError,successmsg } = this.state;
    if (redirect) {
      return <Redirect to={'/admin/news'} />
    }
    
    return (
      <>
        <Header />
        {/* Page content */}
        
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow card-bg">
                <CardHeader className="border-0">
                  <div className="row">
                    <div className="col-md-6 col-6 pt-2">
                      News Add
                    </div>
                    <div className="col-md-6 col-6 text-right">
                      <button type="button" className="btn btn-primary md" onClick={ () => this.props.history.go(-1)}>Back</button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                {visibleSuccess ? <Alert color="success" isOpen={visibleSuccess} toggle={this.onDismiss}>
                      {successmsg}
                  </Alert> : visibleError ? <Alert color="warning"  isOpen={visibleError} toggle={this.onDismiss}>
                      {
                        errors.map((item, index) =>{
                              return (<> <span key={index} >{item.msg} of {item.param}</span> <br /> </>);
                        })
                      }
                  </Alert>:''}
                <Form encType="multipart/form-data">
                  <Row>
                  <Col md="6">
                      <FormGroup>
                        <label htmlFor="category">Category<span className="error-message">*</span></label>
                        <select  id="category" className="form-control" name="category" value={data.category}
                          onChange={this.changeCategory}>

                          <option value="1">Paribesh News</option>
                          <option value="2">WBPCB Intiative</option>
                          <option value="3">Info-in Graphics</option>
                          <option value="4">Quotes</option>
                          <option value="5">Event</option>

                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label htmlFor="headline">Headline<span className="error-message">*</span></label>
                        <Input
                          className="form-control-alternative" id="headline" placeholder="headline" type="text" name="headline" value={data.headline}
                          onChange={this.onChange} />
                      </FormGroup>
                      {errors.headline && <InlineError text={errors.headline} />}
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label htmlFor="exampleEmail">News From<span className="error-message">*</span></label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                         {/*  <ReactDatetime
                            inputProps={{
                              placeholder: "From Date",
                              name: "news_from",
                              id:"news_from",
                              value:this.state.data.news_from,
                            //  onClick:this.changeFromDate,
                              onBlur:this.changeFromDate,
                             // onSelect:this.changeFromDate,
                             // onChange:this.changeFromDate
                            }}
                            timeFormat={false}
                          />   */}
                          <DatePicker className="form-control" selected={this.state.news_from} onChange={this.changeFromDate} dateFormat="yyyy-MM-dd"/>
                   
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label htmlFor="exampleEmail">News Till<span className="error-message">*</span></label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <DatePicker className="form-control" selected={this.state.news_till} onChange={this.changeTillDate} dateFormat="yyyy-MM-dd"/>
                   
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    
                    <Col md="6">
                      <FormGroup>
                        <label htmlFor="images">Images</label>
                        <Input
                          className="form-control-file" id="images" placeholder="images" multiple type="file" name="images" onChange={this.fileChangedHandler} />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label htmlFor="status">Status<span className="error-message">*</span></label>
                        <select  id="status" className="form-control" name="status" value={data.status}
                          onChange={this.onChange}>
                          <option value="0">Draft</option>
                          <option value="1">Published</option>
                          <option value="2">Delete</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="6"  style={showQuotes ? {} : { display: 'none' }}>
                      <FormGroup>
                        <label htmlFor="event_venue">Event Venue<span className="error-message">*</span></label>
                        <Input
                          className="form-control-alternative" id="event_venue" placeholder="event venue" type="text" name="event_venue" value={data.event_venue}
                          onChange={this.onChange} />
                      </FormGroup>
                      {errors.event_venue && <InlineError text={errors.event_venue} />}
                    </Col>
                    
                    <Col md="6"  style={showQuotes ? {} : { display: 'none' }}>
                      <FormGroup>
                        <label htmlFor="exampleEmail">Description<span className="error-message">*</span></label>
                        <Input className="form-control-alternative" placeholder="Write a large text here ..." rows="2" type="textarea"
                          name="description"
                          value={data.description}
                          onChange={this.onChange} />
                      </FormGroup>
                      {errors.description && <InlineError text={errors.description} />}
                    </Col>
                    <Col>
                      <div className="text-center pt-3">
                        <Button color="primary" className="is-active" size="md" onClick={this.addNews} 
                          disabled={fetchInProgress}>Submit &nbsp;
                          {fetchInProgress ? <Loader size="smaller" inProgress={fetchInProgress} /> : ''}</Button>
                      </div>
                    </Col>
                  </Row>

                </Form>
                </CardBody>           
              </Card>
            </div>
          </Row>
        </Container>
      
      </>
    );
  }
}

export default withRouter(NewsAdd);


import { GetToken } from '../GetToken';
import moment from '../../node_modules/moment';
import {BASE_URL,plog} from '../config/config';
import { PostLogOutData } from '../PostLogOutData';
var AsyncLock = require('async-lock');
var lock = new AsyncLock();
const userDetailsToken = (url, accessToken) => {
  //let url = '/users/';
  return new Promise((resolve, reject) => {
    //for user details
    fetch(BASE_URL + url , {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    })
      .then((response) => {
        // plog('In userdata--',response);
        resolve(response.json())
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const authenticateToken = () => {
  
  let key="pcbreact";
  return lock.acquire(key, function() {
  if(sessionStorage.getItem('userData')||localStorage.getItem('userData')){
    
    return new Promise((resolve, reject) => {
      let responseJson = JSON.parse(localStorage.getItem('userData'));
    let refreshToken = {
      refresh_token: responseJson.refresh_token
    }
    let tokenExpireTime = moment(responseJson.accessTokenExpiresAt).format();
    let timeNow = moment(new Date()).format();

      if (timeNow <= tokenExpireTime) {
        plog("Time Now is less then Token Expire Time");
        resolve();
      } else { 
         GetToken('/users/token', refreshToken).then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            sessionStorage.setItem('userData', JSON.stringify(responseJson));
            localStorage.setItem('userData',JSON.stringify(responseJson));
            resolve();
          } else {
            let loginid = localStorage.getItem('loginid');
            let Login_id={ login_logid: loginid };
            PostLogOutData('logout', Login_id, responseJson.access_token).then((result) => {
                sessionStorage.setItem('userData', '');
                sessionStorage.clear();
                localStorage.setItem('userDetails', '');
                localStorage.clear();
            }).catch(err=>{
              plog('Eror Caught');
            });
            plog("Get Token Error in login");
            
            reject("Get Token Error");
          }
        }).catch(err=>{
          plog('Eror Caught last');
          sessionStorage.setItem('userData', '');
          sessionStorage.clear();
          localStorage.setItem('userDetails', '');
          localStorage.clear();
        });;
      }
    });
  }else{
   window.location.href = "/";
  }
}).then(function() {
  plog('Lock release');
});
}

export default { authenticateToken, userDetailsToken };
import React from "react";
import '../../../assets/css/custom.css';
import noImage from '../../../assets/img/noimage.png';
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Alert
} from "reactstrap";
import { Link } from 'react-router-dom';
import Header from "../../../components/Headers/Header.jsx";
import NewsApi  from '../../../API/newsapi';
import Helper  from '../../../helpers/helper';
import Pagination from "react-js-pagination";
import moment from 'moment'
import loder1 from '../../../assets/loder1.gif';
class NewsList extends React.Component {
  constructor(){
    super();
    this.state = {
      data:[],
    paramData:{
      per_page:10,
      pageno:1,
      category:'0'
    },
    isModalOpen: false,
    fetchInProgress: false,
    activePage: 1,
    totalDataCount:1

    };
  this.handlePageChange = this.handlePageChange.bind(this);
  }
  handlePageChange=(pageNumber) =>{
    this.setState({ activePage: pageNumber });
    this.setState(prevState => ({
      paramData: {...prevState.paramData,pageno:pageNumber}
    }))
    this.loadData();
  }

  componentWillMount() { 
    this.loadData();
  }
  loadData=() =>{ 
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(()=>{
      NewsApi.getNewsList("all",this.state.paramData).then((result) => {
        let totalItem=result.total_data;
          this.setState({totalDataCount:totalItem});
          this.setState({data :result.data,fetchInProgress: false});

      });
    })
  }
  handleAdd= () =>{
    this.setState({ isModalOpen: true });
  }
  toggleModal = state => {
    this.setState({isModalOpen:false});
  };
  onChange = e =>{
    this.setState({
      paramData: { ...this.state.paramData, category: e.target.value }
    })
    this.loadData();
  }
  render() {
    const {data,fetchInProgress,paramData}=this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        { fetchInProgress ?<div className="progress2 justify-content-center"><img src={loder1} alt="" className="pad"/></div> :  
        <Container className="mt--7" fluid>
        
          <Row className="align-items-center">
              <Col xs="6" md="4">
                  <div>
                    <label htmlFor="status" className="white">Category</label>
                    <select  id="status" className="form-control" name="category" value={paramData.category}
                      onChange={this.onChange}>
                      <option value="0">All</option>
                      <option value="1">Paribesh News</option>
                      <option value="2">WBPCB Intiative</option>
                      <option value="3">Info-in Graphics</option>
                      <option value="4">Quotes</option>
                      <option value="5">Event</option>
                    </select>
                  </div>
              </Col>
              <Col xs="2" md="4"></Col>
              <Col xs="4" md="4" className="text-right mt-4 pl-0">
                  <Link to="add-news">
                    <Button color="primary" type="button">
                      <span>Add News </span>
                    </Button>
                </Link>
              </Col>
            </Row>
            <div className="sep"></div>
          <Row>
            <div className="col">
            
              {(data!==0)?
                data.map((item, index) =>{
                  return(
                    <Card className="shadow table" key={index}>
                      <div className="col-md-12">
                        <div className="card-view">
                          <div className="row">
                            <div className="col-md-3 col-12 text-center p-0">
                             { item.news_banner==null?
                              <img src={noImage}  alt="" className="img-fluid bx-img"/>
                             : <img src={item.images[0]}  alt="" className="img-fluid bx-img"/>
                             }
                            </div>
                            <div className="col-md-9 col-12 pt-2 pl-3 pr-3 pb-3">
                              <h3>{item.headline}</h3>
                              <span className="gray">{moment(item.createdAt).format('ll')}</span>
                              <p style={item.category!==4 ? {} : { display: 'none' }}>{item.description}</p>
                              <p><span className="badge badge-success">{(item.status===0)?"Draft":(item.status===1)?"Published":"Delete"}</span> 
                              <span className="badge badge-info ml-2">{item.category===1?"Paribesh News":(item.category===2)?"WBPCB Intiative":(item.category===3)?"Info-in Graphics":(item.category===4)?"Quotes":"Event"}</span>
                              </p>
                              
                              <div className="float-md-right bottom-a">
                                <Link to={{pathname: 'show-news', state: {newsid: item.news_id}}}>
                                <button type="button" className="btn btn-info btn-sm">View</button>
                                </Link>{' '}
                                <Link to={{pathname: 'edit-news',state: {news_id: item.news_id}}} >
                                <button type="button" className="btn btn-success btn-sm">Edit</button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                    </Card>)
                }):<Alert color="warning">
                <strong>Sorry!</strong>  No Data Found!
              </Alert>
              } 
              {data!==0?
                  <div className="pag float-right">
                    <Pagination className="pagination pagination-lg"
                                listClassName="pagination-lg"
                              activePage={this.state.activePage}
                              itemsCountPerPage={10}
                              totalItemsCount={this.state.totalDataCount}
                              pageRangeDisplayed={5}
                              onChange={this.handlePageChange}
                            /> 
                   </div>:''
              }
                  
            </div>
            
          </Row>
        </Container>
        }
      </>
    );
  }
  }

export default NewsList;





import qs from 'querystring';
import axios from "axios";
import {BASE_URL} from '../config/config';
  const getComplaintsList=(url, paramData) =>{ 
 
        const keyValuePairs = [];
        for (const key in paramData) {
            if(key==='status_by' && paramData[key]==='all')
                continue;
            if(key==='token_no' && paramData[key]==='')
                continue;  
          keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(paramData[key]));
        }
       let newParam="?"+keyValuePairs.join('&');

        return new Promise((resolve, reject) => {
            let accesstoken=JSON.parse(localStorage.getItem('userData')).access_token;
            fetch(BASE_URL + url+newParam, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer '+accesstoken
                },
               // params: qs.stringify(userData)
            })
                .then((response) =>{ resolve(response.json())})
                .catch((error) => {
                    reject(error);
                });
    
        });
    } 
    const complaintAction = (url, actionData) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        return new Promise((resolve, reject) => {
            axios.patch(BASE_URL + url, qs.stringify(actionData), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer ' + accesstoken
                }})
                .then((response) => { resolve(response) })
                .catch((error) => {
                    reject(error);
                }); 
            }) 
    }
    const getComplaintDetails = (url, tokenno) => {
        
    
        return new Promise((resolve, reject) => {
            let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
            fetch(BASE_URL + url+tokenno, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer ' + accesstoken
                },
                //params: qs.stringify(newsdetails)
            })
                .then((response) => { resolve(response.json()) })
                .catch((error) => {
                    reject(error);
                });
    
        });
    }
 const addRating = (url, actionData) => {
        let accesstoken = JSON.parse(localStorage.getItem('userData')).access_token;
        return new Promise((resolve, reject) => {
            axios.patch(BASE_URL + url, qs.stringify(actionData), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer ' + accesstoken
                }})
                .then((response) => { resolve(response) })
                .catch((error) => {
                    reject(error);
                }); 
            }) 
    } 
    const getUserwiseCategory= (url, paramData) =>{ 
 
        const keyValuePairs = [];
        for (const key in paramData) {
            /*if(key==='token_no' && paramData[key]==='')
                continue;  */ 
          keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(paramData[key]));
        }
       let newParam="?"+keyValuePairs.join('&');

        return  new Promise(async(resolve, reject) => {
            let accesstoken=JSON.parse(localStorage.getItem('userData')).access_token;
            await fetch(BASE_URL + url+newParam, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer '+accesstoken
                },
               // params: qs.stringify(userData)
            })
                .then((response) =>{ resolve(response.json())})
                .catch((error) => {
                    reject(error);
                });
    
        });
    }   
export  default {  getComplaintsList,complaintAction,getComplaintDetails,addRating,getUserwiseCategory };
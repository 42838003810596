import React from "react";
import "../../../assets/css/custom.css";
import { Form, Row, Card, Container, CardHeader } from "reactstrap";
import { Redirect } from "react-router-dom";
import Header from "../../../components/Headers/Header.jsx";
import ComplaintApi from "../../../API/complainapi";
import Helper from "../../../helpers/helper";
import moment from "moment";
import loder1 from "../../../assets/loder1.gif";
import Slider from "react-animated-slider";
import { withRouter } from "react-router";
import "react-animated-slider/build/horizontal.css";
import ImageSlider from "./ImageSlider";
// import Swiper from "react-slider-swiper";

class ComplaintShow extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {
        complaint_id: "",
        token_no: "",
        description: "",
        complaint_images: "",
        is_anonymous: "",
        name: "",
        address: 0,
        mobile_no: 1,
        lat: "",
        lng: "",
        added_datetime: "",
        status: "",
        action_message: "",
        flag: "",
        createdAt: "",
        updatedAt: "",
        complainant_id: "",
        email:"",
        category_id: "",
        "ComplaintCategory.category_name": "",
        images: {},
      },
      imageLink: {},
      redirect: false,
      fetchInProgress: false,
      errors: {},
    };
  }
  componentDidMount() {
    const { tokenno } = this.props.location.state;
    const { complaint_id } = this.props.location.state;
    let url = "";
    let newNo = "";
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken().then(() => {
      if (tokenno === undefined) {
        url = "/complaint/complaint-status?complaint_id=";
        newNo = complaint_id;
      } else {
        url = "/complaint/complaint-status?token_number=";
        newNo = tokenno;
      }
      ComplaintApi.getComplaintDetails(url, newNo).then((result) => {
        this.setState({
          data: result.data,
          fetchInProgress: false,
          imageLink: result.data.images,
        });
      });
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/news"} />;
    }
    const { data, imageLink, fetchInProgress } = this.state;
    const items = [];
    const item = [];
    if (data.complaint_images != null) {
      let allimages = data.complaint_images.split("|*|");
      for (var i = 0; i < allimages.length; i++) {
        items.push(
          <div
            style={{
              background: `url('${imageLink[i]}') no-repeat center`,
              backgroundSize: "cover",
            }}
            key={i}
            alt="preview"
            name={allimages[i]}
          ></div>
        );
        item.push({ url: `${allimages[i]}` });
      }
    } else {
      items.push(
        <div
          style={{
            background: `url('${require("../../../assets/img/noimage.png")}') no-repeat center`,
            backgroundSize: "cover",
          }}
          key={0}
          alt="preview"
        ></div>
      );
    }
    return (
      <>
        <Header />
        {/* Page content */}
        {fetchInProgress ? (
          <div className="progress2 justify-content-center">
            {" "}
            <img src={loder1} alt="" />
          </div>
        ) : (
          <Container className="mt--7" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow card-bg-view">
                  <CardHeader>
                    <div className="row">
                      <div className="col-md-6 col-6 pt-2">
                        Complaint Details
                      </div>
                      <div className="col-md-6 col-6 text-right">
                        <button
                          type="button"
                          className="btn btn-primary md"
                          onClick={() => this.props.history.go(-1)}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </CardHeader>
                  <Form encType="multipart/form-data">
                    <div className="row">
                      <div className="col-md-6 text-center">
                        {/* <Slider autoplay={2000}>{items}</Slider> */}   
                         <ImageSlider items={data.images} ></ImageSlider>
                      </div>

                      <div className="col-md-6 text-md-left pl-md-2">
                        <div className="text-md-left t-no">
                          <label htmlFor="event_venue" style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <i className="ni ni-bullet-list-67"></i> Token
                            Number : {data.token_no}
                          </label>
                        </div>
                        <div className="text-md-left " >
                          <label htmlFor="event_venue" className="status" >
                            <i className="ni ni-circle-08"></i> Name :{" "}
                          </label>
                          {data.name}
                        </div>
                        <div className="text-md-left ">
                          <label htmlFor="event_venue" className="status" >
                            <i className="ni fa fa-phone"></i> Mobile no :{" "}
                          </label>
                          {data.mobile_no}
                        </div>
                        <div className="text-md-left ">
                          <label htmlFor="event_venue" className="status" >
                            <i className="ni fa fa-envelope"></i> Email :{" "}
                          </label>
                          {!!data.email && data.email}
                        </div>
                        <div className="text-md-left ">
                          <label htmlFor="event_venue" className="status" >
                            <i className="ni fa fa-address-card"></i> Address :{" "}
                          </label>
                          {data.address}
                        </div>

                        <div className="text-md-left  ">
                          <label htmlFor="event_venue" className="status" >
                            {" "}
                            <i className="ni ni-calendar-grid-58"></i> Complaint
                            Date :
                          </label>
                          {moment(data.added_datetime).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </div>
                        <div className="text-md-left  ">
                          <label htmlFor="event_venue" className="status" >
                          
                            <i className="ni fa fa-map"></i> View on Map :
                          </label>
                          (<a target="_" href={`https://www.google.com/maps/search/?api=1&query=${data.lat},${data.lng}`}>{data.lat} , {data.lng}</a>)
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 description">
                        <span className="c-date">
                          {" "}
                          {moment(data.added_datetime).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </span>
                        <br />
                        <label htmlFor="description">{data.description}</label>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="col-md-12 text-md-left pl-md-2">
                      <div className="text-md-left ">
                        <label htmlFor="event_venue" className="status">
                          {" "}
                          <i className="ni ni-user-run"></i> Reply Status :{" "}
                        </label>
                        {data.status === 0
                          ? "Registered"
                          : data.status === 1
                          ? "Resolved"
                          : "Irrelevant"}
                      </div>
                      <div className="text-md-left ">
                        <label htmlFor="event_venue" className="status">
                          {" "}
                          <i className="ni ni-email-83"></i> Reply Message :{" "}
                        </label>
                        {data.action_message}
                      </div>
                    </div>
                  </Form>
                </Card>
              </div>
            </Row>
          </Container>
        )}
      </>
    );
  }
}

export default withRouter(ComplaintShow);

import React from "react";
import { Redirect, Link } from 'react-router-dom';
import Loader from '../../../components/Spinner/spinner';
import Header from "../../../components/Headers/Header";
import InlineError from '../../../components/messages/InlineError';
import Helper from '../../../helpers/helper';
import UserApi from '../../../API/userapi';
import {
  Container,
  Button,
  Card,
  Col,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Alert,
  Row
} from "reactstrap";

class AddUser extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {
        title: '',
        name: '',
        email: '',
        mobile_no: '',
        user_type: '',
        address: '',
        pincode: ''
      },
      redirect: false,
      visibleSuccess: false,
      visibleError: false,
      successmsg: {},
      fetchInProgress: false,
      errors: {}
    };
    this.addUser = this.addUser.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  addUser = () => {
    this.setState({ fetchInProgress: true });
    Helper.authenticateToken(this.state).then(() => {
      const errors = this.validate(this.state.data);
      this.setState({ errors, fetchInProgress: false });
      if (Object.keys(errors).length === 0) {
        UserApi.addUser('/admin/add-user', this.state.data).then((result) => {
          
          if (result.data.status === "success") {
            this.setState({ visibleSuccess: true, fetchInProgress: false, successmsg: result.data.error_description });
            setTimeout(function () {
              this.setState({ redirect: true });
            }.bind(this), 3000) 
          } else {
            this.setState({ errors: result.errors, fetchInProgress: false, visibleError: true });
          }
        }).catch(err=>{
          this.setState({ errors:err.response.data.errors, fetchInProgress: false, visibleError: true });
        })
      }
    })
  }

  validate = (data) => {
    const errors = {};
    if (!data.name) errors.name = "Name is Required";
    if (!data.mobile_no) errors.mobile_no = "Mobile No is Required";
    if (!data.email) errors.email = "Enter valid email";
    if (!data.user_type) errors.user_type = "Please select type";
    return errors;
  }
  onChange = e => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    })
  }

  onDismiss() {
    const { visibleSuccess } = this.state;
    (visibleSuccess) ? this.setState({ visibleSuccess: false }) : this.setState({ visibleError: false });
  }

  render() {
    const { data, errors, redirect, fetchInProgress, visibleSuccess, visibleError,successmsg } = this.state;
    if (redirect) {
      return <Redirect to={'/admin/user'} />
    }
    return (
      <>
        <Header />
        {/* Page content */}

        <Container className="mt--7" fluid>
          {/* {fetchInProgress ? <div className="row justify-content-center"> <Loader size="x-large" inProgress={fetchInProgress} /></div> : */}
            <Row>
              <div className="col">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-2">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Add User</h3>
                      </Col>
                      <Col xs="4" className="text-right">
                        <Link to="user">
                          <Button className="btn btn-sm" color="primary" type="button">
                            <span>Back</span>
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {visibleSuccess ? <Alert color="success" isOpen={visibleSuccess} toggle={this.onDismiss}>
                      {successmsg}
                  </Alert> : visibleError ? <Alert color="danger" isOpen={visibleError} toggle={this.onDismiss}>
                      Error occured 
                      {
                        errors.map((item, index) =>{
                              return (<> <span key={index} >{item.msg}</span> <br /> </>);
                        })
                      }
                  </Alert>:''}

                    <Form encType="multipart/form-data">
                      <h6 className="heading-small text-muted mb-4">
                        User information
                    </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                              >
                                Title
                            </label>
                              <select id="input-title" className="form-control" name="title" value={data.title} onChange={this.onChange}>
                                <option>Select Title</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Ms">Ms</option>
                                <option value="Shri">Shri</option>
                                <option value="Smt">Smt</option>
                              </select>
                            </FormGroup>
                          </Col>
                          <Col lg="8">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Full name <span className="error-message">*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-first-name"
                                placeholder="Enter your name"
                                type="text"
                                name="name"
                                value={data.name}
                                autoComplete="off"
                                onChange={this.onChange}
                              />
                              {errors.name && <InlineError text={errors.name} />}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="5">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Email address <span className="error-message">*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="xyz@example.com"
                                type="email"
                                name="email"
                                autoComplete="off"
                                value={data.email}
                                // disabled
                                onChange={this.onChange}
                              />
                              {errors.email && <InlineError text={errors.email} />}
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-mobile"
                              >
                                Mobile Number <span className="error-message">*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-mobile"
                                placeholder="Mobile no."
                                type="text"
                                autoComplete="off"
                                maxLength="10"
                                name="mobile_no"
                                value={data.mobile_no}
                                // disabled
                                onChange={this.onChange}
                              />
                              {errors.mobile_no && <InlineError text={errors.mobile_no} />}
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-user-type"
                              >
                                User Type <span className="error-message">*</span>
                              </label>
                              <select id="input-user-type" className="form-control" name="user_type" value={data.user_type} onChange={this.onChange}>
                                <option>Select User Type</option>
                                <option value="1">Admin</option>
                                <option value="2">BOC</option>
                              </select>
                              {errors.user_type && <InlineError text={errors.user_type} />}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                      {/* Address */}
                      <h6 className="heading-small text-muted mb-4">
                        Contact information
                    </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="8">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >
                                Address
                            </label>
                              <Input
                                className="form-control-alternative"
                                id="input-address"
                                placeholder="Home Address"
                                rows="2"
                                type="textarea"
                                name="address"
                                value={data.address}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-postal-code"
                              >
                                Postal code
                            </label>
                              <Input
                                className="form-control-alternative"
                                id="input-postal-code"
                                placeholder="Postal code"
                                autoComplete="off"
                                type="text"
                                maxLength="6"
                                name="pincode"
                                value={data.pincode}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                      {/* Description */}

                      <div className="pl-lg-4 text-right">
                        <Button
                          color="success"
                          disabled={fetchInProgress}
                          onClick={this.addUser}
                        >
                          Add User &nbsp;
                          {fetchInProgress ? <Loader size="smaller" inProgress={fetchInProgress} /> : ''}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Row>
          {/* } */}
        </Container>
      </>
    );
  }
}

export default AddUser;